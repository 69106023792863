export const EVF_INPUT_FIELDS = [
  {
    id: "globalCustomerName",
    name: "Customer Name",
    isDisabled: true,
  },
  {
    id: "superRegion",
    name: "GEO",
    isDisabled: true,
  },
  {
    name: "Sub Region",
    id: "subRegion",
    isDisabled: true,
  },
  {
    id: "actualSybUsed",
    name: "SYB Deal Value in USD",
    isDisabled: true,
    isCurrency: true,
  },
  {
    id: "closeDate",
    name: "Opportunity Close Date",
    isDisabled: true,
  },
];

export const SELL_WITH_OPTIONS = {
  ISV: [
    {
      label:
        "Partner services or solution enables Red Hat to gain access into Enterprise, Commercial and/or Industry account",
      value:
        "Partner services or solution enables Red Hat to gain access into Enterprise, Commercial and/or Industry account",
    },
    {
      label:
        "Partner solution supports in sale/expansion/influence of Red Hat Subs / Services in net new or existing renewal opportunity",
      value:
        "Partner solution supports in sale/expansion/influence of Red Hat Subs / Services in net new or existing renewal opportunity",
    },
    {
      label:
        "Partner includes Red Hat technologies in standard offerings, reference architectures and customer solution implementations",
      value:
        "Partner includes Red Hat technologies in standard offerings, reference architectures and customer solution implementations",
    },
    {
      label:
        "Partner 'cocreates' content with Red Hat that supports in sale/expansion/influence of Red Hat Subs in net new or existing renewal opportunity",
      value:
        "Partner 'cocreates' content with Red Hat that supports in sale/expansion/influence of Red Hat Subs in net new or existing renewal opportunity",
    },
  ],
  SI: [
    {
      label: "Partner Sourced or Partner-Led Opportunity",
      value: "Partner Sourced or Partner-Led Opportunity",
    },
    {
      label:
        "Partner solution supports in sale/expansion/influence of Red Hat Subs or existing renewal opportunity",
      value:
        "Partner solution supports in sale/expansion/influence of Red Hat Subs or existing renewal opportunity",
    },
    {
      label:
        "Partner includes Red Hat technologies in standard offerings, reference architectures and customer solution implementations",
      value:
        "Partner includes Red Hat technologies in standard offerings, reference architectures and customer solution implementations",
    },
    {
      label:
        "SI was previously tagged and has demonstrated continued support for the adoption and scale of the RH platform with the client",
      value:
        "SI was previously tagged and has demonstrated continued support for the adoption and scale of the RH platform with the client",
    },
    {
      label:
        "Partner driving transformational services with the client and was a validated partner with a renewal and/or a net new subs transaction and demonstrates continued support for the same client project with RH surround service",
      value:
        "Partner driving transformational services with the client and was a validated partner with a renewal and/or a net new subs transaction and demonstrates continued support for the same client project with RH surround service",
    },
  ],
  TME: [
    {
      label:
        "Develop joint technology differentiation that captures 5G Core & RAN market share",
      value:
        "Develop joint technology differentiation that captures 5G Core & RAN market share",
    },
    {
      label:
        "Expand our partners into emerging markets (Managed Svcs, Edge, Enterprise Private 5G, AI, Media, Entertainment)",
      value:
        "Expand our partners into emerging markets (Managed Svcs, Edge, Enterprise Private 5G, AI, Media, Entertainment)",
    },
  ],
  IBM: [
    {
      label:
        "Partner services or solution enables Red Hat to gain access into Enterprise, Commercial and/or Industry account",
      value:
        "Partner services or solution enables Red Hat to gain access into Enterprise, Commercial and/or Industry account",
    },
    {
      label:
        "Partner solution supports in sale/expansion/influence of Red Hat Subs / Services in net new or existing renewal opportunity",
      value:
        "Partner solution supports in sale/expansion/influence of Red Hat Subs / Services in net new or existing renewal opportunity",
    },
    {
      label:
        "Partner includes Red Hat technologies in standard offerings, reference architectures and customer solution implementations",
      value:
        "Partner includes Red Hat technologies in standard offerings, reference architectures and customer solution implementations",
    },
    {
      label:
        "Partner 'cocreates' content with Red Hat that supports in sale/expansion/influence of Red Hat Subs in net new or existing renewal opportunity",
      value:
        "Partner 'cocreates' content with Red Hat that supports in sale/expansion/influence of Red Hat Subs in net new or existing renewal opportunity",
    },
    {
      label: "Partner Sourced or Partner-Led Opportunity",
      value: "Partner Sourced or Partner-Led Opportunity",
    },
    {
      label:
        "Partner was previously tagged and has demonstrated continued support for the adoption and scale of the RH platform with the client",
      value:
        "Partner was previously tagged and has demonstrated continued support for the adoption and scale of the RH platform with the client",
    },
    {
      label:
        "Partner driving transformational services with the client and was a validated partner with a renewal and/or a net new subs transaction and demonstrates continued support for the same client project with RH surround service",
      value:
        "Partner driving transformational services with the client and was a validated partner with a renewal and/or a net new subs transaction and demonstrates continued support for the same client project with RH surround service",
    },
  ],
  SAP: [
    {
      label: "Automation of the SAP infrastructure",
      value: "Automation of the SAP infrastructure",
    },
    {
      label: "Integration of SAP and Non-SAP application and data",
      value: "Integration of SAP and Non-SAP application and data",
    },
    {
      label: "Develop SAP extensions on Openshift",
      value: "Develop SAP extensions on Openshift",
    },
    {
      label: "Run SAP Data Intelligence on Openshift",
      value: "Run SAP Data Intelligence on Openshift",
    },
    {
      label: "SAP RISE on RHEL",
      value: "SAP RISE on RHEL",
    },
  ],
};

export const HELPER_TEXT = [
  // ISV Helper Text
  {
    label:
      "Partner services or solution enables Red Hat to gain access into Enterprise, Commercial and/or Industry account",
    value:
      "Customer list of engagements driven by the partner (acceptable forms: presentations, email, shared google doc)/ Deal Registration/Customer Validation",
  },
  {
    label:
      "Partner solution supports in sale/expansion/influence of Red Hat Subs / Services in net new or existing renewal opportunity",
    value:
      "Provide a minimum of 2 of the following: <br> * Add the QUIP, CHAMP, Marketing Plan or Opportunity Close Plan that shows the GSI helped influence. <br>* Describe the Co-sell activity between Red hat and the Partner and the expected business outcomes * Describe the specific Partners activities that helped with the influence and adoption of Red Hat Technology<br> * Provide details of the Partner's solution and highlight which Red Hat products are used (include solution URL and/or Solution use case)<br>* Supply documentation of the sales activity and outcomes of engagements between the Partner and AE, ASA, PAM, PSA.<br>* SI was originally tagged as the net new SI and has demonstrated continued support for the adoption and scale of the RH platform with the client",
  },
  {
    label:
      "Partner includes Red Hat technologies in standard offerings, reference architectures and customer solution implementations",
    value:
      "Provide a minimum of 2 of the following:<br>* Add the QUIP, CHAMP, Marketing Plan or Opportunity Close Plan that shows the GSI helped influence. <br>* Describe the customer's Business outcome as a result of this solution <br>* Details of the customer migration plan and/or workloads, including ISV <br>* Explain how the Partner is orchestrating other Ecosystem types(e.g. Hyperscalers/ISV/SAP/CCSP/OEM) and how this is delivering value to the Customer <br>* Provide details of the Partner's solution and highlight which Red Hat products are used (include solution URL and/or Solution use case)",
  },
  {
    label:
      "Partner 'cocreates' content with Red Hat that supports in sale/expansion/influence of Red Hat Subs in net new or existing renewal opportunity",
    value:
      "Please provide evidence the the Partner has confirmed/verifable co-creation content. Telemetry Data (limited)/SFDC opportunity comments/next steps with Partner workload mentioned",
  },

  // Systems Integrator

  {
    label: "Partner Sourced or Partner-Led Opportunity",
    value:
      "Provide a minimum of 2 of the following: <br>*Add the QUIP, CHAMP, Marketing Plan or Opportunity Close Plan that shows the GSI helped influence. <br>*Describe why is the GSI recommending Red Hat technologies? <br>*Detail the Partner's client engagement and expected Business outcome. <br>*Provide details of the project name / programs of work the Partner is working on and what are the key milestones? <br>*Detail who is our competition and how is the GSI supporting Red Hat? <br>*Details of the Partners relationship with the Customer and their current role <br>*Supply documentation of the Partner's proposed architecture which has / will expand Red Hat's footprint within the account. <br>*Explain how the Partner is orchestrating other Ecosystem types (e.g. Hyperscalers/ISV/SAP/CCSP/OEM) and how this is delivering value to the Customer. <br>*Supply documentation of the sales activity and outcomes of engagements between the Partner and AE, ASA, PAM, PSA.",
  },
  {
    label:
      "Partner solution supports in sale/expansion/influence of Red Hat Subs or existing renewal opportunity",
    value:
      "Provide a minimum of 2 of the following: <br>* Add the QUIP, CHAMP, Marketing Plan or Opportunity Close Plan that shows the GSI helped influence. <br>* Describe the Co-sell activity between Red hat and the Partner and the expected business outcomes <br>* Describe the specific Partners activities that helped with the influence and adoption of Red Hat Technology <br>* Provide details of the Partner's solution and highlight which Red Hat products are used (include solution URL and/or Solution use case) <br>* Supply documentation of the sales activity and outcomes of engagements between the Partner and AE, ASA, PAM, PSA. <br>* SI was originally tagged as the net new SI and has demonstrated continued support for the adoption and scale of the RH platform with the client",
  },
  {
    label:
      "SI was previously tagged and has demonstrated continued support for the adoption and scale of the RH platform with the client",
    value:
      "Provide a minimum of 2 of the following: <br>*Provide any historic background information relating to the Partner and this renewal <br>*Detail the partner activities supporting Red Hat & the customers adoption of Red Hat technologies since the original opportunity closed <br>*Details of the Partners relationship with the Customer and their current role <br>*Partner agree on lifecycle value plan <br>*Provide the Partner plans for expansion and or adoption of Red Hat Technology <br>*SI was originally tagged as the net new SI and has demonstrated continued support for the adoption and scale of the RH platform with the client",
  },
  {
    label:
      "Partner driving transformational services with the client and was a validated partner with a renewal and/or a net new subs transaction and demonstrates continued support for the same client project with RH surround service",
    value:
      "Provide a minimum of 2 of the following: <br>*Add the QUIP, CHAMP, Marketing Plan or Opportunity Close Plan that shows the GSI helped influence. <br>*Describe the specific Partners activities that helped with the influence and adoption of Red Hat Technology <br>*Provide details of the project name / programs of work the Partner is working on and what are the key milestones? <br>*Details of the Partners relationship with the Customer and their current role <br>*Supply documentation of the Partner's proposed architecture which has / will expand Red Hat's footprint within the account.<br>*Explain how the Partner is orchestrating other Ecosystem types (e.g. Hyperscalers/ISV/SAP/CCSP/OEM) and how this is delivering value to the Customer. <br>*Supply documentation of the sales activity and outcomes of engagements between the Partner and AE, ASA, PAM, PSA.",
  },
  // Telco Partners
  {
    label:
      "Develop joint technology differentiation that captures 5G Core & RAN market share",
    value:
      "Solution Co-creation, certification, system validation, GTM strategy/engagement, (all or any of these activities)",
  },
  {
    label:
      "Expand our partners into emerging markets (Managed Svcs, Edge, Enterprise Private 5G, AI, Media, Entertainment)",
    value:
      "Evidence of a launch into New Markets; i.e. teaming agreement, memorandum of understanding, etc.",
  },
  // IBM
  {
    label:
      "Partner services or solution enables Red Hat to gain access into Enterprise, Commercial and/or Industry account",
    value:
      "Customer list of engagements driven by the partner (acceptable forms: presentations, email, shared google doc)/ Deal Registration/Customer Validation",
  },
  {
    label:
      "Partner solution supports in sale/expansion/influence of Red Hat Subs / Services in net new or existing renewal opportunity",
    value:
      "Provide a minimum of 2 of the following:<br>* Add the QUIP, CHAMP, Marketing Plan or Opportunity Close Plan that shows the GSI helped influence.<br>* Describe the Co-sell activity between Red hat and the Partner and the expected business outcomes<br>* Describe the specific Partners activities that helped with the influence and adoption of Red Hat Technology<br>* Provide details of the Partner's solution and highlight which Red Hat products are used (include solution URL and/or Solution use case)<br>* Supply documentation of the sales activity and outcomes of engagements between the Partner and AE, ASA, PAM, PSA.<br>* SI was originally tagged as the net new SI and has demonstrated continued support for the adoption and scale of the RH platform with the client",
  },
  {
    label:
      "Partner includes Red Hat technologies in standard offerings, reference architectures and customer solution implementations",
    value:
      "Provide a minimum of 2 of the following:<br>* Add the QUIP, CHAMP, Marketing Plan or Opportunity Close Plan that shows the GSI helped influence.<br>* Describe the customer's Business outcome as a result of this solution<br>* Details of the customer migration plan and/or workloads, including ISV<br>* Explain how the Partner is orchestrating other Ecosystem types (e.g. Hyperscalers/ISV/SAP/CCSP/OEM) and how this is delivering value to the Customer<br>* Provide details of the Partner's solution and highlight which Red Hat products are used (include solution URL and/or Solution use case)",
  },
  {
    label:
      "Partner 'cocreates' content with Red Hat that supports in sale/expansion/influence of Red Hat Subs in net new or existing renewal opportunity",
    value:
      "Please provide evidence the the Partner has confirmed/verifable co-creation content. Telemetry Data (limited)/SFDC opportunity comments/next steps with Partner workload mentioned",
  },
  {
    label: "Partner Sourced or Partner-Led Opportunity",
    value:
      "Provide a minimum of 2 of the following:<br>*Add the QUIP, CHAMP, Marketing Plan or Opportunity Close Plan that shows the GSI helped influence.<br>*Describe why is the GSI recommending Red Hat technologies?<br>*Detail the Partner's client engagement and expected Business outcome.<br>*Provide details of the project name / programs of work the Partner is working on and what are the key milestones?<br>*Detail who is our competition and how is the GSI supporting Red Hat?<br>*Details of the Partners relationship with the Customer and their current role<br>*Supply documentation of the Partner's proposed architecture which has / will expand Red Hat's footprint within the account.<br>*Explain how the Partner is orchestrating other Ecosystem types (e.g. Hyperscalers/ISV/SAP/CCSP/OEM) and how this is delivering value to the Customer.<br>*Supply documentation of the sales activity and outcomes of engagements between the Partner and AE, ASA, PAM, PSA.",
  },
  {
    label:
      "Partner was previously tagged and has demonstrated continued support for the adoption and scale of the RH platform with the client",
    value:
      "Provide a minimum of 2 of the following:<br>*Provide any historic background information relating to the Partner and this renewal<br>*Detail the partner activities supporting Red Hat & the customers adoption of Red Hat technologies since the original opportunity closed<br>*Details of the Partners relationship with the Customer and their current role<br>*Partner agree on lifecycle value plan<br>*Provide the Partner plans for expansion and or adoption of Red Hat Technology<br>*SI was originally tagged as the net new SI and has demonstrated continued support for the adoption and scale of the RH platform with the client",
  },
  {
    label:
      "Partner driving transformational services with the client and was a validated partner with a renewal and/or a net new subs transaction and demonstrates continued support for the same client project with RH surround service",
    value:
      "Provide a minimum of 2 of the following: <br>*Add the QUIP, CHAMP, Marketing Plan or Opportunity Close Plan that shows the GSI helped influence. <br>*Describe the specific Partners activities that helped with the influence and adoption of Red Hat Technology <br>*Provide details of the project name / programs of work the Partner is working on and what are the key milestones? <br>*Details of the Partners relationship with the Customer and their current role <br>*Supply documentation of the Partner's proposed architecture which has / will expand Red Hat's footprint within the account. <br>*Explain how the Partner is orchestrating other Ecosystem types (e.g. Hyperscalers/ISV/SAP/CCSP/OEM) and how this is delivering value to the Customer. <br>*Supply documentation of the sales activity and outcomes of engagements between the Partner and AE, ASA, PAM, PSA.",
  },
  // SAP
  {
    label: "Automation of the SAP infrastructure",
    value: "Please link to Chatter/attachment acknowledging BDM agreement",
  },
  {
    label: "Integration of SAP and Non-SAP application and data",
    value: "Please link to Chatter/attachment acknowledging BDM agreement",
  },
  {
    label: "Develop SAP extensions on Openshift",
    value: "Please link to Chatter/attachment acknowledging BDM agreement",
  },
  {
    label: "Run SAP Data Intelligence on Openshift",
    value: "Please link to Chatter/attachment acknowledging BDM agreement",
  },
  {
    label: "SAP RISE on RHEL",
    value: "Please link to Chatter/attachment acknowledging BDM agreement",
  },
];

export const PARTNER_TYPE = [
  { label: "ISV", value: "ISV" },
  { label: "Systems Integrator", value: "SI" },
  { label: "Telco Partners", value: "TME" },
  { label: "SAP", value: "SAP" },
  { label: "IBM", value: "IBM" },
];

export const TYPE_OF_OPPORTUNITY = [
  {
    label: "New",
    value: "New",
  },
  {
    label: "Renewal (HAS TO BE > USD 50k)",
    value: "Renewal (HAS TO BE > USD 50k)",
  },
];

export const DISCLAIMER =
  "The influencing partner named in this EVF should not be given, or have been given, pricing for the opportunity/deal cited in this EVF. Please confirm that the partner named in this EVF has not and will not bid, or engage as a financial or reseller partner, on the opportunity/deal cited in this form by checking this box.";
