import apiClient from "../../../apis/api-client";
import utilsApiClient from "../../../apis/pa-utils/api-client";

interface RequestProps {
  url: string;
  data?: any;
  params?: any;
  successCallback?: (response?) => void;
  failureCallback?: (response?) => void;
}

const changeTaskState = async (props: RequestProps) => {
  const { url, data, params, successCallback, failureCallback } = props;
  const response = await apiClient(url).post(url, data, {
    params,
  });

  if (response.status === 200) {
    successCallback && successCallback();
  } else {
    failureCallback && failureCallback(response);
  }
};

const getSbrTeams = async (props: RequestProps) => {
  const { url, successCallback, failureCallback } = props;
  const response = await utilsApiClient.get(url);
  if (response.status === 200) {
    successCallback && successCallback(response.data);
  } else {
    failureCallback && failureCallback();
  }
};

export { changeTaskState, getSbrTeams };
