import VALIDATION from "./validation";

export const PROCESS_INPUT_FIELDS = [
  {
    id: "customerCompanyName",
    name: "Customer / Partner Name",
    isDisabled: true,
  },
  {
    id: "redHatRepresentativeEmail",
    name: "Red Hat Representative Email",
    isDisabled: true,
  },
  {
    id: "customerContactName",
    name: "Customer Contact Name",
    isDisabled: false,
    isOptional: true,
  },
  {
    name: "Regional Service Manager (RSM) Name",
    id: "regionalServiceManagerName",
    isDisabled: true,
  },
  {
    name: "Customer Email address",
    id: "customerEmailAddress",
    isDisabled: false,
    validation: VALIDATION.email,
    isOptional: true,
  },
  {
    name: "RSM Email address",
    id: "regionalServiceManagerEmail",
    isDisabled: true,
  },
  {
    name: "Red Hat representative Name",
    id: "redHatRepresentativeName",
    isDisabled: true,
  },
  {
    name: "Opportunity number of the original booking",
    id: "opportunityNumber",
    isDisabled: true,
  },
  {
    name: "Total Hours on contract (Total Planned Hours)",
    id: "totalPlannedHours",
    isDisabled: true,
  },
  {
    name: "Hours remaining",
    id: "totalRemainingHours",
    isDisabled: true,
  },
  {
    name: "Euro/Dollar Value of Remaining Delivery",
    id: "totalRemainingRevenueBacklog",
    isDisabled: true,
  },
];

export const PCF_OPTIONS = [
  { label: "Extension/Backdating of dates", value: "EXT", isDisabled: false },
  { label: "Remix of Hours", value: "ROH", isDisabled: true },
  { label: "Billing Type Change", value: "BTC", isDisabled: false },
  { label: "Backdating of term dates", value: "BTD", isDisabled: true },
  { label: "Other", value: "OTH", isDisabled: true },
];

export const AGREEMENT_OPTIONS = [
  { label: "Special Bid Order Form", value: "SBOF" },
  { label: "Order Form (English)", value: "OFE" },
  { label: "IBM Task Order", value: "IBMTOR" },
];

export const PRODUCT_TYPE_OPTIONS = [
  { label: "Time and Materials", value: "TM" },
  { label: "Consulting Unit", value: "CU" },
  { label: "Fixed Price", value: "FP" },
];

export const BILLING_TYPES = [
  { label: "Monthly", value: "Monthly" },
  { label: "Upfront", value: "Upfront" },
];

export enum PROJECT_TYPES {
  CUSTOMER_PROJECT = "Customer Project",
  CONSULTING_UNIT = "Consulting Unit",
}

export enum PRODUCT_TYPES {
  TIME_AND_MATERIALS = "Time and Materials",
  CONSULTING_UNIT = "Consulting Unit",
  FIXED_PRICE = "Fixed Price",
}

export enum BILLING_ARRANGEMENTS {
  MONTHLY = "Monthly",
  UPFRONT = "Upfront",
}

export enum FULFILLMENT_CHANNEL_TYPE {
  DIRECT_END_CUSTOMER = "Direct End Customer",
  DIRECT_RESELLER = "Direct Reseller",
  DISTRIBUTOR = "Distributor",
  CLOUD_SERVICE_PROVIDER = "Cloud Service Provider",
  OEM = "OEM",
  WEBSTORE = "Webstore",
}
