import {
  DINNER_PLAN_API_BASE_URL,
  EVS_API_BASE_URL,
  MANAGES_SERVICES_API_BASE_URL,
  PCA_API_BASE_URL,
  dinnerPlanApiEndpoints,
  evsApiEndpoints,
  managedServicesEndpoints,
  pcaApiEndpoints,
} from "../apis/endpoints";

export interface ProcessAccessor {
  code: string;
  baseurl: any;
  endpoint: string;
  isLive: boolean;
}
export const PROCESS_ACCESS: ProcessAccessor[] = [
  {
    code: "pcf",
    baseurl: PCA_API_BASE_URL,
    endpoint: pcaApiEndpoints.pca,
    isLive: true,
  },
  {
    code: "evs",
    baseurl: EVS_API_BASE_URL,
    endpoint: evsApiEndpoints.evs,
    isLive: true,
  },
  {
    code: "dinnerPlan",
    baseurl: DINNER_PLAN_API_BASE_URL,
    endpoint: dinnerPlanApiEndpoints.dinnerPlan,
    isLive: true,
  },
  {
    code: "managedservices",
    baseurl: MANAGES_SERVICES_API_BASE_URL,
    endpoint: managedServicesEndpoints.managedServices,
    isLive: true,
  },
];
