import utilsApiClient from "../../../apis/pa-utils/api-client";

interface RequestProps {
  url?: string;
  data?: any;
  params?: any;
  successCallback?: (response?) => void;
  failureCallback?: (response) => void;
}

const createComment = async (props: RequestProps) => {
  const { url, data, successCallback, failureCallback } = props;
  const response = await utilsApiClient.post(url, data);

  if (response.status === 201) {
    successCallback && successCallback(response);
  } else {
    failureCallback && failureCallback(response);
  }
};

const getComments = async (props: RequestProps) => {
  const { url, data, params, successCallback, failureCallback } = props;
  const response = await utilsApiClient.get(url, {
    params,
  });
  if (response.status === 200) {
    successCallback && successCallback(response.data);
  } else {
    failureCallback && failureCallback(response);
  }
};

export { getComments, createComment };
