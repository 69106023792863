import {
  Badge,
  Button,
  InputGroup,
  MenuToggle,
  MenuToggleElement,
  PageSection,
  PageSectionVariants,
  Select,
  SelectList,
  SelectOption,
  TextInput,
  Toolbar,
  ToolbarContent,
  ToolbarFilter,
  ToolbarGroup,
  ToolbarItem,
  ToolbarToggleGroup,
} from "@patternfly/react-core";
import { FilterIcon } from "@patternfly/react-icons";
import { useState } from "react";
import { TASK_FILTER_CATEGORY, STATUS } from "../../../../constants/Task";

export const TaskListHeader = ({ filters, setFilters }) => {
  const [searchInput, setSearchInput] = useState<string>("");
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([
    ...filters.status,
  ]);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const onSelect = (event, selection): void => {
    if (selectedStatuses.includes(selection)) {
      const newTaskStates = [...selectedStatuses].filter(
        (state) => state !== selection
      );
      setSelectedStatuses(newTaskStates);
    } else {
      setSelectedStatuses([...selectedStatuses, selection]);
    }
  };

  const onDeleteChip = (
    categoryName: TASK_FILTER_CATEGORY,
    value: string
  ): void => {
    switch (categoryName) {
      case TASK_FILTER_CATEGORY.STATUS:
        const newSelectedStatues = selectedStatuses.filter(
          (item) => item !== value
        );
        setSelectedStatuses([...newSelectedStatues]);
        setFilters({ ...filters, status: newSelectedStatues });
        break;
      case TASK_FILTER_CATEGORY.SEARCH_KEY:
        const newSearchKey = filters.searchKey.filter((item) => item !== value);
        setFilters({ ...filters, searchKey: newSearchKey });
        break;
    }
  };

  const onApplyFilter = (): void => {
    setSearchInput("");
    const clonedSearchByKeyArray = [...filters.searchKey];
    if (searchInput && !clonedSearchByKeyArray.includes(searchInput)) {
      clonedSearchByKeyArray.push(searchInput);
    }
    setFilters({
      ...filters,
      searchKey: clonedSearchByKeyArray,
      status: selectedStatuses,
    });
  };

  const resetAllFilters = (): void => {
    setSearchInput("");
    setSelectedStatuses([STATUS.READY, STATUS.RESERVED]);

    setFilters({
      searchKey: "",
      status: [STATUS.READY, STATUS.RESERVED],
    });
  };

  const onEnterClicked = (event: React.KeyboardEvent<EventTarget>): void => {
    if (event.key === "Enter") {
      searchInput.length > 0 && onApplyFilter();
    }
  };

  const onToggleClick = () => {
    setIsExpanded(!isExpanded);
  };

  const toggle = (toggleRef: React.Ref<MenuToggleElement>) => (
    <MenuToggle
      ref={toggleRef}
      onClick={onToggleClick}
      isExpanded={isExpanded}
      style={
        {
          width: "200px",
        } as React.CSSProperties
      }
    >
      Status
      {selectedStatuses.length > 0 && (
        <Badge isRead>{selectedStatuses.length}</Badge>
      )}
    </MenuToggle>
  );

  return (
    <PageSection variant={PageSectionVariants.light}>
      <Toolbar
        style={{ backgroundColor: "white" }}
        collapseListedFiltersBreakpoint="xl"
        clearAllFilters={resetAllFilters}
        clearFiltersButtonText="Reset to default"
      >
        <ToolbarContent>
          <ToolbarToggleGroup toggleIcon={<FilterIcon />} breakpoint="xl">
            <ToolbarGroup variant="filter-group">
              <ToolbarFilter
                chips={filters.status}
                deleteChip={onDeleteChip}
                className="pf-u-mr-sm"
                categoryName={TASK_FILTER_CATEGORY.STATUS}
                id="datatoolbar-filter-status"
              >
                <Select
                  id="status-select"
                  aria-label="Status"
                  onSelect={onSelect}
                  selected={selectedStatuses}
                  isOpen={isExpanded}
                  toggle={toggle}
                >
                  <SelectList>
                    <SelectOption
                      hasCheckbox
                      value="Ready"
                      isSelected={selectedStatuses.includes("Ready")}
                    >
                      Ready
                    </SelectOption>
                    <SelectOption
                      hasCheckbox
                      value="Completed"
                      isSelected={selectedStatuses.includes("Completed")}
                    >
                      Completed
                    </SelectOption>
                    <SelectOption
                      hasCheckbox
                      value="Reserved"
                      isSelected={selectedStatuses.includes("Reserved")}
                    >
                      Reserved
                    </SelectOption>
                    <SelectOption
                      hasCheckbox
                      value="Aborted"
                      isSelected={selectedStatuses.includes("Aborted")}
                    >
                      Aborted
                    </SelectOption>
                    <SelectOption
                      hasCheckbox
                      value="Skip"
                      isSelected={selectedStatuses.includes("Skip")}
                    >
                      Skip
                    </SelectOption>
                  </SelectList>
                </Select>
              </ToolbarFilter>
              <ToolbarFilter
                chips={filters.searchKey}
                deleteChip={onDeleteChip}
                categoryName={TASK_FILTER_CATEGORY.SEARCH_KEY}
              >
                <InputGroup>
                  <TextInput
                    name="search"
                    id="search"
                    type="search"
                    aria-label="Task Name"
                    onChange={(event, value) => setSearchInput(value)}
                    onKeyPress={onEnterClicked}
                    placeholder="Filter by task name"
                    value={searchInput}
                  />
                </InputGroup>
              </ToolbarFilter>
              <ToolbarItem>
                <Button
                  variant="primary"
                  onClick={onApplyFilter}
                  id="apply-filter-button"
                >
                  Apply filter
                </Button>
              </ToolbarItem>
            </ToolbarGroup>
          </ToolbarToggleGroup>
        </ToolbarContent>
      </Toolbar>
    </PageSection>
  );
};
