import {
  Masthead,
  MastheadMain,
  MastheadBrand,
  Brand,
  MastheadContent,
  ToolbarContent,
  Split,
  SplitItem,
  Toolbar,
  ToolbarGroup,
  ToolbarItem,
  Flex,
  Dropdown,
  DropdownItem,
  NotificationBadge,
  MenuToggle,
  MastheadToggle,
  PageToggleButton,
  Avatar,
  DropdownList,
} from "@patternfly/react-core";
import logo from "../../assets/favicon.png";
import "./header.scss";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getCurrentProcessMeta } from "../../utils";
import { ProcessesMetaInterface } from "../../constants/Processes";
import Modal from "../Modal";
import Settings from "./Settings";
import { getUser, updateUser } from "./apis";
import { utilsApiEndpoints } from "../../apis/endpoints";
import { AlertContext } from "../../contexts/alert/AlertContext";
import { INITIAL_USER, User } from "../../constants/User";
import { BarsIcon, BellIcon } from "@patternfly/react-icons";
import { useAuthContext } from "../../contexts/authentication/AuthenticationContext";

export const Header = ({
  notifications,
  isDrawerExpanded,
  onCloseNotificationDrawer,
  isSidebarOpen,
  onSidebarToggle,
}) => {
  const history = useHistory();
  const { logout } = useAuthContext();
  const alert = useContext(AlertContext);
  const [isOpen, setIsOpen] = useState(false);
  const currentProcessMeta: ProcessesMetaInterface = getCurrentProcessMeta();
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [userData, setUserData] = useState<User>(INITIAL_USER);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    getUser({
      url: utilsApiEndpoints.users,
      successCallback: (response) => {
        setUserData(response);
      },
      failureCallback: (response) => {
        alert.addAlert(response.message, "danger");
      },
    });
  };

  const onClickSaveSettings = () => {
    const payload = {
      emailNotificationEnabled: userData.emailNotificationEnabled,
      gchatNotificationEnabled: userData.gchatNotificationEnabled,
      slackNotificationEnabled: userData.slackNotificationEnabled,
    };

    updateUser({
      url: `${utilsApiEndpoints.users}/${userData.email}`,
      data: payload,
      successCallback: (response) => {
        setUserData(response);
        setIsSettingsModalOpen(false);
        alert.addAlert("Settings updated successfully!!!", "success");
      },
      failureCallback: (response) => {
        alert.addAlert(response.message, "danger");
      },
    });
  };

  const dropdownItems = [
    <DropdownList key="dropdownGroup">
      <DropdownItem onClick={() => setIsSettingsModalOpen(true)}>
        Settings
      </DropdownItem>
      <DropdownItem onClick={() => logout()} key="dropdown-logout">
        Logout
      </DropdownItem>
    </DropdownList>,
  ];

  const handleModalToggle = () => {
    getUserData();
    setIsSettingsModalOpen(!isSettingsModalOpen);
  };

  const onFocus = () => {
    const element = document.getElementById("toggle-image-and-text");
    element.focus();
  };

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  const onDropDownSelect = () => {
    setIsOpen(false);
    onFocus();
  };

  return (
    <>
      <Masthead className="header">
        <MastheadToggle>
          <PageToggleButton
            variant="plain"
            aria-label="Global navigation"
            isSidebarOpen={isSidebarOpen}
            onSidebarToggle={onSidebarToggle}
            id="vertical-nav-toggle"
          >
            <BarsIcon />
          </PageToggleButton>
        </MastheadToggle>
        <MastheadMain>
          <MastheadBrand>
            <Brand
              width="50px"
              height="50px"
              src={logo}
              alt="Red Hat default logo"
              onClick={() => history.push("/")}
              style={{ cursor: "pointer" }}
            />
          </MastheadBrand>
        </MastheadMain>
        <MastheadContent>
          <Toolbar id="toolbar" isFullHeight isStatic>
            <ToolbarContent style={{ justifyContent: "space-between" }}>
              <ToolbarGroup
                variant="icon-button-group"
                spacer={{ default: "spacerNone", md: "spacerMd" }}
              >
                <ToolbarItem
                  variant="separator"
                  className={"hr-vertical"}
                ></ToolbarItem>
                <ToolbarItem
                  style={{
                    marginBottom: 10,
                    marginTop: "10px",
                    marginRight: "8px",
                    marginLeft: "18px",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Flex
                    style={{
                      fontSize: "1.3rem",
                    }}
                  >
                    Process Accelerator
                  </Flex>
                  <Flex
                    style={{
                      fontSize: "1.1rem",
                    }}
                  >
                    {currentProcessMeta?.name}
                  </Flex>
                </ToolbarItem>
              </ToolbarGroup>
              <ToolbarGroup>
                <ToolbarItem>
                  <Split hasGutter>
                    <SplitItem>
                      <NotificationBadge
                        variant="read"
                        onClick={(event) => onCloseNotificationDrawer(event)}
                        aria-label="Notifications"
                        isExpanded={isDrawerExpanded}
                        count={notifications.length}
                      >
                        <BellIcon />
                      </NotificationBadge>
                    </SplitItem>
                  </Split>
                </ToolbarItem>
                <ToolbarItem>
                  <Split hasGutter>
                    <SplitItem>
                      <Dropdown
                        className="header-dropdown"
                        onSelect={onDropDownSelect}
                        toggle={(toggleRef) => (
                          <MenuToggle
                            ref={toggleRef}
                            id="toggle-image-and-text"
                            variant="plain"
                            isExpanded={isOpen}
                            onClick={onToggle}
                          >
                            <Avatar
                              src="/images/avatar.svg"
                              alt="avatar"
                              size="md"
                            />
                          </MenuToggle>
                        )}
                        isOpen={isOpen}
                        onOpenChange={() => setIsOpen(false)}
                      >
                        {dropdownItems}
                      </Dropdown>
                    </SplitItem>
                  </Split>
                </ToolbarItem>
              </ToolbarGroup>
            </ToolbarContent>
          </Toolbar>
        </MastheadContent>
      </Masthead>
      {isSettingsModalOpen && (
        <Modal
          variant="small"
          children={<Settings userData={userData} setUserData={setUserData} />}
          title="Settings"
          description="Update your preferences."
          isModalOpen={isSettingsModalOpen}
          handleModalToggle={handleModalToggle}
          handleModalSubmit={onClickSaveSettings}
        />
      )}
    </>
  );
};
