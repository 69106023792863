import { FormGroup } from "@patternfly/react-core";
import {
  Dropdown,
  DropdownItem,
  DropdownToggle,
} from "@patternfly/react-core/deprecated";
import { useState } from "react";

interface DropdownInputProps {
  dropdownItems: { value: string; label: string; isDisabled?: boolean }[];
  label?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  style?: React.CSSProperties;
  id: string;
  value?: { value: string; label: string };
  setValue: (value: { value: string; label: string }) => void;
  placeholder?: string;
}

const DropdownInput: React.FC<DropdownInputProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const onToggle = (event, isOpen: boolean) => {
    setIsOpen(isOpen);
  };

  const dropdownItems = props.dropdownItems.map((item, key) => {
    return (
      <DropdownItem
        key={key}
        id={item.value}
        component="button"
        isDisabled={item.isDisabled || false}
      >
        {item.label}
      </DropdownItem>
    );
  });
  return (
    <FormGroup
      style={{ ...props.style }}
      label={props.label}
      isRequired={props.isRequired || false}
      id={props.id}
    >
      <Dropdown
        value={props.value.value}
        style={{ width: "100%" }}
        isOpen={isOpen}
        id={props.id}
        dropdownItems={dropdownItems}
        toggle={
          props.dropdownItems.length === 1 ? (
            <DropdownToggle style={{ width: "100%" }} onToggle={onToggle}>
              {props.dropdownItems[0].label}
            </DropdownToggle>
          ) : (
            <DropdownToggle style={{ width: "100%" }} onToggle={onToggle}>
              {props.value.label || props.placeholder}
            </DropdownToggle>
          )
        }
        onSelect={(e) => {
          props.setValue({
            value: e.currentTarget.id,
            label: e.currentTarget.innerText,
          });
          setIsOpen(false);
        }}
      />
    </FormGroup>
  );
};

export default DropdownInput;
