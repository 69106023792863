import { useContext, useEffect, useState } from "react";
import { Page, PageSection, PageSectionVariants } from "@patternfly/react-core";
import { NotificationDrawer } from "./components/NotificationDrawer";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { Header } from "./components/Header/Header";
import { AlertContext } from "./contexts/alert/AlertContext";
import { routes } from "./routes/routes";
import {
  authorize,
  getNotifications,
  readNotification,
} from "./apis/pa-utils/apis";
import { UTILS_API_BASE_URL, utilsApiEndpoints } from "./apis/endpoints";
import Sidebar from "./components/Sidebar";

function App() {
  const location = useLocation();
  const alert = useContext(AlertContext);
  const [notifications, setNotifications] = useState([]);
  const mainContainerId = "main-content";
  const [isDrawerExpanded, setIsDrawerExpanded] = useState(false);
  const standAlonePages = ["account-planning", "rhsc-assistant"];
  const isStandAlonePage = standAlonePages.some((page) =>
    location.pathname.includes(page)
  );
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const onSidebarToggle = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const onCloseNotificationDrawer = () => {
    setIsDrawerExpanded(!isDrawerExpanded);
  };

  const markAllRead = () => {
    markNotificationsAsRead(notifications);
  };

  const onNotificationClick = (notification) => {
    markNotificationsAsRead([notification]);
  };

  const markNotificationsAsRead = (_notifications) => {
    readNotification({
      url: utilsApiEndpoints.read,
      data: _notifications,
      successCallback: (response) => {
        fetchNotifications();
      },
      failureCallback: (response) => {
        alert.addAlert(response.message, "danger");
      },
    });
  };

  const fetchNotifications = () => {
    getNotifications({
      url: utilsApiEndpoints.notifications,
      successCallback: (response) => {
        setNotifications(response);
      },
      failureCallback: (response) => {
        alert.addAlert(response.message, "danger");
      },
    });
  };

  const registerForSSE = (token) => {
    const sse = new EventSource(
      `${UTILS_API_BASE_URL}${utilsApiEndpoints.register}?token=${encodeURI(
        token
      )}`,
      {
        withCredentials: false,
      }
    );

    sse.onopen = (e) => fetchNotifications();

    sse.addEventListener("comment", (e) => {
      fetchNotifications();
    });

    sse.onerror = () => {
      // error log here
      sse.close();
    };

    return () => {
      sse.close();
    };
  };

  useEffect(() => {
    if (!isStandAlonePage) {
      authorize({
        url: utilsApiEndpoints.authorize,
        successCallback: (response) => {
          registerForSSE(response);
        },
        failureCallback: (response) => {
          alert.addAlert(response.message, "danger");
        },
      });
    }
  }, []);

  return (
    <Page
      header={
        !isStandAlonePage && (
          <Header
            notifications={notifications}
            isDrawerExpanded={isDrawerExpanded}
            onCloseNotificationDrawer={onCloseNotificationDrawer}
            isSidebarOpen={isSidebarOpen}
            onSidebarToggle={onSidebarToggle}
          />
        )
      }
      sidebar={!isStandAlonePage && <Sidebar isSidebarOpen={isSidebarOpen} />}
      notificationDrawer={
        !isStandAlonePage && (
          <NotificationDrawer
            notifications={notifications}
            markAllRead={markAllRead}
            onCloseNotificationDrawer={onCloseNotificationDrawer}
            onNotificationClick={onNotificationClick}
          />
        )
      }
      isManagedSidebar
      mainContainerId={mainContainerId}
      isNotificationDrawerExpanded={isDrawerExpanded}
    >
      <PageSection
        className="main-page"
        style={{ padding: 0 }}
        variant={PageSectionVariants.default}
      >
        <Switch>
          <Redirect exact from="/" to="/processes" />
          {routes.map((route) => (
            <Route
              key={route.name}
              exact={route.exact}
              path={route.path}
              component={route.component}
            />
          ))}
        </Switch>
      </PageSection>
    </Page>
  );
}

export default App;
