import {
  Button,
  Grid,
  GridItem,
  Form as PFForm,
  ActionGroup,
  TextArea,
  FormGroup,
  PageSection,
  PageSectionVariants,
  TextContent,
  Text,
  HelperText,
  HelperTextItem,
} from "@patternfly/react-core";
import { useContext, useEffect, useState } from "react";
import {
  RadioInput,
  BasicTextInput,
  DropdownInput,
  DateInput,
} from "../../../components/InputFields";
import { getProjects, getProjectDetails, createPca } from "./apis";
import { useHistory } from "react-router-dom";
import {
  PROCESS_INPUT_FIELDS,
  PCF_OPTIONS,
  PRODUCT_TYPE_OPTIONS,
  AGREEMENT_OPTIONS,
  BILLING_TYPES,
  FULFILLMENT_CHANNEL_TYPE,
  PROJECT_TYPES,
  PRODUCT_TYPES,
  BILLING_ARRANGEMENTS,
} from "../../../constants/CreateProcess";
import { AlertContext } from "../../../contexts/alert/AlertContext";
import VALIDATION from "../../../constants/validation";
import { PCA_TYPE_TO_LABEL_AND_FIELDS } from "../../../constants/Process";
import moment from "moment";
import _ from "lodash";
import { getProcessType } from "../../../utils";
import { ChevronLeftIcon } from "@patternfly/react-icons";
import { useAuthContext } from "../../../contexts/authentication/AuthenticationContext";

const CreatePCF = () => {
  const history = useHistory();
  const { user } = useAuthContext();
  const alert = useContext(AlertContext);
  const currentProcessType = getProcessType();

  const [formValues, setFormValues] = useState({
    opportunityNumber: "",
    projectId: "",
    typeOfProjectChangeApproval: PCF_OPTIONS[0].value,
    typeOfAgreement: AGREEMENT_OPTIONS[0].value,
    typeOfProduct: PRODUCT_TYPE_OPTIONS[0].value,
  });
  const [prevDates, setPrevDates] = useState({
    startDate: "",
    endDate: "",
    closeDate: "",
  });
  const [additionalFieldValues, setAdditionalFieldsValues] = useState({
    additionalScope: "",
    remixHours: "",
    additionOfSKU: "",
    changeOfScope: "",
    newStartDate: "",
    newEndDate: "",
    contractTermResponse: "",
    numberOfExtensionsAlreadyHad: 0,
    linkToPSAScheduledPlannedAssignments: "",
    billType: "",
    justification: "",
  });
  const [preFilledFormValues, setPreFilledFormValues] = useState({
    customerCompanyName: "",
    redHatRepresentativeName: "",
    redHatRepresentativeEmail: "",
    regionalServiceManagerName: "",
    regionalServiceManagerEmail: "",
    opportunityNumber: "",
    totalPlannedHours: "",
    totalRemainingHours: "",
    totalRemainingRevenueBacklog: "",
  });

  const [optionalFields, setOptionalFields] = useState({
    customerContactName: "",
    customerEmailAddress: "",
    docuSignName: "",
    docuSignEmail: "",
  });

  const [selectedPCFType, setSelectedPCFType] = useState<{
    label: string;
    value: string;
  }>(PCF_OPTIONS[0]);
  const [selectedTypeOfAgreement, setSelectedTypeOfAgreement] = useState<{
    label: string;
    value: string;
  }>(AGREEMENT_OPTIONS[0]);
  const [selectedTypeOfProduct, setSelectedTypeOfProduct] = useState<{
    label: string;
    value: string;
  }>(PRODUCT_TYPE_OPTIONS[0]);
  const [totalRemainingHours, setTotalRemainingHours] = useState("");

  const [projects, setProjects] = useState<any[]>([]);

  const [isTypeOfAgreementDisabled, setIsTypeOfAgreementDisabled] =
    useState<boolean>(false);
  const [isTypeOfProductDisabled, setIsTypeOfProductDisabled] =
    useState<boolean>(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);

  useEffect(() => {
    setFormValues({ ...formValues, projectId: "" });
    setPreFilledFormValues({
      customerCompanyName: "",
      redHatRepresentativeName: "",
      redHatRepresentativeEmail: "",
      regionalServiceManagerName: "",
      regionalServiceManagerEmail: "",
      opportunityNumber: "",
      totalPlannedHours: "",
      totalRemainingHours: "",
      totalRemainingRevenueBacklog: "",
    });
    setOptionalFields({
      customerContactName: "",
      customerEmailAddress: "",
      docuSignName: "",
      docuSignEmail: "",
    });
    if (formValues.opportunityNumber.length >= 7) {
      getProjects({
        dataToSend: { opportunityNumber: formValues.opportunityNumber },
        successCallback: (data) => {
          data.forEach((project: any) => {
            setProjects((projects) => [
              ...projects,
              { label: project, value: project },
            ]);
          });
          if (data.length === 1) {
            setFormValues({ ...formValues, projectId: data[0] });
          }
        },
        failureCallback: () => {},
      });
    }
    return () => {
      setProjects([]);
    };
  }, [formValues.opportunityNumber]);

  useEffect(() => {
    if (formValues.projectId.length !== 0)
      getProjectDetails({
        dataToSend: {
          pca: {
            projectId: formValues.projectId,
            opportunityNumber: formValues.opportunityNumber,
          },
        },
        successCallback: (data) => {
          let opportunity = data?.pca?.opportunity;
          setPreFilledFormValues({
            ...preFilledFormValues,
            customerCompanyName:
              opportunity?.channelType ===
              FULFILLMENT_CHANNEL_TYPE.DIRECT_END_CUSTOMER
                ? opportunity?.accountName
                : opportunity?.partnerName,
            redHatRepresentativeName: opportunity?.opportunityOwner,
            redHatRepresentativeEmail: opportunity.opportunityOwnerEmail,
            regionalServiceManagerName: opportunity?.regionalServiceManagerName,
            regionalServiceManagerEmail:
              opportunity?.regionalServiceManagerEmail,
            opportunityNumber: opportunity?.opportunityNumber,
            totalPlannedHours: opportunity?.totalPlannedHours,
            totalRemainingHours: opportunity?.totalRemainingHours,
            totalRemainingRevenueBacklog:
              opportunity?.totalRemainingRevenueBacklog,
          });
          setPrevDates({
            ...prevDates,
            startDate: opportunity?.startDate.join("-"),
            endDate: opportunity?.endDate.join("-"),
            closeDate: opportunity?.closeDate.join("-"),
          });
          setAdditionalFieldsValues({
            ...additionalFieldValues,
            newStartDate: moment(opportunity?.startDate.join("-")).format(
              "YYYY-MM-DD"
            ),
            newEndDate: moment(opportunity?.endDate.join("-")).format(
              "YYYY-MM-DD"
            ),
            billType: BILLING_TYPES.find(
              (value) => value.value !== opportunity?.billingArrangement
            ).value,
          });

          if (
            opportunity?.channelType === FULFILLMENT_CHANNEL_TYPE.DISTRIBUTOR ||
            opportunity?.channelType ===
              FULFILLMENT_CHANNEL_TYPE.DIRECT_RESELLER ||
            opportunity?.channelType === FULFILLMENT_CHANNEL_TYPE.OEM
          ) {
            if (opportunity?.partnerName.toUpperCase().includes("IBM")) {
              setSelectedTypeOfAgreement(AGREEMENT_OPTIONS[2]);
              setIsTypeOfAgreementDisabled(false);
            } else {
              setSelectedTypeOfAgreement(AGREEMENT_OPTIONS[0]);
              setIsTypeOfAgreementDisabled(true);
            }
          } else if (
            opportunity?.channelType ===
            FULFILLMENT_CHANNEL_TYPE.DIRECT_END_CUSTOMER
          ) {
            if (opportunity?.accountName.toUpperCase().includes("IBM")) {
              setSelectedTypeOfAgreement(AGREEMENT_OPTIONS[2]);
              setIsTypeOfAgreementDisabled(false);
            } else {
              setSelectedTypeOfAgreement(AGREEMENT_OPTIONS[1]);
              setIsTypeOfAgreementDisabled(true);
            }
          }

          // Prefill product type field as per below matrix
          // Project Type	        Billing Type	          Billing Arrangement	  Type of Project
          // -----------------------------------------------------------------------------------
          // Customer Project	    Time and Materials	    Monthly	              Time and Materials
          // Customer Project	    Time and Materials	    Upfront	              Time and Materials
          // Customer Project	    Fixed Price	            Any version	          Fixed Price
          // Consulting Unit	    Time and Materials	    Upfront	              Consulting Unit
          if (opportunity?.projectType === PROJECT_TYPES.CUSTOMER_PROJECT) {
            if (opportunity?.billingType === PRODUCT_TYPES.TIME_AND_MATERIALS) {
              if (
                opportunity?.billingArrangement ===
                  BILLING_ARRANGEMENTS.UPFRONT ||
                opportunity?.billingArrangement === BILLING_ARRANGEMENTS.MONTHLY
              ) {
                const productType = PRODUCT_TYPE_OPTIONS.find(
                  (option) => option.label === PRODUCT_TYPES.TIME_AND_MATERIALS
                );
                setSelectedTypeOfProduct(productType);
                setFormValues({
                  ...formValues,
                  typeOfProduct: productType.value,
                });
                setIsTypeOfProductDisabled(true);
              }
            } else if (opportunity?.billingType === PRODUCT_TYPES.FIXED_PRICE) {
              const productType = PRODUCT_TYPE_OPTIONS.find(
                (option) => option.label === PRODUCT_TYPES.FIXED_PRICE
              );
              setSelectedTypeOfProduct(productType);
              setFormValues({
                ...formValues,
                typeOfProduct: productType.value,
              });
              setIsTypeOfProductDisabled(true);
            }
          } else if (
            opportunity?.projectType === PROJECT_TYPES.CONSULTING_UNIT &&
            opportunity?.billingType === PRODUCT_TYPES.TIME_AND_MATERIALS &&
            opportunity?.billingArrangement === BILLING_ARRANGEMENTS.UPFRONT
          ) {
            const productType = PRODUCT_TYPE_OPTIONS.find(
              (option) => option.label === PRODUCT_TYPES.CONSULTING_UNIT
            );
            setSelectedTypeOfProduct(productType);
            setFormValues({
              ...formValues,
              typeOfProduct: productType.value,
            });
            setIsTypeOfProductDisabled(true);
          }
        },
        failureCallback: () => {},
      });
  }, [formValues.projectId]);

  useEffect(() => {
    setFormValues({
      ...formValues,
      typeOfAgreement: selectedTypeOfAgreement.value,
    });
  }, [selectedTypeOfAgreement]);

  const normalizeBetween = (value, min, max) => {
    if (min !== undefined && max !== undefined) {
      return Math.max(Math.min(value, max), min);
    } else if (value <= min) {
      return min;
    } else if (value >= max) {
      return max;
    }
    return value;
  };

  const onBlurNumberField = (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const normalizedValue = normalizeBetween(
      isNaN(+target.value) ? 0 : Number(target.value),
      0,
      Infinity
    );

    return normalizedValue;
  };

  const onSubmit = (value: any) => {
    setIsSubmitDisabled(true);
    value.preventDefault();
    let isValid = Object.values(formValues).every(
      (value) => value.length !== 0
    );

    const requiredAdditionalFields = _.pick(
      additionalFieldValues,
      PCA_TYPE_TO_LABEL_AND_FIELDS[selectedPCFType.value].fields.map(
        (field) => field.key
      )
    );
    isValid =
      isValid &&
      Object.values(requiredAdditionalFields).every(
        (value) => value.length !== 0
      );
    if (!isValid) {
      alert.addAlert("Please fill all the required fields", "danger");
      setIsSubmitDisabled(false);
      return;
    }

    if (
      formValues.typeOfProjectChangeApproval !== "EXT" &&
      !VALIDATION.length(
        requiredAdditionalFields.justification.trim(),
        15,
        Infinity
      )
    ) {
      alert.addAlert(
        "Justification should be of minimum 15 characters",
        "danger"
      );
      setIsSubmitDisabled(false);
      return;
    }

    var updatedPreFilledFormValues = {
      ...preFilledFormValues,
    };
    if (
      selectedTypeOfProduct.label === PRODUCT_TYPES.CONSULTING_UNIT ||
      selectedTypeOfProduct.label === PRODUCT_TYPES.FIXED_PRICE
    ) {
      if (totalRemainingHours.length === 0) {
        alert.addAlert("Please fill all the required fields", "danger");
        setIsSubmitDisabled(false);
        return;
      } else {
        updatedPreFilledFormValues = {
          ...preFilledFormValues,
          totalRemainingHours: totalRemainingHours,
        };
      }
    }

    if (isValid) {
      createPca({
        dataToSend: {
          pca: {
            ...formValues,
            ...updatedPreFilledFormValues,
            ...requiredAdditionalFields,
            ...optionalFields,
            createdByEmail: user?.email,
            createdBy: user?.name,
          },
        },
        params: {
          businessKey: formValues.opportunityNumber,
        },
        successCallback: (data) => {
          alert.addAlert("Project change request submitted.", "success");
          history.push("/processes/" + currentProcessType);
        },
        failureCallback: () => {
          setIsSubmitDisabled(false);
        },
      });
    } else {
      setIsSubmitDisabled(false);
      alert.addAlert("Please fill all the required fields.", "danger");
    }
  };
  return (
    <>
      <PageSection variant={PageSectionVariants.light}>
        <Grid>
          <GridItem span={8} style={{ display: "flex" }}>
            <button
              type="button"
              className="back-to-list-button"
              onClick={() => history.push("/processes/pcf")}
            >
              <ChevronLeftIcon height="14px" />
            </button>
            <TextContent>
              <Text component="h1">
                Create Process - Project Change Form Generator
              </Text>
            </TextContent>
          </GridItem>
        </Grid>
      </PageSection>
      <div style={{ margin: 20 }}>
        <PFForm
          onSubmit={onSubmit}
          style={{ padding: "10px 40px", background: "white", height: "100%" }}
        >
          <Grid hasGutter style={{ marginTop: "15px" }}>
            <GridItem span={6}>
              <BasicTextInput
                validation={(value) => {
                  return VALIDATION.length(value, 7, 18);
                }}
                helperText="Opportunity Number should be 7 characters long."
                maxLength={18}
                isRequired={true}
                id="opportunityNumber"
                label="Opportunity Number"
                value={formValues.opportunityNumber}
                setValue={(value) => {
                  setFormValues({ ...formValues, opportunityNumber: value });
                }}
              />
            </GridItem>
            <GridItem span={6}>
              <DropdownInput
                isRequired={true}
                style={{ width: "100%" }}
                value={{
                  label: formValues.projectId,
                  value: formValues.projectId,
                }}
                setValue={(value) => {
                  setFormValues({ ...formValues, projectId: value.value });
                }}
                placeholder="Select Project Id"
                label="Project Ids"
                id="projectId"
                dropdownItems={projects || []}
              />
            </GridItem>
            {PROCESS_INPUT_FIELDS.map((input, key) => {
              return (
                <GridItem key={key} span={6}>
                  <BasicTextInput
                    validation={input?.validation}
                    value={
                      input.isOptional
                        ? optionalFields[input.id]
                        : preFilledFormValues[input.id]
                    }
                    isDisabled={input.isDisabled}
                    id={input.id}
                    label={input.name}
                    setValue={(value) => {
                      input.isOptional
                        ? setOptionalFields({
                            ...optionalFields,
                            [input.id]: value,
                          })
                        : setPreFilledFormValues({
                            ...preFilledFormValues,
                            [input.id]: value,
                          });
                    }}
                  />
                </GridItem>
              );
            })}
          </Grid>
          <Grid hasGutter>
            <GridItem span={12}>
              <RadioInput
                style={{ marginTop: "15px" }}
                isRequired={true}
                id="pcfType"
                label="Type of PCF?"
                options={PCF_OPTIONS.filter((value) => !value.isDisabled)}
                selectedValue={selectedPCFType}
                setSelectedValue={(value) => {
                  setSelectedPCFType(value);
                  setFormValues({
                    ...formValues,
                    typeOfProjectChangeApproval: value.value,
                  });
                }}
              />
            </GridItem>
            {selectedPCFType.value !== "ROH" &&
              selectedPCFType.value !== "BTC" && (
                <div
                  style={{ marginTop: "15px" }}
                  className="pf-c-form__label-text"
                >
                  {selectedPCFType.label}
                  <span
                    className="pf-c-form__label-required"
                    aria-hidden="true"
                  >
                    {" "}
                    *
                  </span>
                </div>
              )}
            <FormGroup label="Term Period">
              <Grid hasGutter>
                <GridItem span={6}>
                  <DateInput
                    isDisabled={selectedPCFType.value !== "EXT"}
                    isRequired={true}
                    style={{ width: "100%" }}
                    id="extensionStartDate"
                    helperText="Start Date"
                    {...(prevDates.startDate?.length > 0
                      ? { value: new Date(prevDates.startDate) }
                      : {})}
                    setValue={(value) => {
                      setAdditionalFieldsValues({
                        ...additionalFieldValues,
                        newStartDate: moment(value).format("YYYY-MM-DD"),
                      });
                    }}
                  />
                </GridItem>
                <GridItem span={6}>
                  <DateInput
                    isDisabled={selectedPCFType.value !== "EXT"}
                    isRequired={true}
                    style={{ width: "100%" }}
                    id="extensionEndDate"
                    helperText="End Date"
                    {...(prevDates.endDate?.length > 0
                      ? { value: new Date(prevDates.endDate) }
                      : {})}
                    setValue={(value) => {
                      setAdditionalFieldsValues({
                        ...additionalFieldValues,
                        newEndDate: moment(value).format("YYYY-MM-DD"),
                      });
                    }}
                  />
                </GridItem>
              </Grid>
            </FormGroup>
            <AdditionalRequiredFields
              formValues={additionalFieldValues}
              setFormValues={setAdditionalFieldsValues}
              field={selectedPCFType.value}
              onBlurNumberField={onBlurNumberField}
            />
            <GridItem span={12}>
              <RadioInput
                isDisabled={isTypeOfAgreementDisabled}
                isRequired={true}
                style={{ marginTop: "15px" }}
                id="soldUnderServices"
                label="Type of agreement sold under Services?"
                options={AGREEMENT_OPTIONS}
                selectedValue={selectedTypeOfAgreement}
                setSelectedValue={(value) => {
                  setSelectedTypeOfAgreement(value);
                  setFormValues({
                    ...formValues,
                    typeOfAgreement: value.value,
                  });
                }}
              />
            </GridItem>
            <GridItem span={12}>
              <RadioInput
                isRequired={true}
                isDisabled={isTypeOfProductDisabled}
                style={{ marginTop: "15px" }}
                id="product"
                label="Type of Product?"
                options={PRODUCT_TYPE_OPTIONS}
                selectedValue={selectedTypeOfProduct}
                setSelectedValue={(value) => {
                  setSelectedTypeOfProduct(value);
                  setFormValues({ ...formValues, typeOfProduct: value.value });
                }}
              />
            </GridItem>
            {(selectedTypeOfProduct.label === PRODUCT_TYPES.CONSULTING_UNIT ||
              selectedTypeOfProduct.label === PRODUCT_TYPES.FIXED_PRICE) && (
              <GridItem key="totalRemainingHours" span={12}>
                <BasicTextInput
                  isRequired={true}
                  id="totalRemainingHours"
                  value={totalRemainingHours}
                  type="number"
                  setValue={(value) => {
                    setTotalRemainingHours(value);
                  }}
                  label="Total Remaining Hours/Amount"
                  onBlur={(value: React.FormEvent<HTMLInputElement>) => {
                    const normalizedValue = onBlurNumberField(value);
                    setTotalRemainingHours(normalizedValue);
                  }}
                />
              </GridItem>
            )}
            <GridItem span={6}>
              <BasicTextInput
                value={optionalFields.docuSignName}
                isDisabled={false}
                id="docuSignName"
                label="DocuSign Name"
                setValue={(value) => {
                  setOptionalFields({
                    ...optionalFields,
                    docuSignName: value,
                  });
                }}
              />
            </GridItem>
            <GridItem span={6}>
              <BasicTextInput
                validation={VALIDATION.email}
                value={optionalFields.docuSignEmail}
                isDisabled={false}
                id="docuSignEmail"
                label="DocuSign Email"
                setValue={(value) => {
                  setOptionalFields({
                    ...optionalFields,
                    docuSignEmail: value,
                  });
                }}
              />
            </GridItem>
          </Grid>

          <ActionGroup style={{ marginBottom: 50 }}>
            <Button
              type="submit"
              style={{ fontWeight: "500" }}
              variant="primary"
              isDisabled={isSubmitDisabled}
              isLoading={isSubmitDisabled}
            >
              {isSubmitDisabled ? "Creating..." : "Create"}
            </Button>
          </ActionGroup>
        </PFForm>
      </div>
    </>
  );
};

const AdditionalRequiredFields = ({
  field,
  formValues,
  setFormValues,
  onBlurNumberField,
}) => {
  const [isJustificationValidated, setIsJustificationValidated] = useState<
    "default" | "error" | "warning" | "success" | undefined
  >("default");

  const extraFields = [
    {
      name: "Please Include Link to PSA Scheduled Planned (not tentative) Assignments",
      id: "linkToPSAScheduledPlannedAssignments",
      isDisabled: true,
      type: "url",
    },
    {
      name: "How many Extensions has this Opportunity had already?",
      id: "numberOfExtensionsAlreadyHad",
      isDisabled: true,
      type: "number",
    },
  ];
  switch (field) {
    case "EXT":
      return (
        <Grid hasGutter>
          <GridItem span={12}>
            <FormGroup
              label="Why wasn't it possible to deliver within the contract term?"
              isRequired={true}
            >
              <TextArea
                id="contractTermResponse"
                value={formValues?.contractTermResponse}
                onChange={(_event, value) =>
                  setFormValues({ ...formValues, contractTermResponse: value })
                }
              />
            </FormGroup>
          </GridItem>
          {extraFields.map((field, key) => {
            return (
              <GridItem key={key} span={6}>
                <BasicTextInput
                  isRequired={true}
                  id={field.id}
                  value={formValues[field.id]}
                  type={field.type}
                  setValue={(value) => {
                    setFormValues({ ...formValues, [field.id]: value });
                  }}
                  label={field.name}
                  {...(field.id === "numberOfExtensionsAlreadyHad"
                    ? {
                        onBlur: (value: React.FormEvent<HTMLInputElement>) => {
                          const normalizedValue = onBlurNumberField(value);
                          setFormValues({
                            ...formValues,
                            numberOfExtensionsAlreadyHad: normalizedValue,
                          });
                        },
                      }
                    : {})}
                />
              </GridItem>
            );
          })}
        </Grid>
      );
    case "ROH":
      return (
        <Grid hasGutter>
          <GridItem span={6}>
            <BasicTextInput
              isRequired={true}
              id="remixHours"
              label="Remix of Hours"
              value={formValues.remixOfHours}
              setValue={(value) => {
                setFormValues({ ...formValues, remixHours: value });
              }}
            />
          </GridItem>
          <GridItem span={6}>
            <BasicTextInput
              isRequired={true}
              id="additionOfSKU"
              label="Addition of SKUs"
              value={formValues.additionOfSKU}
              setValue={(value) => {
                setFormValues({ ...formValues, additionOfSKU: value });
              }}
            />
          </GridItem>
        </Grid>
      );
    case "BTC":
      return (
        <>
          <RadioInput
            label="Billing Type Change?"
            id={field}
            selectedValue={
              formValues.billType.length === 0
                ? BILLING_TYPES[0]
                : BILLING_TYPES.find(
                    (value) => value.value === formValues.billType
                  )
            }
            options={
              formValues.billType.length === 0
                ? BILLING_TYPES
                : BILLING_TYPES.filter(
                    (value) => value.value === formValues.billType
                  )
            }
          />
          <FormGroup isRequired={true} label="Justification">
            <HelperText>
              <HelperTextItem>
                Justification should be of minimum 15 characters
              </HelperTextItem>
            </HelperText>
            <TextArea
              validated={isJustificationValidated}
              min={15}
              id="justification"
              value={formValues.justification}
              onChange={(_event, value) => {
                setFormValues({ ...formValues, justification: value });
                setIsJustificationValidated(
                  VALIDATION.length(
                    formValues.justification.trim(),
                    15,
                    Infinity
                  )
                    ? "success"
                    : "error"
                );
              }}
              minLength={12}
            />
          </FormGroup>
        </>
      );
    case "OTH":
      return (
        <Grid hasGutter>
          <GridItem span={12}>
            <BasicTextInput
              isRequired={true}
              id="changeOfScope"
              label="Changing of Scope"
              value={formValues.changeOfScope}
              setValue={(value) => {
                setFormValues({ ...formValues, changeOfScope: value });
              }}
            />
          </GridItem>
          <GridItem span={12}>
            <BasicTextInput
              isRequired={true}
              id="additionalScope"
              label="Additional of scope"
              value={formValues.additionalScope}
              setValue={(value) => {
                setFormValues({ ...formValues, additionalScope: value });
              }}
            />
          </GridItem>
        </Grid>
      );
  }
};

export default CreatePCF;
