import { useContext, useState } from "react";
import { RadioInput } from "../../../components/InputFields";
import {
  Button,
  Flex,
  Form,
  Text,
  TextArea,
  TextContent,
  TextVariants,
} from "@patternfly/react-core";
import { ArrowLeftIcon } from "@patternfly/react-icons";
import { useHistory } from "react-router-dom";
import { AlertContext } from "../../../contexts/alert/AlertContext";
import { changeTaskState } from "../CreateAccountPlan/apis";
import { accountPlanningEndpoints } from "../../../apis/endpoints";
import { useAuthContext } from "../../../contexts/authentication/AuthenticationContext";

function RateAccountPlan({ task }) {
  const history = useHistory();
  const alert = useContext(AlertContext);
  const { user } = useAuthContext();

  let taskInputs = JSON.parse(task?.inputs);
  const [feedback, setFeedback] = useState("");
  const questionnaireList = taskInputs?.accountPlanReview?.questionnaireList;
  const options: { label: string; value: string }[] = [
    { label: "Strongly Disagree", value: "1" },
    { label: "Disagree", value: "2" },
    { label: "Agree", value: "3" },
    { label: "Strongly Agree", value: "4" },
  ];
  const [selectedValues, setSelectedValues] = useState({
    0: {},
    1: {},
    2: {},
    3: {},
    4: {},
    5: {},
    6: {},
  });

  const handleOnClickNext = () => {
    const isEmpty = Object.values(selectedValues).some(
      (obj) => Object.keys(obj).length === 0
    );
    if (isEmpty) {
      alert.addAlert("Please answer all the mandatory questions!", "danger");
      return;
    }

    let params = new URLSearchParams();
    params.append("user", user?.email);

    let _questionnaireList = questionnaireList;
    for (let i = 0; i < _questionnaireList.length; i++) {
      _questionnaireList[i].rating = parseInt(selectedValues[i].value, 10);
    }

    const payload = {
      accountPlanReview: {
        ...taskInputs?.accountPlanReview,
        questionnaireList: _questionnaireList,
        feedback: feedback,
      },
    };

    changeTaskState({
      url: `${accountPlanningEndpoints.accountPlanning}/${task.processInstanceId}/${task.name}/${task.id}/phases/complete`,
      dataToSend: payload,
      params: params,
      successCallback: (data) => {
        history.push(
          `/account-planning/${task.processInstanceId}?task=account_plan_approval`
        );
      },
      failureCallback: (data) => {
        alert.addAlert(
          data?.response?.data?.message || "Something went wrong.",
          "danger"
        );
      },
    });
  };

  return (
    <div className="questionnaire-form">
      <TextContent>
        <Text component={TextVariants.h2} className="sub-heading">
          Step 1: Rate Account Plan
        </Text>
      </TextContent>
      <p style={{ marginTop: "10px" }}>
        {`Reviewing ${taskInputs.accountPlanReview.sourceRecordID} | ${taskInputs.accountPlanReview.accountPlanName}`}
      </p>
      <p style={{ marginTop: "10px" }}>
        Click{" "}
        <a
          href="https://www.google.com/url?q=https://docs.google.com/document/d/1bKajQKjCgXApiTd8JKeiXw1h7qK6nbbhUftSAiiYGbs/"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>{" "}
        for guidance on how to answer the below questions
      </p>
      <Form style={{ margin: "10px 0" }}>
        {questionnaireList.map((questionnaireItem, index) => (
          <RadioInput
            key={index}
            label={index + 1 + ". " + questionnaireItem.question}
            id={questionnaireItem.number}
            options={options}
            selectedValue={selectedValues[questionnaireItem.number]}
            setSelectedValue={(value, id) => {
              setSelectedValues({ ...selectedValues, [id]: value });
            }}
          />
        ))}
        <TextArea
          isRequired={false}
          id="feedback"
          label="Feedback"
          placeholder="General Feedback for Sales Associate (Optional)"
          value={feedback}
          onChange={(_event, value) => setFeedback(value)}
        />
        <Flex>
          <Button
            icon={<ArrowLeftIcon />}
            onClick={() => history.push("/account-planning")}
          >
            Back to home
          </Button>
          <Button onClick={() => handleOnClickNext()}>Next</Button>
        </Flex>
      </Form>
    </div>
  );
}

export default RateAccountPlan;
