import "./style.scss";

const LoadingMessage = () => {
  return (
    <div className="bot-message-wrapper">
      <div className="bot-icon-wrapper">
        <img
          src="/images/talk-bubble.png"
          alt="chat bubble"
          className="bot-icon"
        />
      </div>
      <div className="pf-v5-u-display-flex pf-u-align-items-center typing">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    </div>
  );
};

export default LoadingMessage;
