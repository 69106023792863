import apiClient from "../../../apis/api-client";
import {
  DINNER_PLAN_API_BASE_URL,
  DINNER_RECIPE_BASE_URL,
  dinnerPlanApiEndpoints,
} from "../../../apis/endpoints";

interface RequestProps {
  url?: string;
  dataToSend?: any;
  successCallback?: (data) => void;
  failureCallback?: () => void;
  params?: any;
}

const createDinner = async (props: RequestProps) => {
  const { dataToSend, successCallback, failureCallback, params } = props;
  const response = await apiClient(DINNER_PLAN_API_BASE_URL).post(
    dinnerPlanApiEndpoints.dinnerPlan,
    dataToSend,
    { params }
  );
  if (response.status === 201) {
    successCallback && successCallback(response.data);
  } else {
    failureCallback && failureCallback();
  }
};

const getDinnerRecipes = async (props: RequestProps) => {
  const { successCallback, failureCallback, params } = props;
  const response = await apiClient(DINNER_RECIPE_BASE_URL).get("/search", {
    params,
  });
  if (response.status === 200) {
    successCallback && successCallback(response.data);
  } else {
    failureCallback && failureCallback();
  }
};

export { createDinner, getDinnerRecipes };
