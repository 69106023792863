import utilsApiClient from "./api-client";

interface RequestProps {
  url?: string;
  data?: any;
  params?: any;
  successCallback?: (response?) => void;
  failureCallback?: (response) => void;
}

const authorize = async (props: RequestProps) => {
  const { url, successCallback, failureCallback } = props;
  const response = await utilsApiClient.get(url);
  if (response.status === 200) {
    successCallback && successCallback(response.data);
  } else {
    failureCallback && failureCallback(response);
  }
};

const getNotifications = async (props: RequestProps) => {
  const { url, successCallback, failureCallback } = props;
  const response = await utilsApiClient.get(url);
  if (response.status === 200) {
    successCallback && successCallback(response.data);
  } else {
    failureCallback && failureCallback(response);
  }
};

const readNotification = async (props: RequestProps) => {
  const { url, data, successCallback, failureCallback } = props;
  const response = await utilsApiClient.patch(url, data);

  if (response.status === 200) {
    successCallback && successCallback(response);
  } else {
    failureCallback && failureCallback(response);
  }
};

export { authorize, getNotifications, readNotification };
