import apiClient from "../../../apis/api-client";
import {
  MANAGES_SERVICES_API_BASE_URL,
  managedServicesEndpoints,
} from "../../../apis/endpoints";

interface RequestProps {
  dataToSend: any;
  successCallback?: (data) => void;
  failureCallback?: () => void;
  params?: any;
}

const createManagedServices = async (props: RequestProps) => {
  const { dataToSend, successCallback, failureCallback, params } = props;
  const response = await apiClient(MANAGES_SERVICES_API_BASE_URL).post(
    managedServicesEndpoints.managedServices,
    dataToSend
  );
  if (response.status === 201) {
    successCallback && successCallback(response.data);
  } else {
    failureCallback && failureCallback();
  }
};

export { createManagedServices };
