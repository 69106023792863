import { Bullseye, Title, Spinner } from "@patternfly/react-core";
import "./style.scss";

const Loader = () => {
  return (
    <div className="rhsc-assistant-loader" style={{ height: "40vh" }}>
      <Bullseye>
        <Title headingLevel="h5">
          <Spinner size="sm" />
          &emsp; Loading . . .
        </Title>
      </Bullseye>
    </div>
  );
};

export default Loader;
