import Handshake_Icon from "../assets/Icon-Red_Hat-Handshake-A-Red-RGB.svg";
import Paper_Icon from "../assets/Icon-Red_Hat-Paper_stack_lined-A-Red-RGB.svg";
import Dinner_Icon from "../assets/Icon-Red_Hat-Covered_dish-A-Red-RGB.svg";

export interface ProcessesMetaInterface {
  code: string;
  name: string;
  isUnderMaintenance: boolean;
  description: string;
  icon: string;
  opportunityObject: string;
  roleSuffix: string;
}

export const PROCESSES_META: ProcessesMetaInterface[] = [
  {
    code: "pcf",
    name: "Project Change Form Generator",
    isUnderMaintenance: false,
    description:
      "To extend/change the term or billing of the original Order Form or SBOFs in order to allow customer to complete the SKUs",
    icon: Paper_Icon,
    opportunityObject: "pca",
    roleSuffix: "pa-pca-",
  },
  {
    code: "evs",
    name: "Ecosystem Value Sell-With",
    isUnderMaintenance: false,
    description:
      "Please fill out this form to seek approval of sell-with activities by a non-transactional partner.",
    icon: Handshake_Icon,
    opportunityObject: "evs",
    roleSuffix: "pa-evs-",
  },
  {
    code: "dinnerPlan",
    name: "Dinner",
    isUnderMaintenance: false,
    description:
      "Dinner Plan process to demonstrate all Process Accelerator functionalities",
    icon: Dinner_Icon,
    opportunityObject: "dinner",
    roleSuffix: "pa-dinner-",
  },
  {
    code: "managedservices",
    name: "Managed Service Onboarding and Readiness",
    isUnderMaintenance: false,
    description: "Process for Managed Service Onboarding and Readiness",
    icon: Handshake_Icon,
    opportunityObject: "managedServiceRequest",
    roleSuffix: "pa-managedservices-",
  },
];
