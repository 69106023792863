const VALIDATION = {
  email: (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  },
  length: (value: string, min: number, max: number) => {
    return value.length >= min && value.length <= max;
  },
  isValidQuipURL: (url: string) => {
    const quipUrlRegex = new RegExp(
      "https://redhat.quip.com/[a-zA-Z0-9-@:%._\\/+~#=]+"
    );

    return quipUrlRegex.test(url);
  },
};
export default VALIDATION;
