import {
  Badge,
  Button,
  Icon,
  InputGroup,
  MenuToggle,
  MenuToggleElement,
  PageSection,
  PageSectionVariants,
  Select,
  SelectList,
  SelectOption,
  TextInput,
  Toolbar,
  ToolbarContent,
  ToolbarFilter,
  ToolbarGroup,
  ToolbarItem,
  ToolbarToggleGroup,
} from "@patternfly/react-core";
import "./processListHeader.scss";
import { FilterIcon, PlusIcon } from "@patternfly/react-icons";
import { useState } from "react";
import { PROCESS_FILTER_CATEGORY, STATUS } from "../../../../constants/Process";
import { useHistory } from "react-router-dom";
import { getProcessType } from "../../../../utils";

export const ProcessListHeader = ({ filters, setFilters }) => {
  const currentProcessType = getProcessType();
  const [searchInput, setSearchInput] = useState<string>("");
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>([
    ...filters.status,
  ]);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const history = useHistory();

  const onSelect = (
    _event: React.MouseEvent<Element, MouseEvent> | undefined,
    value: string | undefined
  ): void => {
    if (selectedStatuses.includes(value)) {
      const newProcessStates = [...selectedStatuses].filter(
        (state) => state !== value
      );
      setSelectedStatuses(newProcessStates);
    } else {
      setSelectedStatuses([...selectedStatuses, value]);
    }
  };

  const onDeleteChip = (
    categoryName: PROCESS_FILTER_CATEGORY,
    value: string
  ): void => {
    switch (categoryName) {
      case PROCESS_FILTER_CATEGORY.STATUS:
        const newSelectedStatues = selectedStatuses.filter(
          (item) => item !== value
        );
        setSelectedStatuses([...newSelectedStatues]);
        setFilters({ ...filters, status: newSelectedStatues });
        break;
      case PROCESS_FILTER_CATEGORY.SEARCH_KEY:
        const newSearchKey = filters.searchKey.filter((item) => item !== value);
        setFilters({ ...filters, searchKey: newSearchKey });
        break;
    }
  };

  const onApplyFilter = (): void => {
    setSearchInput("");
    const clonedBusinessKeyArray = [...filters.searchKey];
    if (searchInput && !clonedBusinessKeyArray.includes(searchInput)) {
      clonedBusinessKeyArray.push(searchInput);
    }
    setFilters({
      ...filters,
      searchKey: clonedBusinessKeyArray,
      status: selectedStatuses,
    });
  };

  const resetAllFilters = (): void => {
    setSearchInput("");
    setSelectedStatuses([STATUS.ACTIVE]);

    setFilters({
      searchKey: "",
      status: [STATUS.ACTIVE],
    });
  };

  const onEnterClicked = (event: React.KeyboardEvent<EventTarget>): void => {
    if (event.key === "Enter") {
      searchInput.length > 0 && onApplyFilter();
    }
  };

  const onToggleClick = () => {
    setIsExpanded(!isExpanded);
  };

  const toggle = (toggleRef: React.Ref<MenuToggleElement>) => (
    <MenuToggle
      ref={toggleRef}
      onClick={onToggleClick}
      isExpanded={isExpanded}
      style={
        {
          width: "200px",
        } as React.CSSProperties
      }
    >
      Status
      {selectedStatuses.length > 0 && (
        <Badge isRead>{selectedStatuses.length}</Badge>
      )}
    </MenuToggle>
  );

  return (
    <PageSection variant={PageSectionVariants.light}>
      <Toolbar
        style={{ backgroundColor: "white" }}
        collapseListedFiltersBreakpoint="xl"
        clearAllFilters={resetAllFilters}
        clearFiltersButtonText="Reset to default"
        className="process-header"
      >
        <ToolbarContent>
          <ToolbarToggleGroup toggleIcon={<FilterIcon />} breakpoint="xl">
            <ToolbarGroup variant="filter-group">
              <ToolbarFilter
                chips={filters.status}
                deleteChip={onDeleteChip}
                className="pf-u-mr-sm"
                categoryName={PROCESS_FILTER_CATEGORY.STATUS}
                id="datatoolbar-filter-status"
              >
                <Select
                  id="status-select"
                  aria-label="Status"
                  onSelect={onSelect}
                  selected={selectedStatuses}
                  isOpen={isExpanded}
                  toggle={toggle}
                >
                  <SelectList>
                    <SelectOption
                      hasCheckbox
                      value="ACTIVE"
                      isSelected={selectedStatuses.includes("ACTIVE")}
                    >
                      ACTIVE
                    </SelectOption>
                    <SelectOption
                      hasCheckbox
                      value="COMPLETED"
                      isSelected={selectedStatuses.includes("COMPLETED")}
                    >
                      COMPLETED
                    </SelectOption>
                    <SelectOption
                      hasCheckbox
                      value="ERROR"
                      isSelected={selectedStatuses.includes("ERROR")}
                    >
                      ERROR
                    </SelectOption>
                    <SelectOption
                      hasCheckbox
                      value="ABORTED"
                      isSelected={selectedStatuses.includes("ABORTED")}
                    >
                      ABORTED
                    </SelectOption>
                    <SelectOption
                      hasCheckbox
                      value="SUSPENDED"
                      isSelected={selectedStatuses.includes("SUSPENDED")}
                    >
                      SUSPENDED
                    </SelectOption>
                  </SelectList>
                </Select>
              </ToolbarFilter>
              <ToolbarFilter
                chips={filters.searchKey}
                deleteChip={onDeleteChip}
                categoryName={PROCESS_FILTER_CATEGORY.SEARCH_KEY}
              >
                <InputGroup>
                  <TextInput
                    name="search"
                    id="search"
                    type="search"
                    aria-label="Business key"
                    onChange={(event, value) => setSearchInput(value)}
                    onKeyPress={onEnterClicked}
                    placeholder="Filter by business key"
                    value={searchInput}
                  />
                </InputGroup>
              </ToolbarFilter>
              <ToolbarItem>
                <Button
                  variant="primary"
                  onClick={onApplyFilter}
                  id="apply-filter-button"
                >
                  Apply filter
                </Button>
              </ToolbarItem>
            </ToolbarGroup>
          </ToolbarToggleGroup>
          <Button
            type="button"
            icon={
              <Icon isInline>
                <PlusIcon style={{ marginRight: 10 }} />
              </Icon>
            }
            onClick={() =>
              history.push("/create-process/" + currentProcessType)
            }
          >
            Start New Process
          </Button>
        </ToolbarContent>
      </Toolbar>
    </PageSection>
  );
};
