import { EmptyState, EmptyStateIcon, Title } from "@patternfly/react-core";
import { SearchIcon } from "@patternfly/react-icons";

export const NoData = () => {
  return (
    <EmptyState>
      <EmptyStateIcon icon={SearchIcon} />
      <Title headingLevel="h5" size="lg">
        No results found
      </Title>
    </EmptyState>
  );
};
