import { ApolloClient, InMemoryCache } from "@apollo/client";
import { apiBaseUrl } from "../apis/endpoints";

export const getClient = () => {
  return new ApolloClient({
    uri: `${apiBaseUrl}/graphql`,
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: "network-only",
      },
      watchQuery: { fetchPolicy: "no-cache" },
    },
  });
};

export default new ApolloClient({
  uri: `${apiBaseUrl}/graphql`,
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: "network-only",
    },
    watchQuery: { fetchPolicy: "no-cache" },
  },
});
