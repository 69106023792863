import {
  FormGroup,
  HelperText,
  HelperTextItem,
  TextInput,
} from "@patternfly/react-core";
import "./inputs.scss";
import { FormEvent, useState } from "react";

interface TextInputProps {
  value: string;
  setValue?: (value: string) => void;
  isRequired?: boolean;
  type?: any;
  isDisabled?: boolean;
  label?: string;
  style?: React.CSSProperties;
  placeholder?: string;
  inputStyle?: React.CSSProperties;
  id: string;
  maxLength?: number;
  validation?: (value: string) => boolean;
  helperText?: string;
  onBlur?: (e: React.FormEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: React.FormEvent<HTMLInputElement>) => void;
}
const BasicTextInput: React.FC<TextInputProps> = (props) => {
  const [validated, setValidated] = useState<
    "default" | "success" | "warning" | "error" | undefined
  >("default");

  return (
    <FormGroup
      label={props.label}
      isRequired={props.isRequired || false}
      id={props.id}
      style={{ ...props.style }}
    >
      <TextInput
        type={props.type || "text"}
        maxLength={props.maxLength}
        id={props.id}
        autoComplete="off"
        isDisabled={props.isDisabled || false}
        isRequired={props.isRequired || false}
        style={{ outline: "none", ...props.inputStyle }}
        placeholder={props.placeholder}
        value={props.value}
        onBlur={props.onBlur}
        onChange={(event: FormEvent<HTMLInputElement>, value: string) => {
          props.setValue(value);
          if (props.validation) {
            if (props.validation(value)) {
              setValidated("success");
            } else {
              setValidated("error");
            }
          }
        }}
        validated={validated}
        onKeyUp={props.onKeyUp}
      />
      {props.helperText && (
        <HelperText>
          <HelperTextItem>{props.helperText}</HelperTextItem>
        </HelperText>
      )}
    </FormGroup>
  );
};

export default BasicTextInput;
