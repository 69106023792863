export enum ENV {
  PROD = "PROD",
  STAGE = "STAGE",
  QA = "QA",
  DEV = "DEV",
}

export type I_HOSTNAME = {
  [key in ENV]: string[];
};

export const HOSTNAMES: I_HOSTNAME = {
  [ENV.PROD]: ["accelerate.io.redhat.com"],
  [ENV.STAGE]: ["accelerate.preprod.io.redhat.com"],
  [ENV.QA]: ["accelerate.preprod.io.redhat.com"],
  [ENV.DEV]: ["localhost"],
};
