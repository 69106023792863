export interface User {
  createdAt: string;
  email: string;
  emailNotificationEnabled: boolean;
  gchatNotificationEnabled: boolean;
  id: string;
  lastUsedProcess: string;
  name: string;
  slackNotificationEnabled: boolean;
  updatedAt: string;
}

export const INITIAL_USER = {
  id: "",
  name: "",
  email: "",
  lastUsedProcess: "pca",
  emailNotificationEnabled: false,
  slackNotificationEnabled: false,
  createdAt: "",
  updatedAt: "",
  gchatNotificationEnabled: false,
};
