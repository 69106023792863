import { Button } from "@patternfly/react-core";
import "./style.scss";
import { useHistory } from "react-router-dom";

function TaskListItem({ task }) {
  const taskInput = JSON.parse(task?.inputs).accountPlanReview;
  const history = useHistory();

  const handleReviewButtonClick = () => {
    history.push(
      `/account-planning/${task.processInstanceId}?task=${task.name}`
    );
  };
  return (
    <div className="task-item">
      <div>
        <div className="task-item-row">
          <span className="task-item-key">Account Name: </span>
          {taskInput.accountPlanName}
        </div>
        <div className="task-item-row">
          <span className="task-item-key">Requestor: </span>
          {taskInput.createdBy}
        </div>
        <div className="task-item-row">
          <span className="task-item-key">Created At: </span>
          {new Date(task.started).toDateString()}
        </div>
      </div>
      <Button onClick={handleReviewButtonClick}>{task.referenceName}</Button>
    </div>
  );
}

export default TaskListItem;
