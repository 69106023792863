import { createHttpLink, useQuery } from "@apollo/client";
import {
  Tabs,
  Tab,
  TabContent,
  TabContentBody,
  TabTitleText,
  CardBody,
  CardHeader,
  FlexItem,
  Form,
  FormGroup,
  Text,
  TextVariants,
  Title,
  PageSection,
  PageSectionVariants,
  Button,
  Flex,
  TextContent,
  Grid,
  GridItem,
} from "@patternfly/react-core";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Loader } from "../../../components/Loader/Loader";
import { ProcessDetail, TASK_DETAILS_FIELDS } from "../../../constants/Process";
import { GET_TASK } from "../../../graphql/queries/Task";
import { AlertContext } from "../../../contexts/alert/AlertContext";
import { STATUS, TASK_CONTROL_OBJECT } from "../../../constants/Task";
import { changeTaskState } from "./apis";
import { getAuthContext } from "../../../graphql/auth";
import Comments from "../Comments";
import { ChevronLeftIcon } from "@patternfly/react-icons";
import {
  renderOpportunityDetails,
  renderOutputDetails,
  renderProcessInputs,
} from "../TabContents";
import { getCurrentProcessMeta } from "../../../utils";
import {
  evsApiEndpoints,
  pcaApiEndpoints,
  getBaseURL,
  dinnerPlanApiEndpoints,
  managedServicesEndpoints,
} from "../../../apis/endpoints";
import client from "../../../graphql/client";
import { GlobalStateContext } from "../../../store/GlobalContextProvider";
import ExtendedTask from "./Extended";
import { useAuthContext } from "../../../contexts/authentication/AuthenticationContext";

const Detail = (props: { detailMeta: ProcessDetail }) => {
  const {
    globalState: { currentProcess },
  } = useContext(GlobalStateContext);
  const baseURL = getBaseURL(currentProcess);
  baseURL && client.setLink(createHttpLink({ uri: `${baseURL}/graphql` }));
  const { id } = useParams<{ id: string }>();
  const {
    detailMeta: { opportunityFields, extendedTasks },
  } = props;
  const { opportunityObject, code, roleSuffix } = getCurrentProcessMeta();
  const { user } = useAuthContext();
  const history = useHistory();
  const alert = useContext(AlertContext);
  const [taskData, setTaskData] = useState(null);
  const { loading, error, data, refetch } = useQuery(GET_TASK, {
    fetchPolicy: "network-only",
    context: getAuthContext(),
    variables: {
      where: { id: { equal: id } },
    },
    client: client,
  });

  const [activeTabKey, setActiveTabKey] = useState(0);

  const [taskControlFields, setTaskControlFields] = useState([]);
  const [taskControlValues, setTaskControlValues] = useState({});
  const [isActionButtonDisabled, setIsActionButtonDisabled] =
    useState<boolean>(false);

  const handleTabClick = (event, tabIndex) => {
    setActiveTabKey(tabIndex);
  };

  useEffect(() => {
    if (data) {
      setTaskData(data.UserTaskInstances[0]);
    }
  }, [data]);

  useEffect(() => {
    if (taskData?.name && extendedTasks.includes(taskData.name)) {
      setTaskControlFields(TASK_CONTROL_OBJECT[taskData?.name]?.fields);
      setTaskControlValues(TASK_CONTROL_OBJECT[taskData?.name]?.values);
    }
  }, [taskData]);

  const getTaskActionParams = () => {
    let params = new URLSearchParams();
    window?.sessionjs?.realmAccess?.roles.map((role) => {
      if (role.startsWith(roleSuffix)) params.append("group", role);
    });
    params.append("user", user?.email);

    return params;
  };

  const getTaskActionEndpoint = (process: string): string => {
    switch (process) {
      case "evs":
        return getBaseURL(process) + evsApiEndpoints.evs;
      case "pcf":
        return getBaseURL(process) + pcaApiEndpoints.pca;
      case "managedservices":
        return getBaseURL(process) + managedServicesEndpoints.managedServices;
      default:
        return getBaseURL(process) + dinnerPlanApiEndpoints.dinnerPlan;
    }
  };

  const handleOnClickTaskAction = (action: string): void => {
    setIsActionButtonDisabled(true);
    changeTaskState({
      url: `${getTaskActionEndpoint(code)}/${taskData.processInstanceId}/${
        taskData.name
      }/${taskData.id}/phases/${action}`,
      params: getTaskActionParams(),
      successCallback: () => {
        alert.addAlert(`Task has been ${action}ed.`, "success");
        refetch();
        setIsActionButtonDisabled(false);
      },
      failureCallback: (res) => {
        alert.addAlert(
          res.response.data.message || "An error occurred",
          "danger"
        );
        setIsActionButtonDisabled(false);
      },
    });
  };

  const handleTaskComplete = (payload: any): void => {
    changeTaskState({
      url: `${getTaskActionEndpoint(code)}/${taskData.processInstanceId}/${
        taskData.name
      }/${taskData.id}/phases/complete`,
      data: payload,
      params: getTaskActionParams(),
      successCallback: () => {
        alert.addAlert(
          `Task has been ${payload.approved ? "approved" : "rejected"}.`,
          "success"
        );
        history.push(`/tasks/${code}/`);
      },
      failureCallback: (res) => {
        alert.addAlert(
          res.response.data.message ||
            res.response.data.details ||
            "An error occurred",
          "danger"
        );
        setIsActionButtonDisabled(false);
      },
    });
  };

  const handleOnClickApproveOrReject = (isApproved: boolean): void => {
    setIsActionButtonDisabled(true);
    let payload: any = {
      approved: isApproved,
    };
    if (extendedTasks.includes(taskData.name)) {
      if (taskData.name === "productnamecheck") {
        if (taskControlValues["productAdded"]) {
          if (
            taskControlValues["productName"].length &&
            taskControlValues["jiraRequired"].length
          ) {
            payload = {
              ...payload,
              productAdded: true,
              productName: taskControlValues["productName"],
              cpccmRequired:
                taskControlValues["jiraRequired"].includes("cpccmRequired"),
              cppxRequired:
                taskControlValues["jiraRequired"].includes("cppxRequired"),
            };

            handleTaskComplete(payload);
          } else {
            alert.addAlert("Please fill all the mandatory fields", "danger");
            setIsActionButtonDisabled(false);
          }
        } else {
          payload = {
            ...payload,
            productAdded: false,
            productName: null,
            cpccmRequired: false,
            cppxRequired: false,
          };
          handleTaskComplete(payload);
        }
      } else {
        payload = {
          ...payload,
          ...taskControlValues,
        };
        if (
          Object.keys(taskControlValues).filter((key) => taskControlValues[key])
            .length > 0
        ) {
          handleTaskComplete(payload);
        } else {
          alert.addAlert("Please fill all the mandatory fields", "danger");
          setIsActionButtonDisabled(false);
        }
      }
    } else {
      handleTaskComplete(payload);
    }
  };

  const renderTaskDetails = (): JSX.Element => {
    return (
      <FlexItem>
        <CardHeader>
          <Title headingLevel="h3" size="xl">
            Details
          </Title>
        </CardHeader>
        <CardBody>
          <Form>
            {taskData &&
              TASK_DETAILS_FIELDS.map((field) => (
                <FormGroup
                  label={field.label}
                  fieldId={field.id}
                  key={field.id}
                >
                  <Text component={TextVariants.p}>
                    {!field.isNativeKey
                      ? JSON.parse(taskData?.inputs)?.[opportunityObject][
                          field.id
                        ]
                      : field.type === "text"
                      ? taskData?.[field.id] || "N/A"
                      : moment(taskData?.[field.id]).fromNow() || "N/A"}
                  </Text>
                </FormGroup>
              ))}
          </Form>
        </CardBody>
      </FlexItem>
    );
  };

  return (
    <>
      {!loading && taskData ? (
        <>
          <PageSection variant={PageSectionVariants.light}>
            <Grid>
              <GridItem
                span={6}
                style={{ display: "flex", alignItems: "center" }}
              >
                <button
                  type="button"
                  className="back-to-list-button"
                  onClick={() => history.push(`/tasks/${code}/`)}
                >
                  <ChevronLeftIcon height="14px" />
                </button>
                <TextContent>
                  <Text component="h1">{taskData?.referenceName}</Text>
                </TextContent>
              </GridItem>
              <GridItem span={6}>
                <Flex>
                  {taskData?.state === STATUS.READY && (
                    <FlexItem align={{ default: "alignRight" }}>
                      <Button
                        type="button"
                        onClick={() => handleOnClickTaskAction("claim")}
                        isDisabled={isActionButtonDisabled}
                      >
                        Claim
                      </Button>
                    </FlexItem>
                  )}
                  {taskData?.state === STATUS.RESERVED &&
                    taskData?.actualOwner === user?.email && (
                      <>
                        <FlexItem align={{ default: "alignRight" }}>
                          <Button
                            type="button"
                            onClick={() => handleOnClickApproveOrReject(true)}
                            isDisabled={isActionButtonDisabled}
                          >
                            Approve
                          </Button>
                        </FlexItem>
                        <FlexItem>
                          <Button
                            type="button"
                            onClick={() => handleOnClickApproveOrReject(false)}
                            isDisabled={isActionButtonDisabled}
                          >
                            Reject
                          </Button>
                        </FlexItem>
                        <FlexItem>
                          <Button
                            type="button"
                            onClick={() => handleOnClickTaskAction("release")}
                            isDisabled={isActionButtonDisabled}
                          >
                            Release
                          </Button>
                        </FlexItem>
                      </>
                    )}
                </Flex>
              </GridItem>
            </Grid>
          </PageSection>
          <div style={{ margin: 20 }}>
            <Flex alignItems={{ default: "alignItemsFlexStart" }}>
              <FlexItem flex={{ default: "flex_4" }}>
                <PageSection
                  type="tabs"
                  variant={PageSectionVariants.light}
                  isWidthLimited
                >
                  <Tabs activeKey={activeTabKey} onSelect={handleTabClick}>
                    <Tab
                      eventKey={0}
                      title={<TabTitleText>Inputs</TabTitleText>}
                      tabContentId={"tabContent0"}
                    ></Tab>
                    <Tab
                      eventKey={1}
                      title={<TabTitleText>Outputs</TabTitleText>}
                      tabContentId={"tabContent1"}
                    ></Tab>
                    <Tab
                      eventKey={2}
                      title={<TabTitleText>Enriched Data</TabTitleText>}
                      tabContentId={"tabContent2"}
                    ></Tab>
                    <Tab
                      eventKey={3}
                      title={<TabTitleText>Details</TabTitleText>}
                      tabContentId={"tabContent3"}
                    ></Tab>
                  </Tabs>
                </PageSection>
                <PageSection
                  variant={PageSectionVariants.light}
                  isWidthLimited
                  className="task-details-section"
                >
                  <TabContent
                    key={0}
                    eventKey={0}
                    id={"tabContent0"}
                    activeKey={activeTabKey}
                    hidden={0 !== activeTabKey}
                  >
                    <TabContentBody>
                      {renderProcessInputs(
                        JSON.parse(taskData?.inputs)[opportunityObject],
                        code
                      )}
                    </TabContentBody>
                  </TabContent>
                  <TabContent
                    key={1}
                    eventKey={1}
                    id={"tabContent1"}
                    activeKey={activeTabKey}
                    hidden={1 !== activeTabKey}
                  >
                    <TabContentBody>
                      {renderOutputDetails(
                        JSON.parse(taskData?.inputs)?.[opportunityObject],
                        code
                      )}
                    </TabContentBody>
                  </TabContent>
                  <TabContent
                    key={2}
                    eventKey={2}
                    id={"tabContent2"}
                    activeKey={activeTabKey}
                    hidden={2 !== activeTabKey}
                  >
                    <TabContentBody>
                      {renderOpportunityDetails(
                        opportunityFields,
                        JSON.parse(taskData?.inputs)?.[opportunityObject]
                          .opportunity
                      )}
                    </TabContentBody>
                  </TabContent>
                  <TabContent
                    key={3}
                    eventKey={3}
                    id={"tabContent3"}
                    activeKey={activeTabKey}
                    hidden={3 !== activeTabKey}
                  >
                    <TabContentBody>{renderTaskDetails()}</TabContentBody>
                  </TabContent>
                </PageSection>
              </FlexItem>
              {extendedTasks.includes(taskData?.name) &&
                taskData?.state === STATUS.RESERVED &&
                taskData?.actualOwner === user?.email && (
                  <ExtendedTask
                    taskControlFields={taskControlFields}
                    taskControlValues={taskControlValues}
                    setTaskControlValues={setTaskControlValues}
                  />
                )}
            </Flex>
            {taskData && <Comments processId={taskData.processInstanceId} />}
          </div>
        </>
      ) : (
        <Loader text="Loading task details" />
      )}
    </>
  );
};

export default Detail;
