import {
  Button,
  Grid,
  GridItem,
  Icon,
  Label,
  PageSection,
  PageSectionVariants,
  Skeleton,
  Text,
  TextContent,
} from "@patternfly/react-core";
import { Thead, Tbody, Tr, Th, Td, Table } from "@patternfly/react-table";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Filter, STATUS } from "../../../constants/Task";
import { isEmpty } from "lodash";
import { NoData } from "../../../utils/NoData";
import moment from "moment";
import { createHttpLink, useQuery } from "@apollo/client";
import { TaskListHeader } from "./TaskListHeader/TaskListHeader";
import { GET_TASKS_LIST } from "../../../graphql/queries/Task";
import { Spinner2Icon } from "@patternfly/react-icons";
import { getAuthContext } from "../../../graphql/auth";
import {
  PCA_TYPE_TO_LABEL_AND_FIELDS,
  ListMeta,
} from "../../../constants/Process";
import { getCurrentProcessMeta } from "../../../utils";
import { ProcessesMetaInterface } from "../../../constants/Processes";
import { getBaseURL } from "../../../apis/endpoints";
import client from "../../../graphql/client";
import { GlobalStateContext } from "../../../store/GlobalContextProvider";

const List = (props: { taskListMeta: ListMeta }) => {
  const {
    globalState: { currentProcess },
  } = useContext(GlobalStateContext);
  const baseURL = getBaseURL(currentProcess);
  baseURL && client.setLink(createHttpLink({ uri: `${baseURL}/graphql` }));
  const {
    taskListMeta: { columns, defaultSortIndex, processId, opportunityObject },
  } = props;
  const currentProcessMeta: ProcessesMetaInterface = getCurrentProcessMeta();
  const [activeSortIndex, setActiveSortIndex] = useState(defaultSortIndex);
  const [activeSortDirection, setActiveSortDirection] = useState("desc");
  const [tasksList, setTasksList] = useState([]);
  const [filters, setFilters] = useState<Filter>({
    searchKey: [],
    status: [STATUS.READY, STATUS.RESERVED],
  });
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const offSet = 0;
  const limit = 10;
  const [currentPageSize, setCurrentPageSize] = useState<number>(limit);

  const formatSearchWords = (searchWords: string[]) => {
    const tempSearchWordsArray = [];
    searchWords.forEach((word) => {
      tempSearchWordsArray.push({ referenceName: { like: word } });
    });
    return tempSearchWordsArray;
  };

  interface OrCondition {
    or?: {
      potentialGroups?: { containsAny: string[] };
      adminGroups?: { containsAny: string[] };
      [key: string]: any;
    };
    [key: string]: any;
  }

  const buildTaskListWhereArgument = (filters: any) => {
    const rolesFromToken = window?.sessionjs?.realmAccess?.roles.filter((role) =>
      role.startsWith(currentProcessMeta.roleSuffix)
    );

    const basePayload = {
      state: { in: filters.status },
      processId: { equal: processId },
    };

    if (filters.searchKey.length === 0 && rolesFromToken.length) {
      const orCondition: OrCondition = {
        or: {
          potentialGroups: { containsAny: rolesFromToken },
          adminGroups: { containsAny: rolesFromToken },
        },
      };

      return { ...basePayload, ...orCondition };
    }

    if (filters.searchKey.length > 0) {
      const orCondition: OrCondition = {
        ...formatSearchWords(filters.searchKey),
      };

      if (rolesFromToken.length) {
        orCondition.potentialGroups = { containsAny: rolesFromToken };
        orCondition.adminGroups = { containsAny: rolesFromToken };
      }

      return { ...basePayload, ...orCondition };
    }

    return basePayload;
  };

  const buildTaskListOrderbyArgument = (key: string, direction: string) => {
    const orderBy = {};
    orderBy[key] = direction;
    return orderBy;
  };

  const { loading, error, data } = useQuery(GET_TASKS_LIST, {
    fetchPolicy: "network-only",
    context: getAuthContext(),
    variables: {
      where: buildTaskListWhereArgument(filters),
      offset: offSet,
      limit: currentPageSize,
      orderBy: buildTaskListOrderbyArgument(
        columns[activeSortIndex].code,
        activeSortDirection.toUpperCase()
      ),
    },
    client: client,
  });

  useEffect(() => {
    if (data) setTasksList(data?.UserTaskInstances);
    setIsLoadingMore(false);
  }, [data]);

  const mustShowLoadMore =
    (!loading || isLoadingMore) &&
    tasksList.length > 0 &&
    tasksList.length >= currentPageSize &&
    filters.status.length > 0;

  const getSkeleton = () => {
    const noOfSkeletons = new Array(4).fill(0);
    return noOfSkeletons.map((item, index) => {
      return (
        <Tr key={index}>
          <Td colSpan={columns.length}>
            <Skeleton height="2rem" />
          </Td>
        </Tr>
      );
    });
  };

  const getLabelByStatus = (status) => {
    if (status === STATUS.READY) {
      return <Label color="purple">{status}</Label>;
    } else if (status === STATUS.COMPLETED) {
      return <Label color="green">{status}</Label>;
    } else {
      return status ? <Label color="grey">{status}</Label> : "N/A";
    }
  };

  const onSort = (event, index: number, direction) => {
    setActiveSortIndex(index);
    setActiveSortDirection(direction);
  };

  const onLoadMoreClick = () => {
    setIsLoadingMore(true);
    setCurrentPageSize(currentPageSize + limit);
  };

  return (
    <>
      <PageSection variant={PageSectionVariants.light}>
        <Grid>
          <GridItem span={8}>
            <TextContent>
              <Text component="h1">Task Inbox</Text>
            </TextContent>
          </GridItem>
        </Grid>
      </PageSection>
      <div style={{ margin: 20 }}>
        <TaskListHeader filters={filters} setFilters={setFilters} />
        <Table aria-label="Sortable Table" variant="compact">
          <Thead>
            <Tr>
              {columns.map((column, columnIndex) => {
                const sortParams: any = column.isSortable
                  ? {
                      sort: {
                        sortBy: {
                          index: activeSortIndex,
                          direction: activeSortDirection,
                        },
                        onSort,
                        columnIndex,
                      },
                    }
                  : {};

                return (
                  <Th key={columnIndex} {...sortParams}>
                    {column.label}
                  </Th>
                );
              })}
            </Tr>
          </Thead>

          {loading ? (
            <Tbody>{getSkeleton()}</Tbody>
          ) : (
            <Tbody>
              {isEmpty(tasksList) ? (
                <Tr>
                  <Td colSpan={columns.length}>{NoData()}</Td>
                </Tr>
              ) : (
                tasksList &&
                tasksList.map((task, rowIndex) => {
                  const inputOpportunityObject = JSON.parse(task?.inputs)?.[
                    opportunityObject
                  ];

                  return (
                    <Tr
                      key={rowIndex}
                      style={{
                        backgroundColor:
                          rowIndex % 2 === 1 ? "rgb(247, 247, 247)" : "white",
                      }}
                    >
                      <Td style={{ minWidth: "8rem" }}>
                        <Link
                          to={`/tasks/${currentProcessMeta.code}/` + task.id}
                          style={{
                            fontWeight: "bold",
                            fontSize: "1rem",
                            marginRight: "1rem",
                          }}
                        >
                          {task?.referenceName || task?.name || "N/A"}
                        </Link>
                      </Td>
                      <Td style={{ minWidth: "8rem" }}>
                        {currentProcessMeta.code === "managedservices"
                          ? inputOpportunityObject?.proposedProductName
                          : currentProcessMeta.code === "dinnerPlan"
                          ? inputOpportunityObject?.place
                          : currentProcessMeta.code === "evs"
                          ? inputOpportunityObject?.partnerName
                          : inputOpportunityObject?.opportunity?.accountName}
                      </Td>
                      {currentProcessMeta.code !== "managedservices" && (
                        <>
                          <Td style={{ minWidth: "10rem" }}>
                            {currentProcessMeta.code === "dinnerPlan"
                              ? inputOpportunityObject?.pitch
                              : currentProcessMeta.code === "evs"
                              ? inputOpportunityObject?.partnerType
                              : PCA_TYPE_TO_LABEL_AND_FIELDS[
                                  inputOpportunityObject
                                    ?.typeOfProjectChangeApproval
                                ]?.label}
                          </Td>
                          <Td style={{ minWidth: "10rem" }}>
                            {currentProcessMeta.code === "dinnerPlan"
                              ? inputOpportunityObject?.budget
                              : inputOpportunityObject?.opportunity
                                  ?.opportunityNumber}
                          </Td>
                        </>
                      )}
                      <Td style={{ minWidth: "10rem" }}>
                        {inputOpportunityObject?.createdBy}
                      </Td>
                      <Td style={{ minWidth: "8rem" }}>
                        {getLabelByStatus(task?.state)}
                      </Td>
                      <Td style={{ minWidth: "8rem" }}>
                        {moment(task?.started).fromNow() || "N/A"}
                      </Td>
                      <Td style={{ minWidth: "10rem" }}>
                        {moment(task?.lastUpdate).fromNow() || "N/A"}
                      </Td>
                    </Tr>
                  );
                })
              )}
            </Tbody>
          )}
        </Table>
        <div className="table-load-more-button">
          {mustShowLoadMore && (
            <Button
              icon={
                <Icon isInline>
                  <Spinner2Icon
                    style={{
                      transform: "scaleX(-1)",
                    }}
                  />
                </Icon>
              }
              variant="primary"
              type="button"
              onClick={() => onLoadMoreClick()}
            >
              Load More
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default List;
