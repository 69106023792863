import {
  Card,
  CardBody,
  CardTitle,
  Flex,
  FlexItem,
  PageSection,
  PageSectionVariants,
  Text,
  TextContent,
} from "@patternfly/react-core";
import "./processes.scss";
import { getProcessType, updateProcessType } from "../../utils";
import { useHistory } from "react-router-dom";
import {
  PROCESSES_META,
  ProcessesMetaInterface,
} from "../../constants/Processes";
import { PROCESS_ACCESS, ProcessAccessor } from "../../constants/ProcessAccess";
import { useContext, useEffect, useState } from "react";
import { GlobalDispatchContext } from "../../store/GlobalContextProvider";
import { getAccess } from "../PCF/CreateProcess/apis";

const Processes = () => {
  const currentProcess = getProcessType();
  const dispatch = useContext(GlobalDispatchContext);
  const history = useHistory();
  const [processAccessMap, setProcessAccessMap] = useState([]);
  const onProcessLoad = () => {
    PROCESS_ACCESS.forEach((paAccessor: ProcessAccessor) => {
      if (paAccessor.isLive)
        getAccess(
          {
            dataToSend: "",
            successCallback: (data) => {
              setProcessAccessMap((prevState) => {
                return [
                  ...prevState,
                  PROCESSES_META.find((processMeta) => {
                    return processMeta.code === paAccessor.code;
                  }),
                ];
              });
            },
            failureCallback: () => {},
          },
          paAccessor
        );
    });
  };

  useEffect(() => {
    onProcessLoad();
  }, []);

  const onProcessSelect = (selectedProcessType: string) => {
    if (currentProcess === selectedProcessType) {
      history.push(`/processes/${selectedProcessType}`);
      return;
    } else {
      const updatedProcess = updateProcessType(selectedProcessType);
      dispatch({
        type: "UPDATE_CURRENT_PROCESS",
        payload: updatedProcess,
      });
      history.push(`/processes/${selectedProcessType}`);
      return;
    }
  };

  return (
    <div style={{ margin: 20 }}>
      <PageSection variant={PageSectionVariants.light}>
        <TextContent style={{ textAlign: "center", paddingTop: "20px" }}>
          <Text component="h1">Welcome to Process Accelerator ⚡️</Text>
          <p style={{ marginTop: "0" }}>Select a process to proceed.</p>
        </TextContent>
        <div className="processes-list">
          <Flex justifyContent={{ default: "justifyContentSpaceBetween" }}>
            <FlexItem flex={{ default: "flex_2" }}></FlexItem>
            {processAccessMap.map(
              (processMeta: ProcessesMetaInterface | undefined, index) => (
                <FlexItem flex={{ default: "flex_2" }} key={index}>
                  <Card
                    onClick={() => onProcessSelect(processMeta?.code)}
                    isRounded={true}
                    isSelectableRaised
                    isSelected={currentProcess === processMeta?.code}
                    hasSelectableInput={currentProcess !== processMeta?.code}
                  >
                    <CardTitle>
                      <img
                        src={processMeta?.icon}
                        className="process-icon"
                        alt={`${processMeta?.name} icon`}
                      />
                      <br />
                      {processMeta?.name}
                    </CardTitle>
                    <CardBody>{processMeta?.description}</CardBody>
                  </Card>
                </FlexItem>
              )
            )}
            <FlexItem flex={{ default: "flex_2" }}></FlexItem>
          </Flex>
        </div>
      </PageSection>
    </div>
  );
};

export default Processes;
