import {
  Button,
  Grid,
  GridItem,
  Icon,
  Label,
  PageSection,
  PageSectionVariants,
  Skeleton,
  Text,
  TextContent,
} from "@patternfly/react-core";
import { Thead, Tbody, Tr, Th, Td, Table } from "@patternfly/react-table";
import { useContext, useEffect, useState } from "react";
import "./processList.scss";
import { Link } from "react-router-dom";
import {
  Filter,
  PCA_TYPE_TO_LABEL_AND_FIELDS,
  ListMeta,
  STATUS,
} from "../../../constants/Process";
import { isEmpty } from "lodash";
import { NoData } from "../../../utils/NoData";
import moment from "moment";
import { createHttpLink, useQuery } from "@apollo/client";
import { ProcessListHeader } from "./ProcessListHeader/ProcessListHeader";
import { GET_PROCESS_LIST } from "../../../graphql/queries/Process";
import { Spinner2Icon } from "@patternfly/react-icons";
import { getAuthContext } from "../../../graphql/auth";
import { ProcessesMetaInterface } from "../../../constants/Processes";
import { getCurrentProcessMeta } from "../../../utils";
import client from "../../../graphql/client";
import { getBaseURL } from "../../../apis/endpoints";
import { GlobalStateContext } from "../../../store/GlobalContextProvider";
import { useAuthContext } from "../../../contexts/authentication/AuthenticationContext";

const List = (props: { processListMeta: ListMeta }) => {
  const {
    globalState: { currentProcess },
  } = useContext(GlobalStateContext);
  const baseURL = getBaseURL(currentProcess);
  baseURL && client.setLink(createHttpLink({ uri: `${baseURL}/graphql` }));
  const {
    processListMeta: {
      columns,
      defaultSortIndex,
      processId,
      opportunityObject,
    },
  } = props;
  const { user } = useAuthContext();
  const currentProcessMeta: ProcessesMetaInterface = getCurrentProcessMeta();
  const [activeSortIndex, setActiveSortIndex] = useState(defaultSortIndex);
  const [activeSortDirection, setActiveSortDirection] = useState("desc");
  const [processData, setProcessData] = useState([]);
  const [filters, setFilters] = useState<Filter>({
    searchKey: [],
    status: [STATUS.ACTIVE],
  });
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const limit = 10;
  const [currentPageSize, setCurrentPageSize] = useState<number>(limit);

  const formatSearchWords = (searchWords: string[]) => {
    const tempSearchWordsArray = [];
    searchWords.forEach((word) => {
      tempSearchWordsArray.push({ businessKey: { like: "*" + word + "*" } });
    });
    return tempSearchWordsArray;
  };

  const buildProcessListWhereArgument = (filters: any) => {
    if (filters.searchKey.length === 0) {
      return {
        state: { in: filters.status },
        processId: { equal: processId },
      };
    } else {
      return {
        state: { in: filters.status },
        or: formatSearchWords(filters.searchKey),
        processId: { equal: processId },
      };
    }
  };

  const buildProcessListOrderbyArgument = (key: string, direction: string) => {
    const orderBy = {};
    orderBy[key] = direction;
    return orderBy;
  };

  const { loading, error, data } = useQuery(GET_PROCESS_LIST, {
    fetchPolicy: "network-only",
    context: getAuthContext(),
    variables: {
      where: buildProcessListWhereArgument(filters),
      offset: 0,
      limit: 5000,
      orderBy: buildProcessListOrderbyArgument(
        columns[activeSortIndex].code,
        activeSortDirection.toUpperCase()
      ),
    },
    client: client,
  });

  useEffect(() => {
    if (data) {
      // TODO: by-passing process filtering as roles yet to come in place for evs
      if (
        currentProcessMeta.code === "evs" ||
        currentProcessMeta.code === "managedservices"
      ) {
        setProcessData(data?.ProcessInstances);
        return;
      }

      const isPcaViewerRole =
        window?.sessionjs?.realmAccess?.roles.includes("pa-pca-viewers");

      const processesByCurrentUser = data?.ProcessInstances.filter(
        (process) =>
          JSON.parse(process.variables)?.[opportunityObject]?.createdByEmail ===
            user?.email ||
          (isPcaViewerRole && process?.roles.includes("pa-pca-viewers"))
      );
      setProcessData(processesByCurrentUser);
    }
    setIsLoadingMore(false);
  }, [data]);

  const mustShowLoadMore =
    (!loading || isLoadingMore) &&
    processData.length > 0 &&
    processData.length > currentPageSize;

  const getSkeleton = () => {
    const noOfSkeletons = new Array(4).fill(0);
    return noOfSkeletons.map((item, index) => {
      return (
        <Tr key={index}>
          <Td colSpan={columns.length}>
            <Skeleton height="2rem" />
          </Td>
        </Tr>
      );
    });
  };

  const getLabelByStatus = (status) => {
    if (status === STATUS.ACTIVE) {
      return <Label color="purple">{status}</Label>;
    } else if (status === STATUS.COMPLETED) {
      return <Label color="green">{status}</Label>;
    } else {
      return status ? <Label color="grey">{status}</Label> : "N/A";
    }
  };

  const onSort = (event, index: number, direction) => {
    setActiveSortIndex(index);
    setActiveSortDirection(direction);
  };

  const onLoadMoreClick = () => {
    setIsLoadingMore(true);
    setCurrentPageSize(currentPageSize + limit);
  };

  return (
    <>
      <PageSection variant={PageSectionVariants.light}>
        <Grid>
          <GridItem span={8}>
            <TextContent>
              <Text component="h1">All Processes</Text>
            </TextContent>
          </GridItem>
        </Grid>
      </PageSection>
      <div style={{ margin: 20 }}>
        <ProcessListHeader filters={filters} setFilters={setFilters} />
        <Table aria-label="Sortable Table" variant="compact">
          <Thead>
            <Tr>
              {columns.map((column, columnIndex) => {
                const sortParams: any = column.isSortable
                  ? {
                      sort: {
                        sortBy: {
                          index: activeSortIndex,
                          direction: activeSortDirection,
                        },
                        onSort,
                        columnIndex,
                      },
                    }
                  : {};

                return (
                  <Th key={columnIndex} {...sortParams}>
                    {column.label}
                  </Th>
                );
              })}
            </Tr>
          </Thead>

          {loading ? (
            <Tbody>{getSkeleton()}</Tbody>
          ) : (
            <Tbody>
              {isEmpty(processData) ? (
                <Tr>
                  <Td colSpan={columns.length}>{NoData()}</Td>
                </Tr>
              ) : (
                processData &&
                processData
                  .slice(0, currentPageSize)
                  .map((process, rowIndex) => {
                    const variableOpportunityObject = JSON.parse(
                      process?.variables
                    )?.[opportunityObject];

                    return (
                      <Tr
                        key={rowIndex}
                        style={{
                          backgroundColor:
                            rowIndex % 2 === 1 ? "rgb(247, 247, 247)" : "white",
                        }}
                      >
                        <Td style={{ minWidth: "8rem" }}>
                          <Link
                            to={
                              `/processes/${currentProcessMeta.code}/` +
                              process.id
                            }
                            style={{
                              fontWeight: "bold",
                              fontSize: "1rem",
                              marginRight: "1rem",
                            }}
                          >
                            {process?.processName || "N/A"}
                          </Link>
                          {process?.businessKey && (
                            <Label isCompact={true} color="blue">
                              {process?.businessKey}
                            </Label>
                          )}
                        </Td>
                        {currentProcessMeta.code !== "dinnerPlan" &&
                          currentProcessMeta.code !== "managedservices" && (
                            <Td style={{ minWidth: "12rem" }}>
                              {currentProcessMeta.code === "evs"
                                ? variableOpportunityObject?.partnerName
                                : PCA_TYPE_TO_LABEL_AND_FIELDS[
                                    variableOpportunityObject
                                      ?.typeOfProjectChangeApproval
                                  ]?.label}
                            </Td>
                          )}
                        <Td style={{ minWidth: "12rem" }}>
                          {currentProcessMeta.code === "evs"
                            ? variableOpportunityObject?.partnerType
                            : variableOpportunityObject?.createdBy}
                        </Td>
                        <Td style={{ minWidth: "12rem" }}>
                          {getLabelByStatus(process?.state)}
                        </Td>
                        <Td style={{ minWidth: "12rem" }}>
                          {moment(process?.start).fromNow() || "N/A"}
                        </Td>
                        <Td style={{ minWidth: "12rem" }}>
                          {moment(process?.lastUpdate).fromNow() || "N/A"}
                        </Td>
                      </Tr>
                    );
                  })
              )}
            </Tbody>
          )}
        </Table>
        <div className="table-load-more-button">
          {mustShowLoadMore && (
            <Button
              icon={
                <Icon isInline>
                  <Spinner2Icon
                    style={{
                      transform: "scaleX(-1)",
                    }}
                  />
                </Icon>
              }
              variant="primary"
              type="button"
              onClick={() => onLoadMoreClick()}
            >
              Load More
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default List;
