import { PageSection, PageSectionVariants } from "@patternfly/react-core";
import { ProcessesMetaInterface } from "../../../constants/Processes";
import { getCurrentProcessMeta } from "../../../utils";
import { UnderMaintenance } from "../../../utils/UnderMaintenance";
import CreateEVS from "../../EVS/CreateProcess";
import CreatePCF from "../../PCF/CreateProcess";
import CreateDinner from "../../Dinner/CreateProcess";
import CreateMSW from "../../ManagedServices/CreateProcess";

const CreateProcess = () => {
  const currentProcessMeta: ProcessesMetaInterface = getCurrentProcessMeta();

  if (currentProcessMeta.isUnderMaintenance) {
    return (
      <PageSection
        style={{ margin: 20, textAlign: "center", padding: "80px 0" }}
        variant={PageSectionVariants.light}
      >
        <UnderMaintenance name={currentProcessMeta.name} />
      </PageSection>
    );
  } else if (currentProcessMeta.code === "pcf") return <CreatePCF />;
  else if (currentProcessMeta.code === "evs") return <CreateEVS />;
  else if (currentProcessMeta.code === "dinnerPlan") return <CreateDinner />;
  else if (currentProcessMeta.code === "managedservices") return <CreateMSW />;
};

export default CreateProcess;
