import {
  Button,
  Flex,
  Form,
  Text,
  TextContent,
  TextVariants,
} from "@patternfly/react-core";
import { useContext, useState } from "react";
import { RadioInput } from "../../../components/InputFields";
import { ArrowLeftIcon } from "@patternfly/react-icons";
import { useHistory } from "react-router-dom";
import { AlertContext } from "../../../contexts/alert/AlertContext";
import { changeTaskState } from "../CreateAccountPlan/apis";
import { accountPlanningEndpoints } from "../../../apis/endpoints";
import { useAuthContext } from "../../../contexts/authentication/AuthenticationContext";

function AccountPlanApproval({ task }) {
  const history = useHistory();
  const alert = useContext(AlertContext);
  const { user } = useAuthContext();
  const options: { label: string; value: string }[] = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];
  const [selectedValue, setSelectedValue] = useState({
    label: null,
    value: null,
  });
  let taskInputs = JSON.parse(task?.inputs);

  const handleOnClickSubmit = () => {
    if (selectedValue.value === null) {
      alert.addAlert("Please answer all the mandatory questions!", "danger");
      return;
    }

    let params = new URLSearchParams();
    params.append("user", user?.email);

    const payload = { approved: selectedValue.value === "yes" };
    changeTaskState({
      url: `${accountPlanningEndpoints.accountPlanning}/${task.processInstanceId}/${task.name}/${task.id}/phases/complete`,
      dataToSend: payload,
      params: params,
      successCallback: (data) => {
        history.push("/account-planning");
        alert.addAlert(
          "Account Plan review has been successfully submitted.",
          "success"
        );
      },
      failureCallback: (data) => {
        alert.addAlert(
          data?.response?.data?.message || "Something went wrong.",
          "danger"
        );
      },
    });
  };

  return (
    <div>
      <TextContent>
        <Text component={TextVariants.h2} className="sub-heading">
          Step 2: Account Plan Approval
        </Text>
      </TextContent>
      <p style={{ marginTop: "10px" }}>
        {`Reviewing ${taskInputs.accountPlanReview.sourceRecordID} | ${taskInputs.accountPlanReview.accountPlanName}`}
      </p>
      {taskInputs.accountPlanReview.lowScore && (
        <p style={{ marginTop: "10px", color: "red" }}>
          Based on current score it is recommended to reject the plan.
        </p>
      )}
      <Form style={{ margin: "10px 0" }}>
        <RadioInput
          isRequired={true}
          label="Do you approve of this account plan?"
          id="approval"
          options={options}
          selectedValue={selectedValue}
          setSelectedValue={(value) => setSelectedValue(value)}
        />
        <Flex>
          <Button
            icon={<ArrowLeftIcon />}
            onClick={() => history.push("/account-planning")}
          >
            Back to home
          </Button>
          <Button onClick={() => handleOnClickSubmit()}>Submit</Button>
        </Flex>
      </Form>
    </div>
  );
}

export default AccountPlanApproval;
