import { COMPONENT } from "../../constants/Process";

export interface MenuItems {
  menuIndex: number;
  displayName: string;
  linkTo: string;
  componentIdentifier: string;
}

export const headerMenuItems: MenuItems[] = [
  {
    menuIndex: 0,
    displayName: "All Processes",
    linkTo: "/processes/",
    componentIdentifier: COMPONENT.ALL_PROCESSES,
  },
  {
    menuIndex: 1,
    displayName: "Task Inbox",
    linkTo: "/tasks/",
    componentIdentifier: COMPONENT.ALL_TASKS,
  }
];
