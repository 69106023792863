import utilsApiClient from "../../apis/pa-utils/api-client";

interface RequestProps {
  url?: string;
  data?: any;
  params?: any;
  successCallback?: (response?) => void;
  failureCallback?: (response) => void;
}

const getUser = async (props: RequestProps) => {
  const { url, params, successCallback, failureCallback } = props;
  const response = await utilsApiClient.get(url);
  if (response.status === 200) {
    successCallback && successCallback(response.data);
  } else {
    failureCallback && failureCallback(response);
  }
};

const updateUser = async (props: RequestProps) => {
  const { url, data, params, successCallback, failureCallback } = props;
  const response = await utilsApiClient.patch(url, data, params);
  if (response.status === 200) {
    successCallback && successCallback(response.data);
  } else {
    failureCallback && failureCallback(response);
  }
};

export { getUser, updateUser };
