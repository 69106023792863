import { Text, TextContent } from "@patternfly/react-core";
import MaintenanceSVG from "../assets/maintenance.svg";

export const UnderMaintenance = ({ name }) => {
  return (
    <>
      <img
        src={MaintenanceSVG}
        height="500px"
        width="500px"
        alt="Under Maintenance"
      />
      <TextContent style={{ textAlign: "center", paddingTop: "100px" }}>
        <Text component="h1">Currently {name} is under maintenance 🚧</Text>
        <p style={{ marginTop: "0", marginBottom: 30 }}>
          Please come again later
        </p>
      </TextContent>
    </>
  );
};
