import { PageSection, PageSectionVariants } from "@patternfly/react-core";
import { ProcessesMetaInterface } from "../../../constants/Processes";
import { getCurrentProcessMeta } from "../../../utils";
import { UnderMaintenance } from "../../../utils/UnderMaintenance";
import List from "./List";
import { PROCESS_TO_PROCESS_LIST_META } from "../../../constants/Process";

const ProcessList = () => {
  const currentProcessMeta: ProcessesMetaInterface = getCurrentProcessMeta();

  if (currentProcessMeta.isUnderMaintenance) {
    return (
      <PageSection
        style={{ margin: 20, textAlign: "center", padding: "80px 0" }}
        variant={PageSectionVariants.light}
      >
        <UnderMaintenance name={currentProcessMeta.name} />
      </PageSection>
    );
  } else {
    return (
      <List
        processListMeta={PROCESS_TO_PROCESS_LIST_META[currentProcessMeta.code]}
      />
    );
  }
};

export default ProcessList;
