import { gql } from "@apollo/client";

export const GET_PROCESS_LIST = gql`
  query getProcessInstances(
    $where: ProcessInstanceArgument
    $offset: Int
    $limit: Int
    $orderBy: ProcessInstanceOrderBy
  ) {
    ProcessInstances(
      where: $where
      pagination: { offset: $offset, limit: $limit }
      orderBy: $orderBy
    ) {
      id
      processId
      processName
      parentProcessInstanceId
      rootProcessInstanceId
      roles
      state
      start
      lastUpdate
      addons
      businessKey
      serviceUrl
      variables
      error {
        nodeDefinitionId
        message
      }
    }
  }
`;

export const GET_PROCESS = gql`
  query getProcessInstances($where: ProcessInstanceArgument) {
    ProcessInstances(where: $where) {
      id
      processId
      processName
      parentProcessInstanceId
      rootProcessInstanceId
      roles
      state
      start
      lastUpdate
      addons
      businessKey
      variables
      nodes {
        id
        name
        type
        enter
        exit
        definitionId
        nodeId
      }
      serviceUrl
      error {
        nodeDefinitionId
        message
      }
    }
  }
`;
