import { Button, Modal, ModalVariant } from "@patternfly/react-core";

const LoginWarningModal = () => {
  return (
    <div className="rhsc-assistant-login-warning">
      <Modal
        variant={ModalVariant.small}
        title="You are logged out 😅"
        isOpen={true}
        showClose={false}
        actions={[
          <Button
            key="confirm"
            variant="primary"
            style={{ display: "none" }}
            onClick={() => {}}
          >
            Close
          </Button>,
        ]}
      >
        Shhh... You are logged out of the Red Hat Single sign on. To continue
        with RHSC Chat assistant, please re-login into Salesforce.
      </Modal>
    </div>
  );
};

export default LoginWarningModal;
