import {
  Grid,
  GridItem,
  Form as PFForm,
  PageSection,
  PageSectionVariants,
  TextContent,
  Text,
  Button,
  debounce,
  FormGroup,
  ActionGroup,
} from "@patternfly/react-core";
import "./style.scss";
import { useContext, useEffect, useState } from "react";
import { BasicTextInput, DateInput } from "../../../components/InputFields";
import "../../EVS/CreateProcess";
import { useHistory } from "react-router-dom";
import { AlertContext } from "../../../contexts/alert/AlertContext";
import VALIDATION from "../../../constants/validation";
import { ChevronLeftIcon } from "@patternfly/react-icons";
import { createDinner, getDinnerRecipes } from "./apis";
import moment from "moment";
import { utilsApiEndpoints } from "../../../apis/endpoints";
import { getUserEmails } from "../../EVS/CreateProcess/apis";
import { DinnerKeywords } from "../../../constants/Dinner";
import {
  SelectVariant,
  Select,
  SelectOption,
} from "@patternfly/react-core/deprecated";
import { useAuthContext } from "../../../contexts/authentication/AuthenticationContext";

const CreateDinner = () => {
  const history = useHistory();
  const { user } = useAuthContext();
  const alert = useContext(AlertContext);
  const [formValues, setFormValues] = useState({
    place: "",
    budget: "",
    dinnerKeyword: "",
    pitch: "",
    scrumMasterEmail: "",
    managerEmail: "",
    date: "",
    onWeekDay: false,
    opportunity: {},
  });
  const [isScrumMasterEmailOpen, setIsScrumMasterEmailOpen] = useState(false);
  const [isManagerEmailOpen, setIsManagerEmailOpen] = useState(false);
  const [scrumMasterEmailList, setScrumMasterEmailList] = useState([]);
  const [managerEmailList, setManagerEmailList] = useState([]);
  const [isSelectDinnerKeywordOpen, setIsSelectDinnerKeywordOpen] =
    useState(false);
  const [dinnerRecipesList, setDinnerRecipesList] = useState([]);
  const [isSelectDinnerRecipeOpen, setIsSelectDinnerRecipeOpen] =
    useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);

  let dinnerKeywordOptions = DinnerKeywords.map((keyword) => (
    <SelectOption key={keyword.id} value={keyword.value} />
  ));

  const onSelectDinnerRecipe = (event, selection) => {
    const recipe = dinnerRecipesList.find((item) => item.title == selection);
    setFormValues({
      ...formValues,
      pitch: selection,
      opportunity: {
        imageUrl: recipe.image_url,
        publisher: recipe.publisher,
        publisherUrl: recipe.publisher_url,
        recipeId: recipe.recipe_id,
        socialRank: recipe.social_rank,
        sourceUrl: recipe.source_url,
        title: recipe.title,
      },
    });
    setIsSelectDinnerRecipeOpen(false);
  };

  const onDinnerRecipeToggle = (isOpen, value) => {
    setIsSelectDinnerRecipeOpen(isOpen);
  };

  const onSelectDinnerKeyword = (event, selection) => {
    setFormValues({
      ...formValues,
      dinnerKeyword: selection,
      pitch: "",
      opportunity: {},
    });
    setDinnerRecipesList([]);
    setIsSelectDinnerKeywordOpen(false);
  };

  const onDinnerKeywordToggle = (isOpen, value) => {
    setIsSelectDinnerKeywordOpen(isOpen);
  };

  const onScrumMasterToggle = (isOpen, value) => {
    setIsScrumMasterEmailOpen(isOpen);
  };

  const onManagerToggle = (isOpen, value) => {
    setIsManagerEmailOpen(isOpen);
  };

  const onScrumMasterSelect = (event, selection) => {
    setFormValues({ ...formValues, scrumMasterEmail: selection });
    setIsScrumMasterEmailOpen(false);
  };

  const onManagerSelect = (event, selection) => {
    setFormValues({ ...formValues, managerEmail: selection });
    setIsManagerEmailOpen(false);
  };

  const clearScrumMasterSelection = (value) => {
    setScrumMasterEmailList([]);
    setIsScrumMasterEmailOpen(false);
    setFormValues({ ...formValues, scrumMasterEmail: "" });
  };

  const clearManagerSelection = (value) => {
    setManagerEmailList([]);
    setIsManagerEmailOpen(false);
    setFormValues({ ...formValues, managerEmail: "" });
  };

  const onSubmit = (value: any) => {
    setIsSubmitDisabled(true);
    value.preventDefault();

    var _formValues = {
      ...formValues,
    };

    setFormValues(_formValues);
    let isValid = Object.entries(_formValues).every(([, value]) => {
      if (typeof value === "string") {
        return value.length > 0;
      } else if (Array.isArray(value)) {
        return value.length > 0;
      } else if (typeof value === "boolean") {
        return value;
      } else if (typeof value === "object") {
        return Object.keys(value).length !== 0;
      }
    });

    if (!isValid) {
      alert.addAlert("Please fill all the required fields", "danger");
      setIsSubmitDisabled(false);
      return;
    }
    if (isValid) {
      const uniqueId = parseInt((Date.now() * Math.random()).toString());
      createDinner({
        dataToSend: {
          dinner: {
            ..._formValues,
            createdByEmail: user?.email,
            createdBy: user?.name,
          },
        },
        params: {
          businessKey: uniqueId,
        },
        successCallback: () => {
          alert.addAlert("Dinner Request Created.", "success");
          history.push("/processes/dinnerPlan");
        },
        failureCallback: () => setIsSubmitDisabled(false),
      });
    } else {
      alert.addAlert("Please fill all the required fields.", "danger");
      setIsSubmitDisabled(false);
    }
  };

  useEffect(() => {
    if (formValues.dinnerKeyword) {
      getDinnerRecipes({
        params: { q: formValues.dinnerKeyword },
        successCallback: (response) => {
          setDinnerRecipesList(response.recipes);
        },
        failureCallback: () => {},
      });
    }
  }, [formValues.dinnerKeyword]);

  useEffect(() => {
    const date = new Date(formValues.date);
    var day = date.getDay();
    if (day === 6 || day === 0) {
      setFormValues({ ...formValues, onWeekDay: false });
    } else {
      setFormValues({ ...formValues, onWeekDay: true });
    }
  }, [formValues.date]);

  const onEmailChange = (value, type) => {
    if (value.length === 0) {
      if (type === "scrumMaster") {
        setScrumMasterEmailList([]);
      } else {
        setManagerEmailList([]);
      }
      return;
    }
    if (value.length >= 2) debouncedNotifyEmails(value, type);
  };

  const debouncedNotifyEmails = debounce(
    async (value: string, type: string) => {
      getUserEmails({
        url: utilsApiEndpoints.roverEmail,
        params: { searchKey: value },
        successCallback: (data) => {
          const emailDropdownValues = data.map((item) => {
            return {
              businessCardTitle: item?.businessCardTitle,
              email: item?.email,
              name: item?.name,
              rhatLocation: item?.rhatLocation,
              uid: item?.uid,
              uuid: item?.uuid,
              disabled: false,
            };
          });

          if (type === "scrumMaster") {
            setScrumMasterEmailList(emailDropdownValues);
          } else {
            setManagerEmailList(emailDropdownValues);
          }
        },
      });
    },
    300
  );

  return (
    <>
      <PageSection variant={PageSectionVariants.light}>
        <Grid>
          <GridItem span={8} style={{ display: "flex" }}>
            <button
              type="button"
              className="back-to-list-button"
              onClick={() => history.push("/processes/dinnerPlan/")}
            >
              <ChevronLeftIcon height="14px" />
            </button>
            <TextContent>
              <Text component="h1">Create Form</Text>
            </TextContent>
          </GridItem>
        </Grid>
      </PageSection>
      <div style={{ margin: 20 }}>
        <PFForm
          style={{
            padding: "10px 40px",
            background: "white",
            height: "100%",
          }}
        >
          {/* Enter Place */}
          <BasicTextInput
            validation={(value) => {
              return VALIDATION.length(value, 2, 180);
            }}
            maxLength={180}
            isRequired={true}
            id="place"
            label="Place"
            value={formValues.place}
            setValue={(value) => {
              setFormValues({ ...formValues, place: value });
            }}
          />

          {/* Enter Budget */}
          <BasicTextInput
            validation={(value) => {
              return VALIDATION.length(value, 0, 180);
            }}
            type={"number"}
            maxLength={180}
            isRequired={true}
            id="budget"
            label="Budget"
            value={formValues.budget}
            setValue={(value) => {
              setFormValues({ ...formValues, budget: value });
            }}
          />

          {/* Select Food Keyword */}
          <div className="select-dropdown">
            <FormGroup label="Pitch" isRequired={true} id="pitch">
              <Select
                variant={SelectVariant.typeahead}
                typeAheadAriaLabel="Search and select a food keyword"
                onToggle={onDinnerKeywordToggle}
                onSelect={onSelectDinnerKeyword}
                selections={formValues.dinnerKeyword}
                isOpen={isSelectDinnerKeywordOpen}
                aria-labelledby="dinnerKeyword"
                placeholderText="Search and select a food keyword"
              >
                {dinnerKeywordOptions}
              </Select>
            </FormGroup>
          </div>

          {/* Select Dinner Recipes */}
          <div className="select-dropdown">
            <Select
              variant={SelectVariant.typeahead}
              typeAheadAriaLabel="Search and select a Dinner recipe"
              onToggle={onDinnerRecipeToggle}
              onSelect={onSelectDinnerRecipe}
              selections={formValues.pitch}
              isOpen={isSelectDinnerRecipeOpen}
              aria-labelledby="pitch"
              placeholderText="Search and select a Dinner recipe"
              isDisabled={dinnerRecipesList.length === 0}
            >
              {dinnerRecipesList &&
                dinnerRecipesList.map((recipe) => (
                  <SelectOption key={recipe.recipe_id} value={recipe.title} />
                ))}
            </Select>
          </div>

          {/* Select Scrum Master Email */}
          <div className="select-dropdown">
            <FormGroup
              id="scrumMasterEmail"
              label="Scrum Master Email"
              isRequired={true}
            >
              <Select
                variant={SelectVariant.typeahead}
                typeAheadAriaLabel="Search by email or name"
                onToggle={onScrumMasterToggle}
                required={true}
                onSelect={onScrumMasterSelect}
                onClear={clearScrumMasterSelection}
                selections={formValues.scrumMasterEmail}
                onTypeaheadInputChanged={(value) => {
                  onEmailChange(value, "scrumMaster");
                }}
                isOpen={isScrumMasterEmailOpen}
                placeholderText="Search by email or name"
                aria-labelledby="multipleEmailsSelect"
              >
                {scrumMasterEmailList.map((option, index) => (
                  <SelectOption
                    isDisabled={option.disabled}
                    key={index}
                    value={option.email}
                    {...(option.name && {
                      description: option.name,
                    })}
                  />
                ))}
              </Select>
            </FormGroup>
          </div>

          {/* Select Manager Email */}
          <div className="select-dropdown">
            <FormGroup
              id="managerEmail"
              label="Manager Email"
              isRequired={true}
            >
              <Select
                variant={SelectVariant.typeahead}
                typeAheadAriaLabel="Search by email or name"
                onToggle={onManagerToggle}
                onSelect={onManagerSelect}
                onClear={clearManagerSelection}
                selections={formValues.managerEmail}
                onTypeaheadInputChanged={(value) => {
                  onEmailChange(value, "manager");
                }}
                isOpen={isManagerEmailOpen}
                placeholderText="Search by email or name"
                aria-labelledby="multipleEmailsSelect"
              >
                {managerEmailList.map((option, index) => (
                  <SelectOption
                    isDisabled={option.disabled}
                    key={index}
                    value={option.email}
                    {...(option.name && {
                      description: option.name,
                    })}
                  />
                ))}
              </Select>
            </FormGroup>
          </div>

          {/* Select Date */}
          <DateInput
            isRequired={true}
            style={{ width: "100%" }}
            id="date"
            helperText="Dinner Date"
            setValue={(value) => {
              setFormValues({
                ...formValues,
                date: moment(value).format("YYYY-MM-DD"),
              });
            }}
          />
          <ActionGroup
            style={{
              marginBottom: 50,
            }}
          >
            <Button
              onClick={onSubmit}
              style={{ fontWeight: "500" }}
              variant="primary"
              isDisabled={isSubmitDisabled}
              isLoading={isSubmitDisabled}
            >
              {isSubmitDisabled ? "Creating..." : "Create"}
            </Button>
          </ActionGroup>
        </PFForm>
      </div>
    </>
  );
};

export default CreateDinner;
