import { createHttpLink, useQuery } from "@apollo/client";
import {
  CardBody,
  CardHeader,
  Flex,
  FlexItem,
  Form,
  FormGroup,
  Grid,
  GridItem,
  PageSection,
  PageSectionVariants,
  Split,
  SplitItem,
  Stack,
  Tab,
  TabContent,
  TabContentBody,
  TabTitleText,
  Tabs,
  Text,
  TextContent,
  TextVariants,
  Title,
  Tooltip,
} from "@patternfly/react-core";
import {
  CheckCircleIcon,
  ChevronLeftIcon,
  OnRunningIcon,
  TimesCircleIcon,
} from "@patternfly/react-icons";
import "./processDetails.scss";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { GET_PROCESS } from "../../../graphql/queries/Process";
import { Link, useHistory, useParams } from "react-router-dom";
import { Loader } from "../../../components/Loader/Loader";
import { getAuthContext } from "../../../graphql/auth";
import Comments from "../Comments";
import {
  PROCESS_DETAILS_FIELDS,
  ProcessDetail,
  STATUS,
} from "../../../constants/Process";
import _ from "lodash";
import {
  renderOpportunityDetails,
  renderOutputDetails,
  renderProcessInputs,
} from "../TabContents";
import { getCurrentProcessMeta } from "../../../utils";
import { DINNER_PLAN_API_BASE_URL, getBaseURL } from "../../../apis/endpoints";
import client from "../../../graphql/client";
import { GlobalStateContext } from "../../../store/GlobalContextProvider";
import { GET_TASKS_LIST_BY_PROCESS_ID } from "../../../graphql/queries/Task";
import { getProcessRules, getProcessSVG } from "./apis";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { useAuthContext } from "../../../contexts/authentication/AuthenticationContext";

const Detail = (props: { detailMeta: ProcessDetail }) => {
  const {
    globalState: { currentProcess },
  } = useContext(GlobalStateContext);
  const baseURL = getBaseURL(currentProcess);
  baseURL && client.setLink(createHttpLink({ uri: `${baseURL}/graphql` }));
  const { user } = useAuthContext();
  const history = useHistory();
  const {
    detailMeta: { opportunityFields, processId },
  } = props;
  const { opportunityObject, code } = getCurrentProcessMeta();
  const { id } = useParams<{ id: string }>();
  const [processData, setProcessData] = useState(null);
  const [nodes, setNodes] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState(0);
  const [activeTimelineTabKey, setActiveTimelineTabKey] = useState(0);
  const [processSVG, setProcessSVG] = useState(null);
  const [processRules, setProcessRules] = useState(null);

  const { loading, error, data } = useQuery(GET_PROCESS, {
    fetchPolicy: "network-only",
    context: getAuthContext(),
    variables: {
      where: { id: { equal: id } },
    },
    client: client,
  });

  const { data: tasks, loading: taskListLoading } = useQuery(
    GET_TASKS_LIST_BY_PROCESS_ID,
    {
      fetchPolicy: "network-only",
      context: getAuthContext(),
      variables: {
        where: { processInstanceId: { equal: id } },
      },
      client: client,
    }
  );

  const handleTabClick = (event, tabIndex) => {
    setActiveTabKey(tabIndex);
  };

  const handleTimelineTabClick = (event, tabIndex) => {
    setActiveTimelineTabKey(tabIndex);
  };

  const getTimelineDataToShow = (process: any) => {
    if (process && process.nodes.length > 0) {
      const _nodes = process.nodes.filter(
        (node) => node.type === "HumanTaskNode"
      );
      return _.sortBy(_nodes, "exit");
    }
    return [];
  };

  const isPcaViewerRole =
    window?.sessionjs?.realmAccess?.roles.includes("pa-pca-viewers");

  useEffect(() => {
    if (data) {
      setProcessData(data.ProcessInstances[0]);
    }
  }, [data]);

  useEffect(() => {
    if (tasks && tasks.UserTaskInstances) {
      setTaskList(tasks.UserTaskInstances);
    }
  }, [tasks]);

  useEffect(() => {
    if (processData) {
      setNodes(getTimelineDataToShow(processData));
    }
  }, [processData]);

  useEffect(() => {
    if (processId === "dinnerPlan" && id) {
      getProcessSVG({
        url: `${DINNER_PLAN_API_BASE_URL}/svg/processes/${processId}/instances/${id}`,
        successCallback: (response) => {
          setProcessSVG(response.data);
        },
      });

      getProcessRules({
        url: `${DINNER_PLAN_API_BASE_URL}/dinner/rules`,
        successCallback: (response) => {
          setProcessRules(response.data);
        },
      });
    }
  }, []);

  const renderProcessDetails = (): JSX.Element => {
    return (
      <FlexItem>
        <CardHeader>
          <Title headingLevel="h3" size="xl">
            Details
          </Title>
        </CardHeader>
        <CardBody>
          <Form>
            {processData &&
              PROCESS_DETAILS_FIELDS.map((field) => (
                <FormGroup label={field.label} fieldId={field.id}>
                  <Text component={TextVariants.p}>
                    {!field.isNativeKey
                      ? JSON.parse(processData?.variables)?.[opportunityObject][
                          field.id
                        ]
                      : field.type === "text"
                      ? processData?.[field.id] || "N/A"
                      : moment(processData?.[field.id]).fromNow() || "N/A"}
                  </Text>
                </FormGroup>
              ))}
          </Form>
        </CardBody>
      </FlexItem>
    );
  };

  const renderProcessTimeline = (): JSX.Element => {
    return (
      <FlexItem style={{ minHeight: "600px" }}>
        <CardHeader>
          <Title headingLevel="h3" size="xl">
            Timeline
          </Title>
        </CardHeader>
        <CardBody>
          {taskListLoading ? (
            <Loader text="Loading timeline details" />
          ) : (
            nodes.map((node) => {
              const nodeTask = taskList?.find(
                (task) => task.referenceName === node.name
              );
              const taskOutput =
                nodeTask?.outputs && JSON.parse(nodeTask?.outputs);
              return (
                <Stack
                  hasGutter
                  className="process-details--timeline timeline-item-box"
                >
                  <Split hasGutter className={"process-details--timeline-item"}>
                    <SplitItem>
                      <Tooltip
                        content={
                          node.exit
                            ? taskOutput?.approved
                              ? "Approved"
                              : "Rejected"
                            : "Ready"
                        }
                      >
                        {node.exit ? (
                          taskOutput?.approved ? (
                            <CheckCircleIcon
                              color="var(--pf-v5-global--success-color--100)"
                              className="process-details--timeline-status"
                            />
                          ) : (
                            <TimesCircleIcon
                              color="var(--pf-v5-global--danger-color--100)"
                              className="process-details--timeline-status"
                            />
                          )
                        ) : (
                          <OnRunningIcon
                            color="var(--pf-v5-global--read-color--100)"
                            className="process-details--timeline-status"
                          />
                        )}
                      </Tooltip>
                    </SplitItem>
                    <SplitItem isFilled>
                      <TextContent>
                        <Text component={TextVariants.p}>{node.name}</Text>
                        {node.exit && (
                          <>
                            <Text
                              style={{ margin: "-15px 0 10px 0" }}
                              component={TextVariants.small}
                            >
                              {taskOutput?.approved ? "Approved" : "Rejected"}{" "}
                              by {taskOutput?.ActorId}
                            </Text>
                            <Text
                              style={{ margin: "-7px 0 0 0" }}
                              component={TextVariants.small}
                            >
                              {node.exit && moment(node.exit).fromNow()}
                            </Text>
                          </>
                        )}
                      </TextContent>
                    </SplitItem>
                  </Split>
                </Stack>
              );
            })
          )}
        </CardBody>
      </FlexItem>
    );
  };

  const renderProcessDiagram = (): JSX.Element => {
    if (processSVG)
      return (
        <FlexItem style={{ minHeight: "600px" }}>
          <CardHeader>
            <Title headingLevel="h3" size="xl">
              Process Diagram
            </Title>
          </CardHeader>
          <CardBody>
            <Zoom>
              <div dangerouslySetInnerHTML={{ __html: processSVG }} />
            </Zoom>
            <TextContent>
              <Text component={TextVariants.small}>Click to zoom</Text>
            </TextContent>
          </CardBody>
        </FlexItem>
      );
    else return <></>;
  };

  const renderProcessRules = (): JSX.Element => {
    if (processRules)
      return (
        <FlexItem style={{ minHeight: "600px" }}>
          <CardHeader>
            <Title headingLevel="h3" size="xl">
              Process Rules
            </Title>
          </CardHeader>
          <CardBody>
            <SyntaxHighlighter language="java" style={docco} showLineNumbers>
              {processRules}
            </SyntaxHighlighter>
          </CardBody>
        </FlexItem>
      );
    else return <></>;
  };

  return (
    <>
      {/* TODO: by-passing check as roles yet to come in place for evs */}
      {!loading && processData ? (
        code === "evs" ||
        code === "dinnerPlan" ||
        code === "managedservices" ||
        JSON.parse(processData.variables)?.[opportunityObject]
          ?.createdByEmail === user.email ||
        (isPcaViewerRole && processData?.roles.includes("pa-pca-viewers")) ? (
          <>
            <PageSection variant={PageSectionVariants.light}>
              <Grid>
                <GridItem span={8} style={{ display: "flex" }}>
                  <button
                    type="button"
                    className="back-to-list-button"
                    onClick={() => history.push(`/processes/${code}/`)}
                  >
                    <ChevronLeftIcon height="14px" />
                  </button>
                  <TextContent>
                    <Text component="h1">
                      {processData?.processName}
                      {processData?.state === STATUS.COMPLETED &&
                      JSON.parse(processData?.variables)?.[opportunityObject]
                        ?.pdfDriveLink ? (
                        <a
                          href={
                            "https://drive.google.com/file/d/" +
                            JSON.parse(processData?.variables)?.[
                              opportunityObject
                            ]?.pdfDriveLink
                          }
                          target="_blank"
                          style={{ fontSize: 16, marginLeft: 10 }}
                        >
                          View Project Change Form
                        </a>
                      ) : (
                        <></>
                      )}
                    </Text>
                  </TextContent>
                </GridItem>
              </Grid>
            </PageSection>
            <div style={{ margin: 20 }}>
              <Flex alignItems={{ default: "alignItemsFlexStart" }}>
                <FlexItem
                  flex={{ default: "flex_4" }}
                  className="task-details-section"
                >
                  <PageSection
                    type="tabs"
                    variant={PageSectionVariants.light}
                    isWidthLimited
                  >
                    <Tabs activeKey={activeTabKey} onSelect={handleTabClick}>
                      <Tab
                        eventKey={0}
                        title={<TabTitleText>Inputs</TabTitleText>}
                        tabContentId={"tabContent0"}
                      ></Tab>
                      <Tab
                        eventKey={1}
                        title={<TabTitleText>Outputs</TabTitleText>}
                        tabContentId={"tabContent1"}
                      ></Tab>
                      <Tab
                        eventKey={2}
                        title={<TabTitleText>Enriched Data</TabTitleText>}
                        tabContentId={"tabContent2"}
                      ></Tab>
                      <Tab
                        eventKey={3}
                        title={<TabTitleText>Details</TabTitleText>}
                        tabContentId={"tabContent3"}
                      ></Tab>
                    </Tabs>
                  </PageSection>
                  <PageSection
                    variant={PageSectionVariants.light}
                    isWidthLimited
                    className="task-details-section"
                  >
                    <TabContent
                      key={0}
                      eventKey={0}
                      id={"tabContent0"}
                      activeKey={activeTabKey}
                      hidden={0 !== activeTabKey}
                    >
                      <TabContentBody>
                        {renderProcessInputs(
                          JSON.parse(processData?.variables)[opportunityObject],
                          code
                        )}
                      </TabContentBody>
                    </TabContent>
                    <TabContent
                      key={1}
                      eventKey={1}
                      id={"tabContent1"}
                      activeKey={activeTabKey}
                      hidden={1 !== activeTabKey}
                    >
                      <TabContentBody>
                        {renderOutputDetails(
                          JSON.parse(processData?.variables)?.[
                            opportunityObject
                          ],
                          code
                        )}
                      </TabContentBody>
                    </TabContent>
                    <TabContent
                      key={2}
                      eventKey={2}
                      id={"tabContent2"}
                      activeKey={activeTabKey}
                      hidden={2 !== activeTabKey}
                    >
                      <TabContentBody>
                        {renderOpportunityDetails(
                          opportunityFields,
                          JSON.parse(processData?.variables)[opportunityObject]
                            ?.opportunity
                        )}
                      </TabContentBody>
                    </TabContent>
                    <TabContent
                      key={3}
                      eventKey={3}
                      id={"tabContent3"}
                      activeKey={activeTabKey}
                      hidden={3 !== activeTabKey}
                    >
                      <TabContentBody>{renderProcessDetails()}</TabContentBody>
                    </TabContent>
                  </PageSection>
                </FlexItem>
                <FlexItem
                  flex={{ default: "flex_4" }}
                  className="task-details-section"
                >
                  <PageSection
                    type="tabs"
                    variant={PageSectionVariants.light}
                    isWidthLimited
                  >
                    <Tabs
                      activeKey={activeTimelineTabKey}
                      onSelect={handleTimelineTabClick}
                    >
                      <Tab
                        eventKey={0}
                        title={<TabTitleText>Timeline</TabTitleText>}
                        tabContentId={"tabContent0"}
                      ></Tab>
                      {processId === "dinnerPlan" && (
                        <>
                          <Tab
                            eventKey={1}
                            title={<TabTitleText>Process Diagram</TabTitleText>}
                            tabContentId={"tabContent1"}
                          ></Tab>
                          <Tab
                            eventKey={2}
                            title={<TabTitleText>Process Rules</TabTitleText>}
                            tabContentId={"tabContent2"}
                          ></Tab>
                        </>
                      )}
                    </Tabs>
                  </PageSection>
                  <PageSection
                    variant={PageSectionVariants.light}
                    isWidthLimited
                    className="task-details-section"
                  >
                    <TabContent
                      key={0}
                      eventKey={0}
                      id={"tabContent0"}
                      activeKey={activeTimelineTabKey}
                      hidden={0 !== activeTimelineTabKey}
                    >
                      <TabContentBody>{renderProcessTimeline()}</TabContentBody>
                    </TabContent>
                    <TabContent
                      key={1}
                      eventKey={1}
                      id={"tabContent1"}
                      activeKey={activeTimelineTabKey}
                      hidden={1 !== activeTimelineTabKey}
                    >
                      <TabContentBody>{renderProcessDiagram()}</TabContentBody>
                    </TabContent>
                    <TabContent
                      key={2}
                      eventKey={2}
                      id={"tabContent2"}
                      activeKey={activeTimelineTabKey}
                      hidden={2 !== activeTimelineTabKey}
                    >
                      <TabContentBody>{renderProcessRules()}</TabContentBody>
                    </TabContent>
                  </PageSection>
                </FlexItem>
              </Flex>
              {processData && <Comments processId={processData.id} />}
            </div>
          </>
        ) : (
          <>
            You are not authorized to interact with this Process request. Please
            navigate to your <Link to="/processes">processes</Link>.
          </>
        )
      ) : (
        <Loader text="Loading process details" />
      )}
    </>
  );
};

export default Detail;
