import ReactDOM from "react-dom/client";
import "./index.css";
import "@patternfly/react-core/dist/styles/base.css";
import App from "./App";
import { AuthContextProvider } from "./contexts/authentication/AuthenticationContext";
import { ApolloProvider } from "@apollo/client";
import { getClient } from "./graphql/client";
import { GlobalContextProvider } from "./store/GlobalContextProvider";
import { HashRouter } from "react-router-dom";
import { AlertProvider } from "./contexts/alert/AlertContext";

const client = getClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <HashRouter>
    <AuthContextProvider>
      <GlobalContextProvider>
        <ApolloProvider client={client}>
          <AlertProvider>
            <App />
          </AlertProvider>
        </ApolloProvider>
      </GlobalContextProvider>
    </AuthContextProvider>
  </HashRouter>
);
