import {
  Alert,
  AlertActionCloseButton,
  AlertGroup,
  AlertProps,
  AlertVariant,
} from "@patternfly/react-core";
import React, { useState } from "react";

export interface AuthContextProviderProps {
  children: JSX.Element;
}

export const AlertContext = React.createContext({
  addAlert: (title: string, variant: AlertProps["variant"]) => {},
});

export const AlertProvider: React.FC<AuthContextProviderProps> = ({
  children,
}) => {
  const [alerts, setAlerts] = useState<Partial<AlertProps>[]>([]);
  const addAlert = (title: string, variant: AlertProps["variant"]) => {
    setAlerts((prevAlerts) => [
      ...prevAlerts,
      { title, variant, key: new Date().getTime() },
    ]);
  };

  const removeAlert = (key: React.Key) => {
    setAlerts((prevAlerts) => [
      ...prevAlerts.filter((alert) => alert.key !== key),
    ]);
  };
  return (
    <AlertContext.Provider value={{ addAlert }}>
      <AlertGroup isToast isLiveRegion>
        {alerts.map(({ key, variant, title }) => (
          <Alert
            timeout={4000}
            onTimeout={() => removeAlert(key)}
            variant={AlertVariant[variant]}
            title={title}
            actionClose={
              <AlertActionCloseButton
                title={title as string}
                onClose={() => removeAlert(key)}
              />
            }
            key={key}
          />
        ))}
      </AlertGroup>
      {children}
    </AlertContext.Provider>
  );
};
