import apiClient from "../../../apis/api-client";
import { EVS_API_BASE_URL, evsApiEndpoints } from "../../../apis/endpoints";
import utilsApiClient from "../../../apis/pa-utils/api-client";

interface RequestProps {
  url?: string;
  dataToSend?: any;
  successCallback?: (data) => void;
  failureCallback?: () => void;
  params?: any;
}

const getOpportunityDetails = async (props: RequestProps) => {
  const { dataToSend, successCallback, failureCallback } = props;
  const response = await apiClient(EVS_API_BASE_URL).post(
    evsApiEndpoints.enrichEvs,
    dataToSend
  );
  if (response.status === 201) {
    successCallback && successCallback(response.data);
  } else {
    failureCallback && failureCallback();
  }
};

const createEVS = async (props: RequestProps) => {
  const { dataToSend, successCallback, failureCallback, params } = props;
  const response = await apiClient(EVS_API_BASE_URL).post(
    evsApiEndpoints.evs,
    dataToSend,
    {
      params,
    }
  );
  if (response.status === 201) {
    successCallback && successCallback(response.data);
  } else {
    failureCallback && failureCallback();
  }
};

const getUserEmails = async (props: RequestProps) => {
  const { url, params, successCallback, failureCallback } = props;
  const response = await utilsApiClient.get(url, {
    params,
  });
  if (response.status === 200) {
    successCallback && successCallback(response.data);
  } else {
    failureCallback && failureCallback();
  }
};

export { getOpportunityDetails, createEVS, getUserEmails };
