export const DinnerKeywords = [
  {
    id: 0,
    value: "Carrot",
  },
  {
    id: 1,
    value: "Broccoli",
  },
  {
    id: 2,
    value: "Asparagus",
  },
  {
    id: 3,
    value: "Cauliflower",
  },
  {
    id: 4,
    value: "Corn",
  },
  {
    id: 5,
    value: "Cucumber",
  },
  {
    id: 6,
    value: "Green pepper",
  },
  {
    id: 7,
    value: "Lettuce",
  },
  {
    id: 8,
    value: "Mushrooms",
  },
  {
    id: 9,
    value: "Onion",
  },
  {
    id: 10,
    value: "Potato",
  },
  {
    id: 11,
    value: "Pumpkin",
  },
  {
    id: 12,
    value: "Red pepper",
  },
  {
    id: 13,
    value: "Tomato",
  },
  {
    id: 14,
    value: "Beetroot",
  },
  {
    id: 15,
    value: "Brussel sprouts",
  },
  {
    id: 16,
    value: "Peas",
  },
  {
    id: 17,
    value: "Zucchini",
  },
  {
    id: 18,
    value: "Radish",
  },
  {
    id: 19,
    value: "Sweet potato",
  },
  {
    id: 20,
    value: "Artichoke",
  },
  {
    id: 21,
    value: "Leek",
  },
  {
    id: 22,
    value: "Cabbage",
  },
  {
    id: 23,
    value: "Celery",
  },
  {
    id: 24,
    value: "Chili",
  },
  {
    id: 25,
    value: "Garlic",
  },
  {
    id: 26,
    value: "Basil",
  },
  {
    id: 27,
    value: "Coriander",
  },
  {
    id: 28,
    value: "Parsley",
  },
  {
    id: 29,
    value: "Dill",
  },
  {
    id: 30,
    value: "Rosemary",
  },
  {
    id: 31,
    value: "Oregano",
  },
  {
    id: 32,
    value: "Cinnamon",
  },
  {
    id: 33,
    value: "Saffron",
  },
  {
    id: 34,
    value: "Green bean",
  },
  {
    id: 35,
    value: "Bean",
  },
  {
    id: 36,
    value: "Chickpea",
  },
  {
    id: 37,
    value: "Lentil",
  },
  {
    id: 38,
    value: "Apple",
  },
  {
    id: 39,
    value: "Apricot",
  },
  {
    id: 40,
    value: "Avocado",
  },
  {
    id: 41,
    value: "Banana",
  },
  {
    id: 42,
    value: "Blackberry",
  },
  {
    id: 43,
    value: "Blackcurrant",
  },
  {
    id: 44,
    value: "Blueberry",
  },
  {
    id: 45,
    value: "Boysenberry",
  },
  {
    id: 46,
    value: "Cherry",
  },
  {
    id: 47,
    value: "Coconut",
  },
  {
    id: 48,
    value: "Fig",
  },
  {
    id: 49,
    value: "Grape",
  },
  {
    id: 50,
    value: "Grapefruit",
  },
  {
    id: 51,
    value: "Kiwifruit",
  },
  {
    id: 52,
    value: "Lemon",
  },
  {
    id: 53,
    value: "Lime",
  },
  {
    id: 54,
    value: "Lychee",
  },
  {
    id: 55,
    value: "Mandarin",
  },
  {
    id: 56,
    value: "Mango",
  },
  {
    id: 57,
    value: "Melon",
  },
  {
    id: 58,
    value: "Nectarine",
  },
  {
    id: 59,
    value: "Orange",
  },
  {
    id: 60,
    value: "Papaya",
  },
  {
    id: 61,
    value: "Passion fruit",
  },
  {
    id: 62,
    value: "Peach",
  },
  {
    id: 63,
    value: "Pear",
  },
  {
    id: 64,
    value: "Pineapple",
  },
  {
    id: 65,
    value: "Plum",
  },
  {
    id: 66,
    value: "Pomegranate",
  },
  {
    id: 67,
    value: "Quince",
  },
  {
    id: 68,
    value: "Raspberry",
  },
  {
    id: 69,
    value: "Strawberry",
  },
  {
    id: 70,
    value: "Watermelon",
  },
  {
    id: 71,
    value: "Salad",
  },
  {
    id: 72,
    value: "Pizza",
  },
  {
    id: 73,
    value: "Pasta",
  },
  {
    id: 74,
    value: "Popcorn",
  },
  {
    id: 75,
    value: "Lobster",
  },
  {
    id: 76,
    value: "Steak",
  },
  {
    id: 77,
    value: "Bbq",
  },
  {
    id: 78,
    value: "Pudding",
  },
  {
    id: 79,
    value: "Hamburger",
  },
  {
    id: 80,
    value: "Pie",
  },
  {
    id: 81,
    value: "Cake",
  },
  {
    id: 82,
    value: "Sausage",
  },
  {
    id: 83,
    value: "Tacos",
  },
  {
    id: 84,
    value: "Kebab",
  },
  {
    id: 85,
    value: "Poutine",
  },
  {
    id: 86,
    value: "Seafood",
  },
  {
    id: 87,
    value: "Chips",
  },
  {
    id: 88,
    value: "Fries",
  },
  {
    id: 89,
    value: "Masala",
  },
  {
    id: 90,
    value: "Paella",
  },
  {
    id: 91,
    value: "Som tam",
  },
  {
    id: 92,
    value: "Chicken",
  },
  {
    id: 93,
    value: "Toast",
  },
  {
    id: 94,
    value: "Marzipan",
  },
  {
    id: 95,
    value: "Tofu",
  },
  {
    id: 96,
    value: "Ketchup",
  },
  {
    id: 97,
    value: "Hummus",
  },
  {
    id: 98,
    value: "Chili",
  },
  {
    id: 99,
    value: "Maple syrup",
  },
  {
    id: 100,
    value: "Parma ham",
  },
  {
    id: 101,
    value: "Fajitas",
  },
  {
    id: 102,
    value: "Champ",
  },
  {
    id: 103,
    value: "Lasagna",
  },
  {
    id: 104,
    value: "Poke",
  },
  {
    id: 105,
    value: "Chocolate",
  },
  {
    id: 106,
    value: "Croissant",
  },
  {
    id: 107,
    value: "Arepas",
  },
  {
    id: 108,
    value: "Bunny chow",
  },
  {
    id: 109,
    value: "Pierogi",
  },
  {
    id: 110,
    value: "Donuts",
  },
  {
    id: 111,
    value: "Rendang",
  },
  {
    id: 112,
    value: "Sushi",
  },
  {
    id: 113,
    value: "Ice cream",
  },
  {
    id: 114,
    value: "Duck",
  },
  {
    id: 115,
    value: "Curry",
  },
  {
    id: 116,
    value: "Beef",
  },
  {
    id: 117,
    value: "Goat",
  },
  {
    id: 118,
    value: "Lamb",
  },
  {
    id: 119,
    value: "Turkey",
  },
  {
    id: 120,
    value: "Pork",
  },
  {
    id: 121,
    value: "Fish",
  },
  {
    id: 122,
    value: "Crab",
  },
  {
    id: 123,
    value: "Bacon",
  },
  {
    id: 124,
    value: "Ham",
  },
  {
    id: 125,
    value: "Pepperoni",
  },
  {
    id: 126,
    value: "Salami",
  },
  {
    id: 127,
    value: "Ribs",
  },
];
