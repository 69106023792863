import {
  Button,
  Card,
  EmptyState,
  EmptyStateIcon,
  Flex,
  FlexItem,
  Form,
  FormGroup,
  TextArea,
  Title,
} from "@patternfly/react-core";
import { useContext, useEffect, useState } from "react";
import { createComment, getComments } from "./apis";
import { utilsApiEndpoints } from "../../../apis/endpoints";
import { AlertContext } from "../../../contexts/alert/AlertContext";
import moment from "moment";
import { Comment } from "../../../constants/Process";
import favicon from "../../../assets/favicon.png";
import { CommentsIcon } from "@patternfly/react-icons";
import { isEmpty } from "lodash";
import { Loader } from "../../../components/Loader/Loader";

const Comments = ({ processId }) => {
  const [comment, setComment] = useState("");
  const [processComments, setProcessComments] = useState<Comment[]>([]);
  const alert = useContext(AlertContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (processId) getProcessComments();
  }, []);

  const getProcessComments = () => {
    setIsLoading(true);
    getComments({
      url: utilsApiEndpoints.processComments,
      params: { processId: processId, size: 1000, sort: "-updatedAt" },
      successCallback: (response) => {
        setProcessComments(response);
      },
      failureCallback: (response) => {
        alert.addAlert(response.message, "danger");
      },
    });
    setIsLoading(false);
  };

  const submitComment = (e) => {
    e.preventDefault();
    if (comment.trim() === "") return;
    setIsLoading(true);
    createComment({
      url: utilsApiEndpoints.processComments,
      data: {
        content: comment.trim(),
        processId: processId,
      },
      successCallback: (response) => {
        getProcessComments();
        setComment("");
        alert.addAlert("Comment added successfully", "success");
      },
      failureCallback: (response) => {
        alert.addAlert(response.message, "danger");
      },
    });
  };
  return (
    <>
      <Card
        style={{
          marginTop: "50px",
          padding: "30px",
          paddingBottom: "60px",
        }}
      >
        <h2
          style={{ fontSize: "22px", fontWeight: "600", marginBottom: "20px" }}
        >
          Comments
        </h2>
        <Form onSubmit={submitComment}>
          <FormGroup fieldId="comments">
            <TextArea
              id="comment-textarea"
              placeholder="Type your comment..."
              maxLength={255}
              value={comment}
              onChange={(_event, value) => {
                setComment(value);
              }}
              style={{
                resize: "vertical",
                border: "1px solid #d2d2d2",
                outline: "none",
              }}
              autoResize
            />
            <Button
              type="submit"
              style={{
                margin: "20px 0",
                float: "right",
              }}
              variant="primary"
              isDisabled={comment.trim() === ""}
            >
              Post comment
            </Button>
          </FormGroup>
        </Form>
        {isLoading ? (
          <Loader text="Loading comments" />
        ) : isEmpty(processComments) ? (
          <EmptyState>
            <EmptyStateIcon icon={CommentsIcon} />
            <Title headingLevel="h5" size="lg">
              No Comments yet!
            </Title>
          </EmptyState>
        ) : (
          processComments.map((comment) => {
            return (
              <Card
                style={{
                  marginTop: "20px",
                  padding: "20px 20px",
                  boxShadow: "none",
                  border: "1px solid #d2d2d2",
                  borderLeftColor: "#06c",
                  borderLeftWidth: "4px",
                }}
                key={comment.id}
              >
                <Flex
                  alignItems={{
                    default: "alignItemsCenter",
                  }}
                >
                  <FlexItem>
                    <img
                      height="30px"
                      width="30px"
                      src={favicon}
                      alt="Red Hat"
                    />
                  </FlexItem>
                  <Flex
                    flex={{ default: "flex_4" }}
                    direction={{ default: "column" }}
                  >
                    <FlexItem style={{ marginBottom: 0 }}>
                      <b>{comment.updatedByName || comment.updatedBy}</b>
                    </FlexItem>
                    <FlexItem style={{ fontSize: "12px" }}>
                      {moment
                        .utc(comment?.updatedAt)
                        .local()
                        .format("MMMM D YYYY, h:mm:ss A")}
                    </FlexItem>
                  </Flex>
                </Flex>
                <FlexItem style={{ marginTop: "10px" }}>
                  {comment.content}
                </FlexItem>
              </Card>
            );
          })
        )}
      </Card>
    </>
  );
};

export default Comments;
