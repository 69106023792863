import {
  Button,
  Grid,
  GridItem,
  Form as PFForm,
  ActionGroup,
  FormGroup,
  PageSection,
  PageSectionVariants,
  TextContent,
  Text,
  Checkbox,
  HintTitle,
  Hint,
  HintBody,
} from "@patternfly/react-core";
import { useContext, useEffect, useState } from "react";
import {
  RadioInput,
  BasicTextInput,
  DropdownInput,
} from "../../../components/InputFields";
import { useHistory } from "react-router-dom";
import { AlertContext } from "../../../contexts/alert/AlertContext";
import VALIDATION from "../../../constants/validation";
import {
  EVF_INPUT_FIELDS,
  PARTNER_TYPE,
  TYPE_OF_OPPORTUNITY,
  DISCLAIMER,
  SELL_WITH_OPTIONS,
  HELPER_TEXT,
} from "../../../constants/CreateEVF";
import {
  createEVS,
  getOpportunityDetails,
  getUserEmails,
} from "../CreateProcess/apis";
import { utilsApiEndpoints } from "../../../apis/endpoints";
import { getValueByFieldType } from "../../../utils";
import { ChevronLeftIcon } from "@patternfly/react-icons";
import { debounce } from "lodash";
import { useAuthContext } from "../../../contexts/authentication/AuthenticationContext";

interface CheckboxItem {
  name: string;
  isChecked: boolean;
}

const CreateEVS = () => {
  const history = useHistory();
  const { user } = useAuthContext();
  const alert = useContext(AlertContext);
  const parse = require("html-react-parser");
  const [sellWithOptions, setSellWithOptions] = useState<
    {
      label: string;
      value: string;
    }[]
  >(SELL_WITH_OPTIONS.ISV);

  const [partnerNameList, setPartnerNameList] = useState<CheckboxItem[]>([]);

  const [forecastProductGroupList, setForecastProductGroupList] = useState<
    CheckboxItem[]
  >([]);

  const [partnerType, setPartnerType] = useState<{
    label: string;
    value: string;
  }>(PARTNER_TYPE[0]);

  const [typeOfOpportunity, setTypeOfOpportunity] = useState<{
    label: string;
    value: string;
  }>(TYPE_OF_OPPORTUNITY[0]);

  const [formValues, setFormValues] = useState({
    opportunityNumber: "",
    typeOfOpportunity: "New",
    partnerName: "",
    partnerType: "ISV",
    partialInfluence: "",
    cosellCriteria: "",
    cosellDescription: "",
    sapBdmApproved: false,
    disclaimerAccepted: false,
    notifyEmails: [],
    evidenceOfPartnerSellWithActivity: "",
  });

  const [preFilledFormValues, setPreFilledFormValues] = useState({
    superRegion: "",
    region: "",
    subRegion: "",
    opportunityId: "",
    opportunityNumber: "",
    opportunityName: "",
    globalCustomerName: "",
    forecastProductGroupList: "",
    influencingPartnerList: "",
    closeDate: "",
    fiscalYearQuarter: "",
    actualSybUsed: "",
    financialPartnerName: "",
    financialPartnerType: "",
    resellerPartnerName: "",
    resellerPartnerType: "",
  });
  const [isOpen, setIsOpen] = useState(false);
  const [selectedNotifyEmails, setSelected] = useState<any[]>([]);
  const [isPastCloseDate, setIsPastCloseDate] = useState(false);

  const [notifyEmailList, setNotifyEmailList] = useState([]);

  const optionalFields = [
    "notifyEmails",
    "evidenceOfPartnerSellWithActivity",
    "sapBdmApproved",
  ];

  const [cosellDescriptionHelperText, setCoSellDescriptionHelperText] =
    useState("");

  useEffect(() => {
    setSellWithOptions(SELL_WITH_OPTIONS[formValues.partnerType]);
  }, [formValues.partnerType]);

  useEffect(() => {
    if (formValues.cosellCriteria.length > 0) {
      const helpText = HELPER_TEXT.find(
        (text) => text.label === formValues.cosellCriteria
      );
      setCoSellDescriptionHelperText(helpText.value);
    }
  }, [formValues.cosellCriteria]);

  useEffect(() => {
    if (formValues.opportunityNumber.length >= 7) {
      getOpportunityDetails({
        dataToSend: {
          evs: {
            opportunityNumber: formValues.opportunityNumber,
          },
        },
        successCallback: (data) => {
          let opportunity = data?.evs?.opportunity;
          setPreFilledFormValues({
            ...preFilledFormValues,
            superRegion: opportunity?.superRegion,
            region: opportunity?.region,
            subRegion: opportunity?.subRegion,
            opportunityId: opportunity?.opportunityId,
            opportunityNumber: opportunity?.opportunityNumber,
            opportunityName: opportunity?.opportunityName,
            globalCustomerName: opportunity?.globalCustomerName,
            forecastProductGroupList: opportunity?.forecastProductGroupList,
            influencingPartnerList: opportunity?.influencingPartnerList,
            closeDate: opportunity?.closeDate.join("-"),
            fiscalYearQuarter: opportunity?.fiscalYearQuarter,
            actualSybUsed: opportunity?.actualSybUsed,
            financialPartnerName: opportunity?.financialPartnerName,
            financialPartnerType: opportunity?.financialPartnerType,
            resellerPartnerName: opportunity?.resellerPartnerName,
            resellerPartnerType: opportunity?.resellerPartnerType,
          });

          const _partnerNameList = getCheckboxItemsFromString(
            opportunity?.influencingPartnerList
          );
          setPartnerNameList(_partnerNameList);

          const _forecastProductGroupList = getCheckboxItemsFromString(
            opportunity?.forecastProductGroupList
          );
          setForecastProductGroupList(_forecastProductGroupList);
        },
        failureCallback: () => {},
      });
    }
  }, [formValues.opportunityNumber]);

  const getCheckboxItemsFromString = (text: string): CheckboxItem[] => {
    if (text.length === 0) return [];

    const listAfterSplit = text.split(", ");
    const checkboxItemsList = listAfterSplit.map((item: string) => {
      return {
        name: item,
        isChecked: true,
      };
    });

    return checkboxItemsList;
  };

  const checkboxSelectedItemsToString = (list: CheckboxItem[]): string => {
    const _list = [];
    list.forEach((item) => {
      if (item.isChecked) _list.push(item.name);
    });
    return _list.join(", ");
  };

  const handleCheckboxChange = (
    event: React.FormEvent<HTMLInputElement>,
    checked: boolean,
    item: string,
    component: string
  ) => {
    if (component === "forecastProductGroup") {
      const _forecastProductGroupList = forecastProductGroupList.map(
        (partner) =>
          partner.name === item ? { name: item, isChecked: checked } : partner
      );
      setForecastProductGroupList(_forecastProductGroupList);
    } else {
      const _partnerNameList = partnerNameList.map((partner) =>
        partner.name === item ? { name: item, isChecked: checked } : partner
      );
      setPartnerNameList(_partnerNameList);
    }
  };

  const onSubmit = (value: any) => {
    value.preventDefault();

    var _formValues = {
      ...formValues,
      partnerName: checkboxSelectedItemsToString(partnerNameList),
      partialInfluence: checkboxSelectedItemsToString(forecastProductGroupList),
      notifyEmails: selectedNotifyEmails,
    };

    setFormValues(_formValues);
    let isValid = Object.entries(_formValues).every(([key, value]) => {
      if (!optionalFields.includes(key)) {
        if (typeof value === "string") {
          return value.length > 0;
        } else if (Array.isArray(value)) {
          return value.length > 0;
        } else if (typeof value === "boolean") {
          return value;
        }
      } else {
        return true; // Skip validation for non-required fields
      }
    });

    if (!isValid) {
      alert.addAlert("Please fill all the required fields", "danger");
      return;
    }
    if (isValid) {
      createEVS({
        dataToSend: {
          evs: {
            ..._formValues,
            ...preFilledFormValues,
            createdByEmail: user?.email,
            createdBy: user?.name,
          },
        },
        params: {
          businessKey: _formValues.opportunityNumber,
        },
        successCallback: () => {
          alert.addAlert("EVF request submitted.", "success");
          history.push("/processes/evs");
        },
        failureCallback: () => {},
      });
    } else {
      alert.addAlert("Please fill all the required fields.", "danger");
    }
  };

  const [showBusinessReasonField, setShowBusinessReasonField] = useState(false);

  useEffect(() => {
    const date = new Date(preFilledFormValues.closeDate);
    const today = new Date();
    const timeDiff = date.getTime() - today.getTime();
    const daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24));
    setShowBusinessReasonField(daysDiff < 30);
    setIsPastCloseDate(date < today);
  }, [preFilledFormValues.closeDate]);

  return (
    <>
      <PageSection variant={PageSectionVariants.light}>
        <Grid>
          <GridItem span={8} style={{ display: "flex" }}>
            <button
              type="button"
              className="back-to-list-button"
              onClick={() => history.push("/processes/evs/")}
            >
              <ChevronLeftIcon height="14px" />
            </button>
            <TextContent>
              <Text component="h1">Create Form</Text>
            </TextContent>
          </GridItem>
        </Grid>
      </PageSection>
      <div style={{ margin: 20 }}>
        <PFForm
          onSubmit={onSubmit}
          style={{
            padding: "10px 40px",
            background: "white",
            height: "100%",
          }}
        >
          <BasicTextInput
            validation={(value) => {
              return VALIDATION.length(value, 7, 18);
            }}
            maxLength={18}
            isRequired={true}
            id="opportunityNumber"
            label="Opportunity Number"
            value={formValues.opportunityNumber}
            setValue={(value) => {
              setFormValues({ ...formValues, opportunityNumber: value });
            }}
          />
          {/* SFDC CONSTANT FIELDS */}
          <Grid hasGutter style={{ marginTop: "15px" }}>
            {EVF_INPUT_FIELDS.map((input, key) => {
              return (
                <GridItem key={key} span={6}>
                  <BasicTextInput
                    value={
                      input?.isCurrency
                        ? getValueByFieldType(
                            "currency",
                            preFilledFormValues[input.id]
                          )
                        : preFilledFormValues[input.id]
                    }
                    isDisabled={input.isDisabled}
                    id={input.id}
                    label={input.name}
                    setValue={(value) => {
                      setPreFilledFormValues({
                        ...preFilledFormValues,
                        [input.id]: value,
                      });
                    }}
                  />
                </GridItem>
              );
            })}
          </Grid>
          {/* Business reason since less than 30 days close day*/}
          {showBusinessReasonField && (
            <BasicTextInput
              label="Business Reason"
              id="businessReason"
              helperText="Opportunity Close Date is within 30 days of today, please give a business reason for this"
              value={formValues.evidenceOfPartnerSellWithActivity}
              isRequired={true}
              setValue={(value) => {
                setFormValues({
                  ...formValues,
                  evidenceOfPartnerSellWithActivity: value,
                });
              }}
            />
          )}
          {/* Type of Opportunity */}
          <RadioInput
            style={{ marginTop: "10px" }}
            id="typeOfOpportunity"
            isRequired={true}
            options={TYPE_OF_OPPORTUNITY}
            selectedValue={typeOfOpportunity}
            label="Is this a new or renewal opportunity?"
            setSelectedValue={(value) => {
              setTypeOfOpportunity(value);
              setFormValues({
                ...formValues,
                typeOfOpportunity: value.value,
              });
            }}
          />
          {/* Forecast Product Group(s) */}
          <Grid hasGutter style={{ marginTop: "15px" }}>
            <FormGroup
              isRequired={true}
              label="Select the applicable Forecast Product Group(s)?"
            >
              {forecastProductGroupList.length > 0 ? (
                forecastProductGroupList.map((item, key) => {
                  return (
                    <GridItem key={key} span={6} style={{ marginTop: "15px" }}>
                      <Checkbox
                        value={item.name}
                        id={item.name}
                        label={item.name}
                        onChange={(
                          event: React.FormEvent<HTMLInputElement>,
                          checked: boolean
                        ) =>
                          handleCheckboxChange(
                            event,
                            checked,
                            item.name,
                            "forecastProductGroup"
                          )
                        }
                        isChecked={item.isChecked}
                      />
                    </GridItem>
                  );
                })
              ) : (
                <></>
              )}
            </FormGroup>
          </Grid>
          {/* Partner Name field */}
          <Grid hasGutter style={{ marginTop: "15px" }}>
            <FormGroup
              fieldId="partnerName"
              isRequired={true}
              label="Partner Name"
            >
              {partnerNameList.length > 0 ? (
                partnerNameList.map((item, key) => {
                  return (
                    <GridItem key={key} span={6} style={{ marginTop: "10px" }}>
                      <Checkbox
                        value={item.name}
                        id={item.name}
                        label={item.name}
                        onChange={(
                          event: React.FormEvent<HTMLInputElement>,
                          checked: boolean
                        ) =>
                          handleCheckboxChange(
                            event,
                            checked,
                            item.name,
                            "partnerType"
                          )
                        }
                        isChecked={item.isChecked}
                      />
                    </GridItem>
                  );
                })
              ) : (
                <TextContent>
                  <Text component="p" style={{ color: "#FF0000" }}>
                    Please add partners in SFDC
                  </Text>
                </TextContent>
              )}
            </FormGroup>
          </Grid>
          {/* Partner Type */}
          <GridItem span={6}>
            <DropdownInput
              isRequired={true}
              style={{ width: "100%" }}
              value={partnerType}
              setValue={(value) => {
                setPartnerType(value);
                setFormValues({ ...formValues, partnerType: value.value });
              }}
              placeholder="Select Partner Type"
              label="Partner Type / Vertical Partner"
              id="projectId"
              dropdownItems={PARTNER_TYPE || []}
            />
          </GridItem>
          {/* “SAP” Partner Types “SAP BDM  APPROVED”. */}
          {formValues.partnerType === "SAP" && (
            <Checkbox
              isChecked={formValues.sapBdmApproved}
              id="sapBdmApproved"
              isRequired={true}
              onChange={(
                event: React.FormEvent<HTMLInputElement>,
                checked: boolean
              ) => {
                setFormValues({
                  ...formValues,
                  sapBdmApproved: checked,
                });
              }}
              label="SAP BDM Approved"
            />
          )}
          {/* Sell-with */}
          <FormGroup>
            <RadioInput
              style={{ marginTop: "10px" }}
              id="sellWith"
              options={sellWithOptions}
              isRequired={true}
              selectedValue={{
                label: formValues.cosellCriteria,
                value: formValues.cosellCriteria,
              }}
              label="Sell-with Use Cases"
              setSelectedValue={(value) => {
                setFormValues({ ...formValues, cosellCriteria: value.value });
              }}
            />
          </FormGroup>
          {/* Description of Co-sell activity */}
          <FormGroup
            label="Description of Sell-with activity"
            isRequired
            labelIcon={
              formValues.cosellCriteria.length > 0 && (
                <Hint>
                  <HintTitle>Evidence Criteria</HintTitle>
                  <HintBody>{parse(cosellDescriptionHelperText)}</HintBody>
                </Hint>
              )
            }
          >
            <BasicTextInput
              helperText="Please look at the justification criteria before giving the description"
              id="evidence"
              value={formValues.cosellDescription}
              setValue={(value) => {
                setFormValues({ ...formValues, cosellDescription: value });
              }}
              isRequired={true}
            />
          </FormGroup>
          {/* Disclaimer */}
          <TextContent>
            <Text component="h5">Disclaimer: </Text>
            <Text component="p">{DISCLAIMER}</Text>
          </TextContent>
          {/* Disclaimer Confirmation */}
          <Checkbox
            isChecked={formValues.disclaimerAccepted}
            id="disclaimerAccepted"
            isRequired={true}
            onChange={(
              event: React.FormEvent<HTMLInputElement>,
              checked: boolean
            ) => {
              setFormValues({
                ...formValues,
                disclaimerAccepted: checked,
              });
            }}
            label="I confirm"
          />
          <ActionGroup
            style={{
              marginBottom: 50,
            }}
          >
            <Button
              type="submit"
              style={{ fontWeight: "500" }}
              variant="primary"
              isDisabled={isPastCloseDate}
            >
              Create
            </Button>
            {isPastCloseDate && (
              <span style={{ color: "red", margin: "auto", marginLeft: 0 }}>
                EVF creation is not possible since the close date precedes the
                current date.
              </span>
            )}
          </ActionGroup>
        </PFForm>
      </div>
    </>
  );
};

export default CreateEVS;
