import { PROCESSES_META, ProcessesMetaInterface } from "../constants/Processes";

export const getProcessType = (): string => {
  const processType = JSON.parse(localStorage.getItem("processType"));
  if (!processType) {
    localStorage.setItem("processType", JSON.stringify("pcf"));
  }
  return processType;
};

export const updateProcessType = (processType: string): string => {
  localStorage.setItem("processType", JSON.stringify(processType));

  return getProcessType();
};

export const getConversationId = (): string => {
  return JSON.parse(localStorage.getItem("conversationId"));
};

export const updateConversationId = (conversationId: string): string => {
  localStorage.setItem("conversationId", JSON.stringify(conversationId));

  return getConversationId();
};

export const getLastRHSCPageVisit = (): string => {
  return JSON.parse(localStorage.getItem("lastRhsc"));
};

export const updateLastRHSCPageVisit = (page: string): string => {
  localStorage.setItem("lastRhsc", JSON.stringify(page));

  return getLastRHSCPageVisit();
};

export const getCurrentProcessMeta = (): ProcessesMetaInterface => {
  return PROCESSES_META.find(
    (processMeta: ProcessesMetaInterface) =>
      getProcessType() === processMeta.code
  );
};

export const getValueByFieldType = (type: string, value: any) => {
  const usdFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  switch (type) {
    case "currency":
      return usdFormatter.format(value);
    case "arrayDate":
      return value.join("-");
    case "arrayString":
      return value.join(", ");
    case "timestamp":
      return new Date(value).toDateString();
    case "url":
      return `<a href="${value}" target="_blank">Link</a>`;
    case "image":
      return `<img src=${value} alt="img" width="400" />`;
    case "googleDriveFolderId":
      return `<a href="https://drive.google.com/drive/folders/${value}" target="_blank">Link</a>`;
    case "googleDocId":
      return `<a href="https://docs.google.com/document/d/${value}" target="_blank">Link</a>`;
    default:
      return value;
  }
};
