import apiClient from "../../../apis/api-client";
import { PCA_API_BASE_URL, pcaApiEndpoints } from "../../../apis/endpoints";
import { ProcessAccessor } from "../../../constants/ProcessAccess";

interface RequestProps {
  dataToSend: any;
  successCallback?: (data) => void;
  failureCallback?: () => void;
  params?: any;
}

const getAccess = async (
  props: RequestProps,
  processAccess: ProcessAccessor
) => {
  const { dataToSend, successCallback, failureCallback } = props;
  const response = await apiClient(processAccess.baseurl).get(
    processAccess.endpoint
  );
  if (response.status === 201 || response.status === 200) {
    successCallback && successCallback(response.data);
  } else {
    failureCallback && failureCallback();
  }
};

const getProjects = async (props: RequestProps) => {
  const { dataToSend, successCallback, failureCallback } = props;
  const response = await apiClient(PCA_API_BASE_URL).post(
    pcaApiEndpoints.enrichProject,
    dataToSend
  );
  if (response.status === 201) {
    successCallback && successCallback(response.data.projectIds);
  } else {
    failureCallback && failureCallback();
  }
};
const getProjectDetails = async (props: RequestProps) => {
  const { dataToSend, successCallback, failureCallback } = props;
  const response = await apiClient(PCA_API_BASE_URL).post(
    pcaApiEndpoints.enrichPca,
    dataToSend
  );
  if (response.status === 201) {
    successCallback && successCallback(response.data);
  } else {
    failureCallback && failureCallback();
  }
};

const createPca = async (props: RequestProps) => {
  const { dataToSend, successCallback, failureCallback, params } = props;
  const response = await apiClient(PCA_API_BASE_URL).post(
    pcaApiEndpoints.pca,
    dataToSend,
    {
      params,
    }
  );
  if (response.status === 201) {
    successCallback && successCallback(response.data);
  } else {
    failureCallback && failureCallback();
  }
};
export { getProjects, getProjectDetails, createPca, getAccess };
