import "./style.scss";

const UserMessage = ({ message }) => {
  return (
    <div className="parent-container">
      <div className="user-message-wrapper">{message}</div>
    </div>
  );
};

export default UserMessage;
