export enum STATUS {
  READY = "Ready",
  COMPLETED = "Completed",
  RESERVED = "Reserved",
  ABORT = "Abort",
  SKIP = "Skip",
}

export enum TASK_FILTER_CATEGORY {
  STATUS = "Status",
  SEARCH_KEY = "Task Name",
}

export interface Filter {
  status: string[];
  searchKey?: string[];
}

export const TASK_CONTROL_OBJECT = {
  meeting: {
    values: {
      reason: "",
    },
    fields: [
      {
        id: "reason",
        label: "Explain your action.",
        type: "textarea",
        isRequired: true,
      },
    ],
  },
  productnamecheck: {
    values: {
      productAdded: false,
      productName: "",
      jiraRequired: [],
    },
    fields: [
      {
        id: "productAdded",
        label: "Add Product Name",
        type: "checkbox",
        isRequired: true,
        disableFormLabel: true,
        description: "Opt it to enable the fields given below.",
      },
      {
        id: "productName",
        label: "Product Name",
        type: "text",
        isRequired: true,
        disabled: "productAdded",
      },
      {
        id: "jiraRequired",
        label: "Open Jira for",
        type: "multiCheckbox",
        isRequired: true,
        disabled: "productAdded",
        options: [
          { id: "cpccmRequired", label: "CPCCM" },
          { id: "cppxRequired", label: "CPPX" },
        ],
      },
    ],
  },
  populatesbr: {
    values: {
      sbrTeam: "",
    },
    fields: [
      {
        id: "sbrTeam",
        label: "SBR Team Name",
        type: "selectWithCreate",
        isRequired: true,
      },
    ],
  },
};

export const TYPE_OF_APPROVALS = [
  {
    label: "PA Approval",
    value: "paApprovalRequired",
  },
  {
    label: "Revenue Approval",
    value: "revenueApprovalRequired",
  },
  {
    label: "Service Approval",
    value: "serviceApprovalRequired",
  },
];
