import {
  Button,
  Modal as PatternFlyModal,
  ModalVariant,
} from "@patternfly/react-core";

interface ModalProps {
  variant: "small" | "medium" | "large" | "default";
  title: string;
  description: string;
  isModalOpen: boolean;
  handleModalToggle: () => void;
  handleModalSubmit: () => void;
  children: React.ReactNode;
}

const Modal = (props: ModalProps): JSX.Element => {
  return (
    <PatternFlyModal
      variant={ModalVariant[props.variant]}
      title={props.title}
      description={props.description}
      isOpen={props.isModalOpen}
      onClose={props.handleModalToggle}
      actions={[
        <Button
          key="create"
          variant="primary"
          form="modal-with-form-form"
          onClick={props.handleModalSubmit}
        >
          Save
        </Button>,
        <Button key="cancel" variant="link" onClick={props.handleModalToggle}>
          Cancel
        </Button>,
      ]}
    >
      {props.children}
    </PatternFlyModal>
  );
};

export default Modal;
