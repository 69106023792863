import { Flex, FlexItem, Switch } from "@patternfly/react-core";
import "./settings.scss";
import { User } from "../../constants/User";
import { FormEvent } from "react";

const Settings = (props: {
  userData: User;
  setUserData: React.Dispatch<React.SetStateAction<User>>;
}) => {
  const { userData, setUserData } = props;

  const onChangeNotificationSwitch = (
    e: FormEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const target = e.target as HTMLInputElement;
    setUserData({ ...userData, [target.id]: checked });
  };

  return (
    <div className="settings-modal">
      <hr />
      <Flex style={{ marginTop: 20 }}>
        <Flex
          direction={{ default: "column" }}
          alignSelf={{ default: "alignSelfCenter" }}
        >
          <FlexItem style={{ margin: 0, fontWeight: "bold" }}>
            {userData?.name}
          </FlexItem>
          <FlexItem>{userData?.email}</FlexItem>
        </Flex>
      </Flex>
      <Flex direction={{ default: "column" }} style={{ marginTop: 20 }}>
        <Switch
          id="gchatNotificationEnabled"
          label="G-Chat Notification"
          isChecked={userData.gchatNotificationEnabled}
          onChange={onChangeNotificationSwitch}
          isReversed
        />
        <Switch
          id="emailNotificationEnabled"
          label="Email Notification"
          isChecked={userData.emailNotificationEnabled}
          onChange={onChangeNotificationSwitch}
          isReversed
        />
        <Switch
          id="slackNotificationEnabled"
          label="Slack Notification"
          isChecked={userData.slackNotificationEnabled}
          onChange={onChangeNotificationSwitch}
          isReversed
        />
      </Flex>
    </div>
  );
};

export default Settings;
