import apiClient from "../../../apis/api-client";

interface RequestProps {
  url: string;
  data?: any;
  params?: any;
  successCallback?: (response) => void;
  failureCallback?: (response) => void;
}

const getProcessSVG = async (props: RequestProps) => {
  const { url, successCallback, failureCallback } = props;
  const response = await apiClient(url).get(url);

  if (response.status === 200) {
    successCallback && successCallback(response);
  } else {
    failureCallback && failureCallback(response);
  }
};

const getProcessRules = async (props: RequestProps) => {
  const { url, successCallback, failureCallback } = props;
  const response = await apiClient(url).get(url);

  if (response.status === 200) {
    successCallback && successCallback(response);
  } else {
    failureCallback && failureCallback(response);
  }
};

export { getProcessSVG, getProcessRules };
