import { accountPlanningEndpoints } from "../../../apis/endpoints";
import accountPlanningApiClient from "../../../apis/account-planning-pa/api-client";

interface RequestProps {
  url?: string;
  dataToSend?: any;
  successCallback?: (data) => void;
  failureCallback?: (data) => void;
  params?: any;
}

const getAccountPlanBySourceRecordID = async (props: RequestProps) => {
  const { dataToSend, successCallback, failureCallback } = props;
  const response = await accountPlanningApiClient.post(
    accountPlanningEndpoints.enrichAccountPlan,
    dataToSend
  );
  if (response.status === 201) {
    successCallback && successCallback(response.data);
  } else {
    failureCallback && failureCallback(response);
  }
};

const createAccountPlan = async (props: RequestProps) => {
  const { dataToSend, successCallback, failureCallback } = props;
  const response = await accountPlanningApiClient.post(
    accountPlanningEndpoints.accountPlanning,
    dataToSend
  );
  if (response.status === 201) {
    successCallback && successCallback(response.data);
  } else {
    failureCallback && failureCallback(response);
  }
};

const changeTaskState = async (props: RequestProps) => {
  const { url, dataToSend, params, successCallback, failureCallback } = props;
  const response = await accountPlanningApiClient.post(url, dataToSend, {
    params,
  });

  if (response.status === 200) {
    successCallback && successCallback(response.data);
  } else {
    failureCallback && failureCallback(response);
  }
};

export { getAccountPlanBySourceRecordID, createAccountPlan, changeTaskState };
