import {
  Button,
  EmptyState,
  EmptyStateIcon,
  Flex,
  Form,
  FormGroup,
  FormSelect,
  FormSelectOption,
  Skeleton,
  Tab,
  TabTitleText,
  Tabs,
  Text,
  TextContent,
  TextVariants,
  Title,
} from "@patternfly/react-core";
import { useContext, useEffect, useState } from "react";
import { BasicTextInput } from "../../../components/InputFields";
import { AlertContext } from "../../../contexts/alert/AlertContext";
import { createAccountPlan, getAccountPlanBySourceRecordID } from "./apis";
import client from "../../../graphql/client";
import { createHttpLink, useQuery } from "@apollo/client";
import {
  ACCOUNT_PLANNING_PA_API_BASE_URL,
  utilsApiEndpoints,
} from "../../../apis/endpoints";
import { GET_TASKS_LIST } from "../../../graphql/queries/Task";
import TaskListItem from "../TaskListItem";
import "../style.scss";
import { debounce } from "lodash";
import { getUserEmails } from "../../EVS/CreateProcess/apis";
import { SearchIcon } from "@patternfly/react-icons";
import { ACCOUNT_PLANNING_TYPES } from "../../../constants/AccountPlanning";
import { getAuthContext } from "../../../graphql/auth";
import {
  SelectVariant,
  Select,
  SelectOption,
} from "@patternfly/react-core/deprecated";
import { useAuthContext } from "../../../contexts/authentication/AuthenticationContext";

function AccountPlanning() {
  const alert = useContext(AlertContext);
  const { user } = useAuthContext();

  const INITIAL_VALUES = {
    accountPlanType: "red_hat_sales",
    sourceRecordID: "",
    reviewerEmail: "",
  };
  const [taskList, setTaskList] = useState([]);
  const [formValues, setFormValues] = useState(INITIAL_VALUES);
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [accountPlanMeta, setAccountPlanMeta] = useState(null);
  const [isReviewerEmailOpen, setIsReviewerEmailOpen] = useState(false);
  const [reviewerEmailList, setReviewerEmailList] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState<string | number>(0);
  const [accountPlanningTypes, setAccountPlanningTypes] = useState(
    ACCOUNT_PLANNING_TYPES
  );

  useEffect(() => {
    client.setLink(
      createHttpLink({ uri: `${ACCOUNT_PLANNING_PA_API_BASE_URL}/graphql` })
    );
  }, []);

  const { loading, error, data, refetch } = useQuery(GET_TASKS_LIST, {
    fetchPolicy: "network-only",
    context: getAuthContext(),
    variables: {
      where: {
        state: { in: ["Ready", "Reserved"] },
        processId: { equal: "accountPlanning" },
      },
      orderBy: { started: "DESC" },
      offset: 0,
      limit: 1000,
    },
    client: client,
  });

  useEffect(() => {
    if (data && data.UserTaskInstances) {
      const _tasksList = data.UserTaskInstances.filter((userTaskInstance) => {
        const taskInput = JSON.parse(userTaskInstance.inputs).accountPlanReview;
        return taskInput.approverEmail === user?.email;
      });
      setTaskList(_tasksList);
    }
  }, [data]);

  const handleTabClick = (
    event: React.MouseEvent<any> | React.KeyboardEvent | MouseEvent,
    tabIndex: string | number
  ) => {
    if (tabIndex == 1) refetch();
    setActiveTabKey(tabIndex);
  };

  const handleSubmitAccountPlan = () => {
    if (formValues.reviewerEmail.trim().length === 0) {
      alert.addAlert("Enter all the mandatory fields.", "danger");
      return;
    }

    if (formValues.reviewerEmail === user?.email) {
      alert.addAlert("You can't set yourself as Reviewer.", "danger");
      return;
    }

    if (accountPlanMeta) {
      setIsSubmitEnabled(false);
      let accountPlan = {
        accountPlan: {
          accountPlanMeta: accountPlanMeta,
          createdBy: user?.email,
          sourceRecordID: formValues.sourceRecordID,
          accountName: accountPlanMeta.accountName,
          createdAt: new Date(),
          approverEmail: formValues.reviewerEmail,
          accountPlanType: formValues.accountPlanType,
        },
      };

      createAccountPlan({
        dataToSend: accountPlan,
        successCallback: (data) => {
          setFormValues(INITIAL_VALUES);
          setAccountPlanMeta(null);
          alert.addAlert(
            "Account Plan has been successfully sent for review!",
            "success"
          );
        },
        failureCallback: (data) => {
          alert.addAlert(
            data?.response?.data?.message || "Something went wrong.",
            "danger"
          );
        },
      });
    }
  };

  const handleSearchAccountPlan = async (event) => {
    setAccountPlanMeta(null);
    event.preventDefault();
    event.stopPropagation();
    if (event.key === "Enter") {
      setIsSubmitEnabled(true);

      const _accountPlanMeta = {
        accountPlanMeta: {
          sourceRecordID: formValues.sourceRecordID,
        },
      };
      getAccountPlanBySourceRecordID({
        dataToSend: _accountPlanMeta,
        successCallback: (data) => {
          setAccountPlanMeta(data.accountPlanMeta);
          if (!data.accountPlanMeta) {
            alert.addAlert(
              `No Account Plan found for ${formValues.sourceRecordID}`,
              "danger"
            );
            return;
          }
          const _accountPlanningTypes = ACCOUNT_PLANNING_TYPES.filter(
            (type) => data.accountPlanMeta[type.value]
          );

          setAccountPlanningTypes(_accountPlanningTypes);
        },
        failureCallback: (data) => {
          alert.addAlert(
            data?.response?.data?.message || "Something went wrong.",
            "danger"
          );
        },
      });

      setIsSubmitEnabled(false);
    }
  };

  const debouncedNotifyEmails = debounce(async (value: string) => {
    getUserEmails({
      url: utilsApiEndpoints.roverEmail,
      params: { searchKey: value },
      successCallback: (data) => {
        const emailDropdownValues = data.map((item) => {
          return {
            businessCardTitle: item?.businessCardTitle,
            email: item?.email,
            name: item?.name,
            rhatLocation: item?.rhatLocation,
            uid: item?.uid,
            uuid: item?.uuid,
            disabled: false,
          };
        });

        setReviewerEmailList(emailDropdownValues);
      },
    });
  }, 500);

  const onToggle = (isOpen, value) => {
    setIsReviewerEmailOpen(isOpen);
  };

  const onSelect = (event, selection) => {
    setFormValues({ ...formValues, reviewerEmail: selection });
    setIsReviewerEmailOpen(false);
  };

  const clearSelection = (value) => {
    setReviewerEmailList([]);
    setIsReviewerEmailOpen(false);
    setFormValues({ ...formValues, reviewerEmail: "" });
  };

  const onEmailChange = (value) => {
    if (value.length === 0) {
      setReviewerEmailList([]);
      return;
    }
    if (value.length >= 2) debouncedNotifyEmails(value);
  };

  return (
    <div style={{ padding: "7px" }}>
      <TextContent>
        <Text component={TextVariants.h2}>Account Planning</Text>
      </TextContent>
      <Tabs
        isFilled
        activeKey={activeTabKey}
        onSelect={handleTabClick}
        aria-label="Account planning tabs"
        role="account planning"
      >
        <Tab
          eventKey={0}
          title={<TabTitleText>Create</TabTitleText>}
          aria-label="Tabs filled example content users"
        >
          <div style={{ marginTop: "20px" }}>
            <Form isHorizontal={true}>
              <BasicTextInput
                placeholder="Enter Source Record ID"
                helperText="Press 'Enter' to search account plan"
                isRequired={true}
                id="sourceRecordID"
                label="Source Record ID"
                value={formValues.sourceRecordID}
                setValue={(value) => {
                  setFormValues({ ...formValues, sourceRecordID: value });
                }}
                onKeyUp={handleSearchAccountPlan}
              />
              <FormGroup
                label="Account Plan Type"
                fieldId="account-plan-type"
                isRequired
              >
                <FormSelect
                  value={formValues.accountPlanType}
                  onChange={(
                    _event: React.FormEvent<HTMLSelectElement>,
                    value: string
                  ) => {
                    setFormValues({ ...formValues, accountPlanType: value });
                  }}
                  id="select-account-plan-type"
                  name="select-account-plan-type"
                  aria-label="Select Account Plan Type"
                  isDisabled={accountPlanMeta === null}
                >
                  {accountPlanningTypes.map((option, index) => (
                    <FormSelectOption
                      key={index}
                      value={option.value}
                      label={option.label}
                    />
                  ))}
                </FormSelect>
              </FormGroup>

              {isSubmitEnabled ? (
                <Skeleton screenreaderText="Loading contents" />
              ) : accountPlanMeta !== null ? (
                <span>
                  <div className="task-item-row">
                    <span className="task-item-key">Source Record ID: </span>
                    {accountPlanMeta?.sourceRecordID}
                  </div>
                  <div className="task-item-row">
                    <span className="task-item-key">Account Name: </span>
                    {accountPlanMeta?.accountName}
                  </div>
                </span>
              ) : (
                ""
              )}
              <div className="reviewer-email-dropdown">
                <FormGroup
                  label="Reviewer"
                  isRequired={true}
                  id="reviewer-email-dropdown-id"
                >
                  <Select
                    variant={SelectVariant.typeahead}
                    typeAheadAriaLabel="Search by email or name"
                    onToggle={onToggle}
                    required={true}
                    onSelect={onSelect}
                    onClear={clearSelection}
                    selections={formValues.reviewerEmail}
                    onTypeaheadInputChanged={(value) => onEmailChange(value)}
                    isOpen={isReviewerEmailOpen}
                    placeholderText="Search by email or name"
                    aria-labelledby="multipleEmailsSelect"
                  >
                    {reviewerEmailList &&
                      reviewerEmailList.map((option, index) => (
                        <SelectOption
                          isDisabled={option.disabled}
                          key={index}
                          value={option.email}
                          {...(option.name && {
                            description: option.name,
                          })}
                        />
                      ))}
                  </Select>
                </FormGroup>
              </div>
              <Flex>
                <Button
                  type="button"
                  variant="primary"
                  isDisabled={isSubmitEnabled || accountPlanMeta === null}
                  onClick={handleSubmitAccountPlan}
                >
                  Submit
                </Button>
              </Flex>
            </Form>
          </div>
        </Tab>
        <Tab eventKey={1} title={<TabTitleText>Review</TabTitleText>}>
          <div style={{ marginTop: "20px" }}>
            {loading ? (
              <>
                <Skeleton screenreaderText="Loading contents" />
              </>
            ) : taskList && taskList.length ? (
              <>
                {!loading &&
                  taskList.length &&
                  taskList.map((task) => (
                    <TaskListItem task={task} key={task.started} />
                  ))}
              </>
            ) : (
              <EmptyState>
                <EmptyStateIcon icon={SearchIcon} />
                <Title headingLevel="h5" size="md">
                  No results found
                </Title>
              </EmptyState>
            )}
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}

export default AccountPlanning;
