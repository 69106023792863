import { PageSection, PageSectionVariants } from "@patternfly/react-core";
import { ProcessesMetaInterface } from "../../../constants/Processes";
import { getCurrentProcessMeta } from "../../../utils";
import { UnderMaintenance } from "../../../utils/UnderMaintenance";
import Detail from "./Detail";
import { PROCESS_TO_DETAIL_META } from "../../../constants/Process";

const TaskDetails = () => {
  const currentProcessMeta: ProcessesMetaInterface = getCurrentProcessMeta();

  if (currentProcessMeta.isUnderMaintenance) {
    return (
      <PageSection
        style={{ margin: 20, textAlign: "center", padding: "80px 0" }}
        variant={PageSectionVariants.light}
      >
        <UnderMaintenance name={currentProcessMeta.name} />
      </PageSection>
    );
  } else {
    return (
      <Detail
        detailMeta={
          PROCESS_TO_DETAIL_META[currentProcessMeta.code]
        }
      />
    );
  }
};

export default TaskDetails;
