import { createHttpLink, useQuery } from "@apollo/client";
import { useEffect } from "react";
import { ACCOUNT_PLANNING_PA_API_BASE_URL } from "../../../apis/endpoints";
import client from "../../../graphql/client";
import { GET_TASK } from "../../../graphql/queries/Task";
import { useLocation, useParams } from "react-router-dom";
import { Loader } from "../../../components/Loader/Loader";
import RateAccountPlan from "./RateAccountPlan";
import AccountPlanApproval from "./AccountPlanApproval";
import { getAuthContext } from "../../../graphql/auth";

function ReviewAccountPlan() {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const task = queryParams.get("task");

  useEffect(() => {
    client.setLink(
      createHttpLink({ uri: `${ACCOUNT_PLANNING_PA_API_BASE_URL}/graphql` })
    );
  }, []);

  const reviewScreenByTaskType = () => {
    switch (task) {
      case "rate_account_plan":
        return <RateAccountPlan task={data?.UserTaskInstances[0]} />;
      case "account_plan_approval":
        return <AccountPlanApproval task={data?.UserTaskInstances[0]} />;
      default:
        return <div>Default Content</div>;
    }
  };

  const { loading, error, data } = useQuery(GET_TASK, {
    fetchPolicy: "network-only",
    context: getAuthContext(),
    variables: {
      where: { processInstanceId: { equal: id }, name: { equal: task } },
    },
    client: client,
  });

  return (
    <div style={{ padding: "10px" }}>
      {loading ? <Loader /> : reviewScreenByTaskType()}
    </div>
  );
}

export default ReviewAccountPlan;
