import { Flex, FlexItem, FormGroup, Radio } from "@patternfly/react-core";
import "./inputs.scss";

interface RadioInputProps {
  label?: string;
  selectedValue?: { label: string; value: string };
  setSelectedValue?: (
    value: { label: string; value: string },
    id: string
  ) => void;
  options: { label: string; value: string }[];
  isDisabled?: boolean;
  style?: React.CSSProperties;
  id: string;
  isRequired?: boolean;
}

const RadioInput: React.FC<RadioInputProps> = (props) => {
  return (
    <FormGroup
      isRequired={props.isRequired || false}
      label={props.label}
      style={props.style}
    >
      <Flex direction={{ default: "column" }}>
        {props.options.map((option, index) => {
          return (
            <FlexItem style={{ margin: "0 0px 10px 0" }} key={index}>
              <Radio
                name={props.id}
                style={{ height: "16px", width: "16px" }}
                id={index.toString()}
                isChecked={
                  props.selectedValue?.value === option.value ? true : false
                }
                onChange={(checked) => {
                  if (checked) {
                    props.setSelectedValue(option, props.id);
                  }
                }}
                label={option.label}
                isDisabled={props.isDisabled || false}
              />
            </FlexItem>
          );
        })}
      </Flex>
    </FormGroup>
  );
};
export default RadioInput;
