import React, { useReducer } from "react";
import { BreadcrumbTypes } from "../constants/Breadcrumb";
import { globalStateReducer } from "./GlobalStateReducer";
import { COMPONENT } from "../constants/Process";
import { getProcessType } from "../utils";

const homeBreadcrumb: BreadcrumbTypes = {
  name: "Home",
  linkTo: "/",
  isActive: true,
};

export const initialGlobalState: any = {
  breadcrumbArray: [homeBreadcrumb],
  currentComponent: COMPONENT.ALL_PROCESSES,
  currentProcess: getProcessType(),
};

const initialStateContext: any = {
  globalState: initialGlobalState,
};

const initialDispatchContext: any = null;

export const GlobalStateContext = React.createContext(initialStateContext);
export const GlobalDispatchContext = React.createContext(
  initialDispatchContext
);

export function GlobalContextProvider({ children }) {
  const [globalState, dispatch] = useReducer(
    globalStateReducer,
    initialGlobalState
  );
  return (
    <GlobalStateContext.Provider value={{ globalState }}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
}
