import { ENV, HOSTNAMES } from "../constants/env-constants";

export const getEnvName = () => {
  if (typeof window !== "undefined" && window) {
    const hostname = window.location.hostname;
    type HOSTNAME_ENV = keyof typeof HOSTNAMES;
    const HOSTNAME_KEYS = Object.keys(HOSTNAMES) as HOSTNAME_ENV[];

    const value = HOSTNAME_KEYS.find((env: ENV): ENV => {
      if (HOSTNAMES[env].includes(hostname)) return env;
    });
    return value || ENV.STAGE;
  }
  return ENV.STAGE;
};
