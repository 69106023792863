import axios from "axios";
import { ACCOUNT_PLANNING_PA_API_BASE_URL } from "../endpoints";

const apiInstance = () => {
  const api = axios.create({
    baseURL: ACCOUNT_PLANNING_PA_API_BASE_URL,
  });
  api.interceptors.request.use(async (config) => {
    let accessToken = window?.sessionjs?.token;
    if (accessToken) {
      if (config.method !== "OPTIONS") {
        config.headers.authorization = `Bearer ${accessToken}`;
      }
    }
    return config;
  });

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return error;
    }
  );

  return api;
};

const accountPlanningApiClient = apiInstance();

export default accountPlanningApiClient;
