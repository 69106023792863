import { DatePicker, FormGroup } from "@patternfly/react-core";
import "./inputs.scss";
import moment from "moment";

interface DatePickersProps {
  label?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  style?: React.CSSProperties;
  id: string;
  value?: Date;
  setValue: (value: Date) => void;
  helperText?: string;
  minDate?: Date;
  maxDate?: Date;
}

const DateInput: React.FC<DatePickersProps> = (props) => {
  const rangeValidator = (date: Date) => {
    if (date < props.minDate) {
      return "Date is before the allowable range.";
    } else if (date > props.maxDate) {
      return "Date is after the allowable range.";
    }
    return "";
  };
  return (
    <FormGroup isRequired={props.isRequired || false} style={props.style}>
      <DatePicker
        {...(props.value
          ? { value: moment(props.value).format("YYYY-MM-DD").toString() }
          : {})}
        helperText={props.helperText}
        id={props.id}
        isDisabled={props.isDisabled || false}
        style={{ fontSize: "19px", outline: "none", ...props.style }}
        onChange={(e, str, date) => {
          props.setValue(date);
        }}
        validators={[rangeValidator]}
      />
    </FormGroup>
  );
};
export default DateInput;
