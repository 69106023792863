import KeycloakJs, { KeycloakTokenParsed } from "keycloak-js";
import { isUndefined } from "lodash";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { getEnvName } from "../../utils/env-utils";
import { ENV } from "../../constants/env-constants";
import { Loader } from "../../components/Loader/Loader";
import { useLocation } from "react-router-dom";
import LoginWarningModal from "../../components/RHSC/LoginWarningModal";

declare global {
  interface Window {
    sessionjs: any;
  }
}

export interface ISecurityState {
  tokenSsoUsername: string;
  tokenUserFullName: string;
  isLoggingIn: boolean;
  isLoggedIn: boolean;
  token: string;
  user: KeycloakTokenParsed | null;
  isAuthenticated: boolean;
  showLoginWarning: boolean;
}

export const initialSecurityState: ISecurityState = {
  isLoggedIn: false,
  isLoggingIn: false,
  user: null,
  token: null,
  tokenSsoUsername: "",
  tokenUserFullName: "",
  isAuthenticated: false,
  showLoginWarning: false,
};

export interface IAuthContextProviderProps extends ISecurityState {
  login: () => void;
  logout: () => void;
}
export const AuthContext = createContext<IAuthContextProviderProps>({
  ...initialSecurityState,
  login: () => undefined,
  logout: () => undefined,
});
/**
 * custom hook for AuthContext. It will help to get context of the Auth.
 */
export function useAuthContext(): IAuthContextProviderProps {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuthContext must be used within a AuthContextProvider`);
  }
  return context;
}

export const envToSsoEnvMap = {
  PROD: "https://auth.redhat.com/auth",
  STAGE: "https://auth.stage.redhat.com/auth",
  QA: "https://auth.stage.redhat.com/auth",
};
/**
 * Get SSO env link as per environment
 * @returns SSO link
 */
const getSSOURL = (): string => {
  return getEnvName() === ENV.PROD
    ? "https://auth.redhat.com/auth"
    : "https://auth.stage.redhat.com/auth";
};

interface IProps {
  children: ReactNode;
}

/**
 * Auth context provider component
 * https://www.keycloak.org/docs/latest/securing_apps/index.html#init-options
 * @param {IProps} props component props
 * @returns JSX element
 */
export const AuthContextProvider = (props: IProps): JSX.Element => {
  const [authState, setAuthState] =
    useState<ISecurityState>(initialSecurityState);
  const location = useLocation();
  const [keycloak] = useState<KeycloakJs>(
    new KeycloakJs({
      url: getSSOURL(),
      clientId: "io-process-accelerator",
      realm: "EmployeeIDP",
    })
  );
  // Initialize our JWT and everything.
  useEffect(() => {
    setAuthState((prev) => ({ ...prev, isLoggingIn: true }));
    addAdapters();

    keycloak
      .init({
        onLoad: "check-sso",
        enableLogging: getEnvName() === ENV.STAGE,
        pkceMethod: "S256",
        responseMode: "query",
      })
      .then((authenticated) => {
        if (authenticated) {
          receiveLoginToken();
          supportSessionJS();
        } else {
          logoutAction();
        }
      })
      .catch((err) => {
        logoutAction();
        console.log(err);
      })
      .finally(() => {
        setAuthState((prev) => ({ ...prev, isLoggingIn: false }));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Update sessionjs object
   */
  const updateWindowSessionObject = () => {
    window.sessionjs = {
      ...window.sessionjs,
      ...keycloak,
      jwtToken: keycloak.token,
      _state: {
        keycloak: {
          token: keycloak.token,
          isTokenExpired: keycloak.isTokenExpired,
        },
      },
    };
  };

  /**
   * Set session js object for hydrajs library
   * We are making all the hydra related api call from hydrajs
   */
  const supportSessionJS = () => {
    window.sessionjs = {
      ...keycloak,
      jwtToken: keycloak.token,
      _state: {
        keycloak: {
          token: keycloak.token,
          isTokenExpired: keycloak.isTokenExpired,
        },
      },
      _keycloak: keycloak,
      isAuthenticated: () => keycloak.authenticated,
      getToken: () => keycloak.tokenParsed,
      updateToken: async (force = false) => {
        return new Promise<boolean>((resolve, reject) => {
          keycloak
            .updateToken(force ? -1 : 90)
            .then((refreshed) => {
              updateWindowSessionObject();
              resolve(refreshed);
            })
            .catch((err) => {
              reject(err);
              logoutAction();
            });
        });
      },
    };
  };

  /**
   * Update context on receive token and update redux state
   */
  const receiveLoginToken = () => {
    if (!keycloak) {
      return null;
    }
    const user = keycloak.tokenParsed;
    setAuthState({
      user,
      isLoggedIn: true,
      isAuthenticated: keycloak.authenticated || false,
      isLoggingIn: false,
      token: keycloak.token,
      tokenSsoUsername: user?.preferred_username ?? "",
      tokenUserFullName: user?.name ?? `${user?.firstName} ${user?.lastName}`,
      showLoginWarning: false,
    });
  };

  /**
   * Reset value in the auth context
   */
  const resetValue = () => {
    setAuthState(initialSecurityState);
  };

  /**
   * reset local state after logout
   */
  const logoutAction = () => {
    resetValue();
    if (location.pathname.includes("/rhsc-assistant")) {
      setAuthState({ ...authState, showLoginWarning: true });
    } else {
      login();
    }
  };

  /**
   *
   * @returns
   */
  const login = async () => {
    try {
      if (isUndefined(keycloak)) {
        return;
      }
      await keycloak.login();
      receiveLoginToken();
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * Register event handlers for jwt auth.
   * We will update local state or redux state as per event
   * handlers.
   */
  const addAdapters = () => {
    try {
      keycloak.onAuthRefreshSuccess = function () {
        receiveLoginToken();
      };
      keycloak.onAuthLogout = function () {
        logoutAction();
      };
      keycloak.onTokenExpired = async () => {
        try {
          await keycloak.updateToken(5 * 60);
          updateWindowSessionObject();
        } catch (err) {
          logoutAction();
        }
      };
    } catch (e) {
      console.warn("There is some issue in register a function for JWT");
    }
  };

  /**
   * On logout, replaces the current resource with the one at the provided URL.
   * Update local state.
   */
  const logout = async () => {
    try {
      if (!keycloak) {
        return;
      }
      await keycloak.logout();
    } catch (err) {
      console.error(err);
    } finally {
      logoutAction();
    }
  };

  return authState.showLoginWarning ? (
    <LoginWarningModal />
  ) : authState.isLoggedIn === true && props.children ? (
    <AuthContext.Provider
      value={{
        ...authState,
        login: login,
        logout: logout,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  ) : (
    <Loader text="Logging in" />
  );
};
