import {
  Nav,
  NavItem,
  NavList,
  PageSidebar,
  PageSidebarBody,
} from "@patternfly/react-core";
import { Link, useLocation } from "react-router-dom";
import { headerMenuItems, MenuItems } from "../Header/HeaderUtil";
import { FormEvent, useEffect, useState } from "react";
import { ProcessesMetaInterface } from "../../constants/Processes";
import { getCurrentProcessMeta } from "../../utils";

const Sidebar = ({ isSidebarOpen }) => {
  const [activeItem, setActiveItem] = useState(0);
  const location = useLocation();
  const currentProcessMeta: ProcessesMetaInterface = getCurrentProcessMeta();

  useEffect(() => {
    const currentRoute = location.pathname;
    let currentMenu: MenuItems = headerMenuItems.find((menuItem) =>
      currentRoute.includes(menuItem.linkTo)
    );
    setActiveItem(currentMenu?.menuIndex);
  }, [location.pathname]);

  const onSelect = (
    event: FormEvent<HTMLInputElement>,
    selectedItem: {
      groupId: string | number;
      itemId: string | number;
      to: string;
    }
  ) => {
    setActiveItem(selectedItem.itemId as number);
  };

  return (
    <PageSidebar isSidebarOpen={isSidebarOpen} id="vertical-sidebar">
      <PageSidebarBody>
        <Nav
          onSelect={onSelect}
          aria-label="Horizontal global nav"
          className="pa-navigation"
        >
          <NavList>
            {headerMenuItems.map((menu: MenuItems, index: number) => {
              return (
                <NavItem
                  key={index}
                  itemId={menu.menuIndex}
                  isActive={activeItem === menu.menuIndex}
                >
                  <Link to={menu.linkTo + currentProcessMeta?.code}>
                    {menu.displayName}
                  </Link>
                </NavItem>
              );
            })}
          </NavList>
        </Nav>
      </PageSidebarBody>
    </PageSidebar>
  );
};

export default Sidebar;
