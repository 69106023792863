export interface I_ACCOUNT_PLANNING_TYPE {
  label: string;
  value: string;
}

export const ACCOUNT_PLANNING_TYPES: I_ACCOUNT_PLANNING_TYPE[] = [
  {
    value: "ibm_joint",
    label: "IBM joint account planning",
  },
  {
    value: "red_hat_sales",
    label: "Red hat sales account planning",
  },
  {
    value: "customer_success",
    label: "Customer success account planning",
  },
];
