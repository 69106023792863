import { Bullseye, Spinner, Title } from "@patternfly/react-core";
import React from "react";

interface ILoader {
  text?: string;
  size?: "sm" | "md" | "lg" | "xl";
  height?: string;
}
export const Loader = (props: ILoader) => {
  return (
    <div style={{ height: props.height || "70vh" }}>
      <Bullseye>
        <Title headingLevel="h1">
          <Spinner size={props.size || "lg"} />
          &emsp; {props.text} . . .
        </Title>
      </Bullseye>
    </div>
  );
};
