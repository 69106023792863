import {
  NotificationDrawer as PFNotificationDrawer,
  NotificationDrawerHeader,
  Button,
  NotificationDrawerBody,
  NotificationDrawerList,
  NotificationDrawerListItem,
  NotificationDrawerListItemHeader,
  NotificationDrawerListItemBody,
  EmptyState,
  EmptyStateVariant,
  EmptyStateIcon,
  EmptyStateBody,
} from "@patternfly/react-core";
import { BellIcon } from "@patternfly/react-icons";
import moment from "moment";
import React, { createRef } from "react";

export const NotificationDrawer = ({
  notifications,
  onCloseNotificationDrawer,
  markAllRead,
  onNotificationClick,
}) => {
  const drawerRef = createRef();

  return (
    <PFNotificationDrawer ref={drawerRef}>
      <NotificationDrawerHeader
        count={notifications.length}
        onClose={onCloseNotificationDrawer}
      >
        {notifications && notifications.length ? (
          <Button variant="secondary" onClick={markAllRead}>
            Mark all read
          </Button>
        ) : (
          <></>
        )}
      </NotificationDrawerHeader>
      <NotificationDrawerBody>
        {notifications && notifications.length ? (
          <NotificationDrawerList>
            {notifications.map((notification, index) => (
              <NotificationDrawerListItem
                variant="info"
                onClick={() => onNotificationClick(notification)}
                isRead={false}
              >
                <NotificationDrawerListItemHeader
                  title="New Comment"
                  srTitle="notification"
                ></NotificationDrawerListItemHeader>
                <NotificationDrawerListItemBody
                  timestamp={moment
                    .utc(notification.updatedAt)
                    .local()
                    .fromNow()}
                >
                  {notification.processComment.updatedByName} added a new
                  comment, "{notification.processComment.content}"
                </NotificationDrawerListItemBody>
              </NotificationDrawerListItem>
            ))}
          </NotificationDrawerList>
        ) : (
          <EmptyState variant={EmptyStateVariant.full}>
            <EmptyStateIcon icon={BellIcon} />
            <EmptyStateBody>
              There are currently no notifications for you.
            </EmptyStateBody>
          </EmptyState>
        )}
      </NotificationDrawerBody>
    </PFNotificationDrawer>
  );
};
