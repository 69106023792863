import React from "react";
import {
  Select,
  SelectOption,
  SelectList,
  MenuToggle,
  TextInputGroup,
  TextInputGroupMain,
  TextInputGroupUtilities,
  Button,
} from "@patternfly/react-core";
import TimesIcon from "@patternfly/react-icons/dist/esm/icons/times-icon";
import "./inputs.scss";

const SelectTypeaheadCreatable = ({
  initialOptions = [],
  placeholder = "Select an option",
  onOptionSelect = (option) => {},
  onOptionCreate = (option) => {},
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [selected, setSelected] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");
  const [filterValue, setFilterValue] = React.useState("");
  const [selectOptions, setSelectOptions] = React.useState(initialOptions);
  const [focusedItemIndex, setFocusedItemIndex] = React.useState(null);
  const [activeItem, setActiveItem] = React.useState(null);
  const [onCreation, setOnCreation] = React.useState(false);
  const textInputRef = React.useRef(null);

  React.useEffect(() => {
    let newSelectOptions = initialOptions;

    if (filterValue) {
      newSelectOptions = initialOptions.filter((menuItem) =>
        String(menuItem.children)
          .toLowerCase()
          .includes(filterValue.toLowerCase())
      );

      if (!newSelectOptions.length) {
        newSelectOptions = [
          {
            isDisabled: false,
            children: `Create new option "${filterValue}"`,
            value: "create",
          },
        ];
      }

      if (!isOpen) {
        setIsOpen(true);
      }
    }

    setSelectOptions(newSelectOptions);
    setActiveItem(null);
    setFocusedItemIndex(null);
  }, [filterValue, onCreation, initialOptions]);

  const onToggleClick = () => {
    setIsOpen(!isOpen);
  };

  const onSelect = (_event, value) => {
    if (value) {
      if (value === "create") {
        if (!initialOptions.some((item) => item.value === filterValue)) {
          const newOption = { value: filterValue, children: filterValue };
          initialOptions.push(newOption);
          onOptionCreate(newOption);
        }
        setSelected(filterValue);
        setOnCreation(!onCreation);
        setFilterValue("");
      } else {
        setInputValue(value);
        setFilterValue("");
        setSelected(value);
        onOptionSelect(value);
      }
    }

    setIsOpen(false);
    setFocusedItemIndex(null);
    setActiveItem(null);
  };

  const onTextInputChange = (_event, value) => {
    setInputValue(value);
    setFilterValue(value);
  };

  const handleMenuArrowKeys = (key) => {
    let indexToFocus;

    if (isOpen) {
      if (key === "ArrowUp") {
        if (focusedItemIndex === null || focusedItemIndex === 0) {
          indexToFocus = selectOptions.length - 1;
        } else {
          indexToFocus = focusedItemIndex - 1;
        }
      }

      if (key === "ArrowDown") {
        if (
          focusedItemIndex === null ||
          focusedItemIndex === selectOptions.length - 1
        ) {
          indexToFocus = 0;
        } else {
          indexToFocus = focusedItemIndex + 1;
        }
      }

      setFocusedItemIndex(indexToFocus);
      const focusedItem = selectOptions.filter((option) => !option.isDisabled)[
        indexToFocus
      ];
      setActiveItem(
        `select-create-typeahead-${focusedItem.value.replace(" ", "-")}`
      );
    }
  };

  const onInputKeyDown = (event) => {
    const enabledMenuItems = selectOptions.filter(
      (option) => !option.isDisabled
    );
    const [firstMenuItem] = enabledMenuItems;
    const focusedItem =
      focusedItemIndex !== null
        ? enabledMenuItems[focusedItemIndex]
        : firstMenuItem;

    switch (event.key) {
      case "Enter":
        if (isOpen) {
          onSelect(undefined, focusedItem.value);
          setIsOpen(false);
          setFocusedItemIndex(null);
          setActiveItem(null);
        }
        break;
      case "Tab":
      case "Escape":
        setIsOpen(false);
        setActiveItem(null);
        break;
      case "ArrowUp":
      case "ArrowDown":
        event.preventDefault();
        handleMenuArrowKeys(event.key);
        break;
      default:
        break;
    }
  };

  const toggle = (toggleRef) => (
    <MenuToggle
      ref={toggleRef}
      variant="typeahead"
      aria-label="Typeahead creatable menu toggle"
      onClick={onToggleClick}
      isExpanded={isOpen}
      isFullWidth
    >
      <TextInputGroup isPlain>
        <TextInputGroupMain
          value={inputValue}
          onClick={onToggleClick}
          onChange={onTextInputChange}
          onKeyDown={onInputKeyDown}
          id="create-typeahead-select-input"
          autoComplete="off"
          innerRef={textInputRef}
          placeholder={placeholder}
          {...(activeItem && { "aria-activedescendant": activeItem })}
          role="combobox"
          isExpanded={isOpen}
          aria-controls="select-create-typeahead-listbox"
        />
        <TextInputGroupUtilities>
          {!!inputValue && (
            <Button
              variant="plain"
              onClick={() => {
                setSelected("");
                setInputValue("");
                setFilterValue("");
                textInputRef.current.focus();
              }}
              aria-label="Clear input value"
            >
              <TimesIcon aria-hidden />
            </Button>
          )}
        </TextInputGroupUtilities>
      </TextInputGroup>
    </MenuToggle>
  );

  return (
    <Select
      id="create-typeahead-select"
      isOpen={isOpen}
      selected={selected}
      onSelect={onSelect}
      onOpenChange={() => {
        setIsOpen(false);
      }}
      toggle={toggle}
    >
      <SelectList id="select-create-typeahead-listbox">
        {selectOptions.map((option, index) => (
          <SelectOption
            key={option.value || option.children}
            isFocused={focusedItemIndex === index}
            className={option.className}
            onClick={() => setSelected(option.value)}
            id={`select-typeahead-${option.value.replace(" ", "-")}`}
            {...option}
            ref={null}
          />
        ))}
      </SelectList>
    </Select>
  );
};

export default SelectTypeaheadCreatable;
