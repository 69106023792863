import { gql } from "@apollo/client";

export const GET_TASKS_LIST = gql`
  query getTasksForUser(
    $where: UserTaskInstanceArgument
    $offset: Int
    $limit: Int
    $orderBy: UserTaskInstanceOrderBy
  ) {
    UserTaskInstances(
      where: $where
      pagination: { offset: $offset, limit: $limit }
      orderBy: $orderBy
    ) {
      id
      description
      name
      priority
      processInstanceId
      processId
      rootProcessInstanceId
      rootProcessId
      state
      actualOwner
      adminGroups
      adminUsers
      completed
      started
      excludedUsers
      potentialGroups
      potentialUsers
      inputs
      outputs
      referenceName
      lastUpdate
      endpoint
      comments {
        id
      }
      attachments {
        id
      }
    }
  }
`;

export const GET_TASK = gql`
  query getTasksForUser($where: UserTaskInstanceArgument) {
    UserTaskInstances(where: $where) {
      id
      description
      name
      priority
      processInstanceId
      processId
      rootProcessInstanceId
      rootProcessId
      state
      actualOwner
      adminGroups
      adminUsers
      completed
      started
      excludedUsers
      potentialGroups
      potentialUsers
      inputs
      outputs
      referenceName
      lastUpdate
      endpoint
      comments {
        id
      }
      attachments {
        id
      }
    }
  }
`;

export const GET_TASKS_LIST_BY_PROCESS_ID = gql`
  query getTasksForUser($where: UserTaskInstanceArgument) {
    UserTaskInstances(where: $where) {
      id
      description
      name
      priority
      processInstanceId
      processId
      rootProcessInstanceId
      rootProcessId
      state
      actualOwner
      adminGroups
      adminUsers
      completed
      started
      excludedUsers
      potentialGroups
      potentialUsers
      inputs
      outputs
      referenceName
      lastUpdate
      endpoint
      comments {
        id
      }
      attachments {
        id
      }
    }
  }
`;
